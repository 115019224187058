// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";


type Props = {
	currService: WmService,
}
type State = {
	totalCounts: Array<any>,
	fetched: boolean
}
class WmSaitenStats extends React.Component<Props, State> {
	state = {
		fetched: false,
		totalCounts: [],
	}

	fetch = () => {
		const {currService} = this.props
		post_wm("admin/fetch_saiten_stats", {
			service_code: currService.service
		})
		.then((ret) => {
			this.setState({
				fetched: true,
				totalCounts: ret.saiten_stats.total_counts
			})
		})
	}

	render() {
		const {fetched, totalCounts} = this.state
		const totalCountsVd = (fetched && totalCounts.length) ? totalCounts.map((row) => (
			<Grid item key={row.subject} style={{border:'1px solid #ddd', padding:4}}>
				<Grid container>
					<Grid item style={{width:100, fontWeight:700}}>
						{row.subject}
					</Grid>
					<Grid item style={{width:100}}>
						{row.count}人
					</Grid>
				</Grid>
			</Grid>
		)) : (
			<Grid item key={1}>なし</Grid>
		)
		const resultVd = (fetched) ? (
			<div>
				<div style={{marginTop:20}}>
					科目別採点データ数
				</div>
				<Grid container alignItems="center">
					{totalCountsVd}
				</Grid>
			</div>
		) : null
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>採点データの概要（更新ボタンを押してください）</h4>
				<div>
					<Button variant="contained" color="primary" size="small" onClick={this.fetch}>
						更新
					</Button>
				</div>
				{resultVd}
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmSaitenStats)