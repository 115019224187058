// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import {connect} from "react-redux";
import type {WmService, WmServiceType} from '../../shared/ServerInterface'
import CurrentService from './CurrentService'
import {post_wm} from "../../sonodalib/sl_utils/Http";


type Props = {
	history: any,
	wmServiceList: Array<WmService>,
	currService:   WmService,
	wmServiceTypes:Array<WmServiceType>,
}
type States = {
	subjectCsv: string,
}

class WmSubjects extends React.Component<Props, States> {
	state = {
		subjectCsv: "",
	}

	componentDidMount() {
		this.fetch()
	}

	fetch() {
		const {currService} = this.props
		if (currService) {
			post_wm("admin/fetch_subjects_tsv", {
				service_code: currService.service
			})
			.then((ret) => {
				this.setState({
					subjectCsv: ret.subjects_csv
				})
			})
		}
	}

	handleUpdate = () => {
		const {currService} = this.props
		const csv = this.state.subjectCsv.trim()
		if (!csv.length) {
			alert("１行目は見出し行で、削除できません。編集前に戻します")
			this.fetch()
		} else {
			post_wm("admin/prepare_subjects", {
				service_code: currService.service,
				csv_lines:    csv,
			})
			.then((ret) => {
				if (ret.errors.length) {
					let errorText = `${ret.errors.length} 件のエラーがあります\n`
					ret.errors.forEach((error:string) => {
						errorText = errorText + error + "\n"
					})
					alert(errorText)
				} else {
					let text = ""
					if (ret.added) 		text = `${ret.added}件 追加\n`
					if (ret.modified) text = text + `${ret.modified}件 修正\n`
					if (ret.removed)  text = text + `${ret.removed}件 削除\n`
					if (!text.length) {
						alert("変更はありません")
					} else {
						if (window.confirm(text + "更新してよろしいですか？")) {
							post_wm("admin/update_subjects", {
								service_code: currService.service,
								csv_lines:    csv,
							})
							.then((ret) => {
								alert("更新しました")
								this.fetch()
							})
						}
					}
				}
			})
		}
	}

	render() {
		const {wmServiceList, currService, wmServiceTypes} = this.props
		const {subjectCsv} = this.state
		if (!wmServiceList || !wmServiceList.length || !wmServiceTypes || !wmServiceTypes.length) return null

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<h3>科目の管理</h3>
						<textarea
							id="subjectCsv"
							className="form-control"
							onChange={(el)=>{
								this.setState({
									subjectCsv: el.target.value,
								})
							}}
							value={subjectCsv}
							rows={26}
						/>
						<div style={{marginTop:30}}>
							<Button
								variant="contained"
								onClick={this.handleUpdate}
								color="primary"
							>
								更新
							</Button>
						</div>
					</React.Fragment>
				)}
				<div style={{marginTop:40,fontSize:16}}>
					<Box my={2}>
						セルフチェックテスト以外では、ウエイト・おすすめ値は意味を持たないので、0などの値 を指定してください。
					</Box>
					<Box my={2}>
						<a href="https://docs.google.com/spreadsheets/d/1CKhf1f3mdHp7x7eepM6pUn_ikq_kTvlrhoEAIVoB8yQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
							模試科目CSVサンプル
						</a>
					</Box>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		wmServiceList: state.mypage.wmServiceList,
		currService:   state.mypage.currService,
		wmServiceTypes:state.mypage.wmServiceTypes,
	}),
)(withRouter(WmSubjects))