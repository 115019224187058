import {Record} from 'immutable'
import Validations from '../sl_utils/Validations'
import _ from 'lodash'

export interface InputState {
	getValue(): string;
	setValue(string): InputState;
	setError(string): InputState;
	getError(): string;
	isError(): boolean;
	validate(): InputState;
	isValid(): boolean;
	clear(): InputState;
	onChange(any, any, string): void;
}

const TextInputStateRecord = Record({
	name: "",
	value: "",
	error: "",
	validations: "",
	disabled: false,
})

export class TextInputState extends TextInputStateRecord implements InputState {
	getValue() {
		return this.get("value")
	}

	setValue(value: string) {
		switch (typeof value) {
			case "undefined":
				value = ""
				break
			case "number":
				value = "" + value
				break;
			default:
		}
		return this.merge({value, error: "", disabled: false})
	}

	getError() {
		return this.get("error")
	}

	setError(error: string) {
		return this.set("error", error)
	}

	isError() {
		return this.get("error").length !== 0
	}

	validate() {
		let error = ""
		const value = this.get("value").trim()
		let validations = this.get("validations")
		if (typeof validations === "string")
			validations = validations.split(",")
		if ((validations.indexOf("require") !== -1 || validations.indexOf("required") !== -1) && value.length === 0) {
			error = "入力必須項目です"
		} else if (validations.indexOf("email") !== -1 && !Validations.email(value)) {
			error = "正しくないメールアドレスです"
		} else if (validations.indexOf("password") !== -1 && !Validations.password_medium(value)) {
			error = "パスワードは数値・英小文字 8文字以上で入力して下さい"
		} else if (validations.indexOf("telno") !== -1 && !Validations.telno(value)) {
			error = "正しくない電話番号です"
		} else if (validations.indexOf("integer") !== -1 && value && !Validations.integer(value)) {
			error = "数値を入力して下さい"
		} else if (validations.indexOf("zipcode") !== -1 && !Validations.zipcode(value)) {
			error = "正しくない郵便番号です"
		} else if (validations.indexOf("zenkaku") !== -1 && !Validations.zenkaku(value)) {
			error = "半角文字は使えません"
		} else if (validations.indexOf("alpdigit") !== -1 && !Validations.alpdigit(value)) {
			error = "半角 英数字以外は使えません"
		} else {
			const row = _.find(validations, (row) => (typeof row === "object" && row.max))
			if (row && row.max < value.length) {
				error = `${row.max}文字以内で入力して下さい`
			} else {
				const row = _.find(validations, (row) => (typeof row === "object" && row.min))
				if (row && row.min > value.length) {
					error = `${row.min}文字以上で入力して下さい`
				}
			}
		}
		return this.set("error", error)
	}

	isValid() {
		return (this.get("error").length === 0)
	}

	clear() {
		return this.merge({
			value: "",
			error: "",
		})
	}

	isDisabled() {
		return this.get("disabled")
	}

	setDisabled(newDisabled: boolean) {
		this.set("disabled", newDisabled)
	}

	// onChange(event:any, component:any, stateName:string) {
	// 	const obj = {}
	// 	obj[stateName] = component.state[stateName].setValue(event.target.value)
	// 	component.setState(obj)
	// }
}


// SelectorのState

const SelectorInputStateRecord = Record({
	name: "",
	index: 0,
	error: "",
	menuList: [],
})

export class SelectorInputState extends SelectorInputStateRecord implements InputState {
	getValue() {
		return this.get("menuList")[this.get("index")].id
	}

	setValue(value: string) {
		let index = _.findIndex(this.get("menuList"), {id: value})
		if (index === -1) index = 0
		return this.merge({index, error: ""})
	}

	getLabel() {
		return this.get("menuList")[this.get("index")].label
	}

	getError() {
		return this.get("error")
	}

	setError(error: string) {
		return this.set("error", error)
	}

	isError() {
		return this.get("error").length !== 0
	}

	validate() {
		let error = ""
		const row = _.find(this.get("menuList"), {id: this.getValue()})
		if (row) {
			if (row.invalid) {
				error = "必須選択項目です"
			}
		}
		return this.set("error", error)
	}

	isValid() {
		return (this.get("error").length === 0)
	}

	clear() {
		return this.merge({
			index: 0,
			error: "",
		})
	}
}


const CheckboxInputStateRecord = Record({
	name: "",
	error: "",
	value: false,
	validations: "",
	requireErrorMessage: "チェックは必須です",
})

export class CheckboxInputState extends CheckboxInputStateRecord implements InputState {
	getValue() {
		return this.get("value")
	}

	setValue(value: boolean) {
		return this.merge({value, error: ""})
	}

	getError() {
		return this.get("error")
	}

	setError(error: string) {
		return this.set("error", error)
	}

	isError() {
		return this.get("error").length !== 0
	}

	validate() {
		let error = ""
		const validations = this.get("validations").split(",")
		if ((validations.indexOf("require") !== -1 || validations.indexOf("required") !== -1) && !this.getValue()) {
			error = this.get("requireErrorMessage")
		}
		return this.set("error", error)
	}

	isValid() {
		return (this.get("error").length === 0)
	}

	clear() {
		return this.merge({
			value: false,
			error: "",
		})
	}
}


// 連続するチェックボックスのState

const CheckboxStateRecord = Record({
	list: [],
})

export class CheckboxArrayState extends CheckboxStateRecord {
	getValues = () => {
		return this.get("list").map(row => (row.checked ? row.value : null)).filter(value => (value !== null))
	}
	getList = () => {
		return this.get("list")
	}
	setChecked = (index: number, checked: boolean) => {
		this.get("list")[index].checked = checked
		return new CheckboxArrayState({list: this.get("list")})
	}
	setCheckedByValues = (values) => {
		const list = this.get("list")
		_.forEach(list, (row) => {
			row.checked = (values.indexOf(row.value) !== -1)
		})
		return new CheckboxArrayState({list: list})
	}
}


export class FormState {
	static valueMembers(state: any, members: Array<string>) {
		const out = {}
		_.forEach(members, (member: string) => {
			out[member] = state[member].getValue()
		})
		return out
	}

	static validateMembers(state: any, members: Array<string>) {
		const out = {}
		_.forEach(members, (member: string) => {
			out[member] = state[member].validate()
		})
		return out
	}

	static validate(state: any) {
		const out = {}
		_.forEach(state, (v, k) => {
			if (v instanceof TextInputState || v instanceof SelectorInputState || v instanceof CheckboxInputState) {
				out[k] = v.validate()
			}
		})
		return out
	}

	static isValidMembers(state: any, members: Array<string>) {
		return !_.some(members, (member: string) => (!state[member].isValid()))
	}

	static isValid(state: any) {
		return !_.some(state, (v, k) => {
			if (v instanceof TextInputState || v instanceof SelectorInputState || v instanceof CheckboxInputState) {
				return !v.isValid()
			}
			return false
		})
	}

	static getAllInvalidElementNames(state: any) {
		const out = []
		_.forEach(state, (v, k) => {
			if (v instanceof TextInputState) {
				if (!v.isValid() && v.name) out.push(v.name)
			} else if (v instanceof SelectorInputState) {
				if (!v.isValid() && v.name) out.push(v.name)
			}
		})
		return out
	}

}

