// @flow
import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import type {InfoText} from '../../shared/DbModel'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, SelectorInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import SlTextFieldSelector from '../../sonodalib/sl_mui/SlTextFieldSelector'
import {post} from "../../sonodalib/sl_utils/Http";
import {updateSnackbarAC} from '../../sonodalib/sl_utils/SlUtilsAction'
import G from "../../sonodalib/SlGlobal";
import TextInput from '../../sonodalib/sl_adminlte/TextInput'

const subjectBgColors = [
	{id: 'primary', label: "青"},
	{id: 'success', label: "緑"},
	{id: 'warning', label: "黄"},
	{id: 'danger', label: "赤"},
	{id: 'default', label: "灰色"},
]

type Props = {
	allGroups: any,
	infoText: InfoText,
	// infoGroups: Array<InfoGroup>,
	onClose: (isCanceled: boolean) => void,
}
type States = {
	orderState: TextInputState,
	subjectState: TextInputState,
	bodyState: TextInputState,
	subjectBgColorState: SelectorInputState,
	viewDantaiState: TextInputState,
	viewGroup1State: TextInputState,
	viewGroup2State: TextInputState,
	hideDantaiState: TextInputState,
	hideGroup1State: TextInputState,
	hideGroup2State: TextInputState,
}

class EditInfo extends React.Component<Props, States> {
	state = {
		orderState: new TextInputState({validations: "required"}),
		subjectState: new TextInputState({validations: "required"}),
		bodyState: new TextInputState({validations: "required"}),
		subjectBgColorState: new SelectorInputState({menuList: subjectBgColors}),
		viewDantaiState: new TextInputState(),
		viewGroup1State: new TextInputState(),
		viewGroup2State: new TextInputState(),
		hideDantaiState: new TextInputState(),
		hideGroup1State: new TextInputState(),
		hideGroup2State: new TextInputState(),
	}

	componentWillMount() {
		const infoText = this.props.infoText
		const {orderState, subjectState, subjectBgColorState, bodyState} = this.state
		const {viewDantaiState, viewGroup1State, viewGroup2State} = this.state
		const {hideDantaiState, hideGroup1State, hideGroup2State} = this.state
		this.setState({
			orderState: orderState.setValue(infoText.order),
			subjectState: subjectState.setValue(infoText.subject),
			subjectBgColorState: subjectBgColorState.setValue(infoText.subject_color),
			bodyState: bodyState.setValue(infoText.body),
			viewDantaiState: viewDantaiState.setValue(infoText.view_dantai),
			viewGroup1State: viewGroup1State.setValue(infoText.view_group1),
			viewGroup2State: viewGroup2State.setValue(infoText.view_group2),
			hideDantaiState: hideDantaiState.setValue(infoText.hide_dantai),
			hideGroup1State: hideGroup1State.setValue(infoText.hide_group1),
			hideGroup2State: hideGroup2State.setValue(infoText.hide_group2),
		})
	}

	handleChangeViewGroup = () => {
		alert("未実装")
		// this.setState({
		// 	showViewGroups: true,
		// })
	}

	handleChangeHideGroup = () => {
		alert("未実装")
		// this.setState({
		// 	showHideGroups: true,
		// })
	}


	handleSave = () => {
		const checkGroupIds = (layer, idString) => {		// idString "200,9T2" 正しくないIDが１つでもあれば false
			if (!idString.length) return true
			const groupList = this.props.allGroups.groupList.toJS()
			return !(_.some(idString.split(","), (_id:string) => {
				const id = _id.trim()
				if (!_.find(groupList, {layer,id})) return true
			}))
		}

		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const {orderState, subjectState, subjectBgColorState, bodyState} = this.state
				const {viewDantaiState, viewGroup1State, viewGroup2State} = this.state
				const {hideDantaiState, hideGroup1State, hideGroup2State} = this.state
				let error = false
				const badIdMessage = "間違ったIDです"
				if (!checkGroupIds(10,viewDantaiState.getValue())) {
					this.setState({viewDantaiState: viewDantaiState.setError(badIdMessage)})
					error = true
				}
				if (!checkGroupIds(10,hideDantaiState.getValue())) {
					this.setState({hideDantaiState: hideDantaiState.setError(badIdMessage)})
					error = true
				}
				if (!checkGroupIds(20,viewGroup1State.getValue())) {
					this.setState({viewGroup1State: viewGroup1State.setError(badIdMessage)})
					error = true
				}
				if (!checkGroupIds(20,hideGroup1State.getValue())) {
					this.setState({hideGroup1State: hideGroup1State.setError(badIdMessage)})
					error = true
				}
				if (!checkGroupIds(30,viewGroup2State.getValue())) {
					this.setState({viewGroup2State: viewGroup2State.setError(badIdMessage)})
					error = true
				}
				if (!checkGroupIds(30,hideGroup2State.getValue())) {
					this.setState({hideGroup2State: hideGroup2State.setError(badIdMessage)})
					error = true
				}
				if (!error) {
					const payload = {
						info_text: {
							uuid: "",
							order: orderState.getValue(),
							subject: subjectState.getValue(),
							body: bodyState.getValue(),
							subject_color: subjectBgColorState.getValue(),
							view_dantai: viewDantaiState.getValue(),
							view_group1: viewGroup1State.getValue(),
							view_group2: viewGroup2State.getValue(),
							hide_dantai: hideDantaiState.getValue(),
							hide_group1: hideGroup1State.getValue(),
							hide_group2: hideGroup2State.getValue(),
						},
					}
					if (this.props.infoText.uuid.length) {	// 編集時
						payload.info_text.uuid = this.props.infoText.uuid
					}
					post("etc/info/update", payload)
					.then(() => {
						G.store.dispatch(updateSnackbarAC("編集しました"))
						this.props.onClose(false)
					})
				}
			}
		})
	}

	handleCancel = () => {
		G.store.dispatch(updateSnackbarAC("編集をキャンセルしました"))
		this.props.onClose(true)
	}

	render() {
		// const {infoGroups} = this.props
		const {orderState, subjectState, subjectBgColorState, bodyState} = this.state
		// const viewGroups = _.filter(infoGroups, {type: 1})
		// const hideGroups = _.filter(infoGroups, {type: 2})
		// const type1count = viewGroups.length
		// const type2count = hideGroups.length

		return (
			<div>
				<div className="box box-success">
					<div className="box-header with-border">
						<h3 className="box-title">表示内容</h3>
					</div>
					<div className="box-body">
						<Grid container direction="row">
							<Grid item xs={7}>
								<SlTextField
									type="number"
									inputProps={{
										min: 0,
										max: 99,
									}}
									state={orderState}
									label="表示順（値が小さいほど上　0なら非表示）"
									placeholder="1〜99"
									onChange={(newState) => {
										this.setState({orderState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={5}>
							</Grid>
							<Grid item xs={8}>
								<SlTextField
									state={subjectState}
									label="タイトル"
									placeholder="例：おしらせ"
									onChange={(newState) => {
										this.setState({subjectState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<SlTextFieldSelector
									label="タイトル背景色"
									state={subjectBgColorState}
									fullWidth={true}
									onChange={(newState) => {
										this.setState({
											subjectBgColorState: newState,
										})
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<SlTextField
									state={bodyState}
									label="本文"
									multiline
									placeholder="本文（タグの利用、複数行可）"
									onChange={(newState) => {
										this.setState({bodyState: newState})
									}}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="box box-success">
					<div className="box-header with-border">
						<h3 className="box-title">表示対象グループID（無指定ならすべてのユーザー） 複数ならカンマ区切り</h3>
					</div>
					<div className="box-body">
						<Grid container direction="row">
							<Grid item xs={4}>
								<TextInput
									state={this.state.viewDantaiState}
									label="団体"
									type="text"
									onChange={(newState) => {
										this.setState({viewDantaiState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextInput
									state={this.state.viewGroup1State}
									label="グループ１"
									type="text"
									onChange={(newState) => {
										this.setState({viewGroup1State: newState})
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextInput
									state={this.state.viewGroup2State}
									label="グループ２"
									type="text"
									onChange={(newState) => {
										this.setState({viewGroup2State: newState})
									}}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="box box-success">
					<div className="box-header with-border">
						<h3 className="box-title">表示除外グループID  複数ならカンマ区切り</h3>
					</div>
					<div className="box-body">
						<Grid container direction="row">
							<Grid item xs={4}>
								<TextInput
									state={this.state.hideDantaiState}
									label="団体"
									type="text"
									onChange={(newState) => {
										this.setState({hideDantaiState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextInput
									state={this.state.hideGroup1State}
									label="グループ１"
									type="text"
									onChange={(newState) => {
										this.setState({hideGroup1State: newState})
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextInput
									state={this.state.hideGroup2State}
									label="グループ２"
									type="text"
									onChange={(newState) => {
										this.setState({hideGroup2State: newState})
									}}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<Grid item xs={12}>
					<button type="button" className="btn btn-primary" onClick={this.handleSave}>保存</button>
					<button type="button" className="btn btn-default" style={{marginLeft: 30}} onClick={this.handleCancel}>キャンセル
					</button>
				</Grid>
			</div>
		)
	}
}

export default connect(
	state => ({
		allGroups: state.mypage.allGroups,
	}),
	dispatch => ({})
)(EditInfo)