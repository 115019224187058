// @flow
import React from 'react';
import {connect} from "react-redux";
import CurrentService from "../CurrentService";
import {TextInputState} from '../../../sonodalib/sl_utils/SlForms'
import type {WmService} from "../../../shared/ServerInterface";
import WmShukeiStats from './WmShukeiStats'
import WmShukeiViewOption from './WmShukeiViewOption'
import WmReserveShukei from './WmReserveShukei'
import WmShukeiEtc from './WmShukeiEtc'
import WmShukeiDlSaitenSum from './WmShukeiDlSaitenSum'
import WmShukeiShowBunpu from './WmShukeiShowBunpu'
import WmExcludeAnySaiten from './WmExcludeAnySaiten'


type Props = {
	currService: WmService,
	shukei_etc:  any,
}
type State = {
	uniqueIdToIgnore: TextInputState,
}
class ShukeiPage extends React.Component<Props, State> {
	state = {
		uniqueIdToIgnore: new TextInputState({validations: "required,integer"}),
	}

	handleChangeUniqueIdToIgnore = (newState, onFinish) => {
		this.setState({
			uniqueIdToIgnore: newState,
		}, onFinish)
	}

	render() {
		const {currService, shukei_etc, categoriesToIgnoreCheckBoxes} = this.props
		const {uniqueIdToIgnore} = this.state
		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService || !shukei_etc) ? null : (
					<React.Fragment>
						<h3>集計処理の管理</h3>
						<WmShukeiStats/>
						<WmReserveShukei currService={currService}/>
						<WmShukeiEtc currService={currService} shukei_etc={shukei_etc}/>
						<WmShukeiViewOption/>
						<WmShukeiShowBunpu currService={currService} shukei_etc={shukei_etc}/>
						<WmShukeiDlSaitenSum currService={currService}/>
						<WmExcludeAnySaiten currService={currService}
																shukei_etc={shukei_etc}
																uniqueIdToIgnore={uniqueIdToIgnore}
																onChangeUniqueIdToIgnore={this.handleChangeUniqueIdToIgnore}
																categoriesToIgnoreCheckBoxes={categoriesToIgnoreCheckBoxes}
						/>
					</React.Fragment>
				)}
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:  state.mypage.currService,
		shukei_etc:   state.mypage.shukei_etc,
		categoriesToIgnoreCheckBoxes: state.mypage.categoriesToIgnoreCheckBoxes,
	}),
)(ShukeiPage)