// @flow
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Moment from 'moment'
import Cookies from "js-cookie";
import BbsPost from "./BbsPost";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button'
import {blue} from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextInput from "../../sonodalib/sl_adminlte/TextInput";
import BsButton from "../../sonodalib/sl_adminlte/BsButton";
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
import {FormState} from '../../sonodalib/sl_utils/SlForms'
import Grid from '@material-ui/core/Grid'

type Props = {
	boardId: number,
	ident?: number,
	uniqueId: string,			// 利用者ID検索をしない場合は ""
}
type States = {
	threads: ?Array<any>,
	showPost: boolean,
	threadId: ?number,
	infoString: string,
	titleState: TextInputState,
	handleNameState: TextInputState,
	bodyState: TextInputState,
}

// const onlyUnreadList = [
// 	{id: 0, label:"未読のみ"},
// 	{id: 1, label:"すべて"},
// ]

export default class BbsThread extends React.Component<Props,States> {
	state = {
		threads: undefined,
		showPost: false,
		threadId: null,
		infoString: "",
		titleState: new TextInputState({validations: "required"}),
		handleNameState: new TextInputState({value:"事務局", validations: ["required", {max:16}]}),
		bodyState: new TextInputState({validations: "required"}),
	}

	componentWillMount() {
		this.fetchThread(this.props)
	}

	componentWillReceiveProps(props: any,nextContent:any) {
		this.fetchThread(props)
	}

	handleClickThread = (thread:any) => {
		this.setState({
			threadId: thread.uuid,
			showPost: true,
		})
	}

	handleClosePost = () => {
		this.setState({
			showPost: false,
		})
		this.fetchThread(this.props)
	}

	fetchThread = (props:any) => {
		const self = this
		const {boardId, ident, uniqueId} = props
		post("admin/fetch_threads", {
			user_token: Cookies.get("sl_users"),
			board_id: boardId,
			ident: ident,
			unique_id: uniqueId,
			only_unread: false,
		})
			.then(ret => {
				const list = _(ret.list).sortBy("thread_created").reverse().value()
				list.forEach((row) => {row.checked = false})
				self.setState({
					threads: list,
				})
			})
	}

	handleToggleCheckbox = (thread:any) => () => {
		thread.checked = !thread.checked
		this.setState({
			threads: this.state.threads
		})
	}

	_checkAll = (checked) => {
		const {threads} = this.state
		if (threads) {
			threads.forEach((row:any) => {
				row.checked = checked
			})
			this.setState({
				threads: this.state.threads
			})
		}
	}

	handleCheckAll = () => {
		this._checkAll(true)
	}

	handleUncheckAll = () => {
		this._checkAll(false)
	}

	handleDelete = () => {
		if (window.confirm("削除してよろしいですか？（元に戻せません）")) {
			const {threads} = this.state
			if (threads) {
				let p = Promise.resolve()
				threads.filter((thread:any) => (thread.checked)).forEach((thread: any) => {
					p = p.then(() =>
						new Promise((resolve, reject) => {
							this.setState({
								infoString: "削除中... " + thread.title,
							})
							post("admin/del_thread_perm", {
								user_token: Cookies.get("sl_users"),
								thread_id: thread.uuid,
							})
							.then((ret) => {
								resolve(ret)
							})
							.catch((ret) => {
								reject(ret)
							})
						})
					)
				})
				p.then(() => {
					alert("削除しました")
					this.fetchThread(this.props)
				})
				.catch(() => {
					alert("不明なエラーが発生しました")
				})
				// $FlowFixMe
				.finally(() => {
					this.setState({
						infoString: "",
					})
				})
			}
		}
	}

	handleCreateThread = () => {
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				if (window.confirm("投稿してもよろしいですか？")) {
					const {boardId} = this.props
					post("bbs/create_thread", {
						user_token: Cookies.get("sl_users"),
						board_id: boardId,
						mondai_id: 0,
						marubatsu_id: 0,
						title: this.state.titleState.getValue(),
						handle_name: this.state.handleNameState.getValue(),
						text: this.state.bodyState.getValue(),
					})
					.then(() => {
						alert("投稿しました")
						this.fetchThread(this.props)
						this.setState({
							titleState: this.state.titleState.setValue(""),
							handleNameState: this.state.handleNameState.setValue(""),
							bodyState: this.state.bodyState.setValue(""),
						})
					})
				}
			}
		})
	}

	render() {
		const {boardId} = this.props
		const { threads, infoString } = this.state
		const threadList = (threads === undefined) ? (
			<TableRow>
				<TableCell colSpan={4}>
					検索結果
				</TableCell>
			</TableRow>
		) : (threads && threads.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					みつかりませんでした
				</TableCell>
			</TableRow>
		) : _.map(
			threads, thread => {
				const hiddenVd = (thread.hidden) ? (
					<span className="badge bg-red" style={{marginRight:8}}>非表示中</span>
				) : null
				return (
					<TableRow hover key={thread.uuid}>
						<TableCell padding="default">
							<Checkbox
								checked={thread.checked}
								onChange={this.handleToggleCheckbox(thread)}
								value="1"
								style={{width: 24, height: 24}}
							/>
						</TableCell>
						<TableCell padding="default">{hiddenVd}<a style={{cursor: 'pointer'}}
																					onClick={this.handleClickThread.bind(this, thread)}>{thread.title}</a> {(thread.newest_user_post > thread.newest_admin_read) ?
							<span style={{fontWeight: 800, color: 'red'}}>未読</span> : null}</TableCell>
						<TableCell padding="dense" numeric>{thread.comment_count}</TableCell>
						<TableCell padding="default">{Moment(thread.thread_created).format("YYYY/MM/DD HH:mm")}</TableCell>
					</TableRow>
				)
			}
		)
		if (this.state.showPost) {
			return <BbsPost
				threadId={this.state.threadId}
				onClose={this.handleClosePost}
			/>
		} else {
			const command_box = (!threads || threads.length === 0) ? null : (
				<div className="clearfix">
					<ul className="no-margin pull-left">
						<Button
							variant="contained"
							style={{color: 'white', backgroundColor: blue[400]}}
							onClick={this.handleCheckAll}
						>
							すべて選択
						</Button>
						<Button
							variant="contained"
							style={{marginLeft: 8, color: 'white', backgroundColor: blue[400]}}
							onClick={this.handleUncheckAll}
						>
							選択解除
						</Button>
						<Button
							variant="contained"
							color="secondary"
							style={{marginLeft: 14}}
							onClick={this.handleDelete}
						>
							完全に削除
						</Button>
					</ul>
				</div>
			)
			const input_handle_name = (
				<Grid item xs={6} style={{padding: 10}}>
					<TextInput
						type="text"
						label="ハンドルネーム"
						state={this.state.handleNameState}
						onChange={(newState) => {
							this.setState({handleNameState: newState})
						}}
					/>
				</Grid>
			)
			const create_thread_box = (boardId === 1 || boardId === 9) ? null : (
				<Grid container style={{marginTop: 10}}>
					<Grid item xs={6} style={{padding: 10}}>
						<TextInput
							type="text"
							label="タイトル"
							state={this.state.titleState}
							onChange={(newState) => {
								this.setState({titleState: newState})
							}}
						/>
					</Grid>
					{input_handle_name}
					<Grid item xs={12} style={{padding: 10}}>
						<TextInput
							type="text"
							label="内容"
							multiline
							rows={11}
							state={this.state.bodyState}
							onChange={(newState) => {
								this.setState({bodyState: newState})
							}}
						/>
					</Grid>
					<Grid item xs={12} style={{padding: 10}}>
						<BsButton
							color="primary"
							onClick={this.handleCreateThread}
						>
							スレッドを作成
						</BsButton>
					</Grid>
				</Grid>
			)

			return (
				<div>
					<section className="content-header">
						<h1>
							スレッドリスト
							<small>Control panel</small>
						</h1>
						<ol className="breadcrumb">
							<li><a><i className="fa fa-dashboard"/> Home</a></li>
							<li className="active">Dashboard</li>
						</ol>
					</section>
					<section className="content">
						{command_box}
						<Table>
							<TableHead>
								<TableRow>
									<TableCell padding="default"></TableCell>
									<TableCell padding="default">タイトル</TableCell>
									<TableCell padding="dense" numeric>返信数</TableCell>
									<TableCell padding="default">スレッド作成日</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{threadList}
							</TableBody>
						</Table>
						{command_box}
						{create_thread_box}
					</section>
					<Dialog open={(infoString.length !== 0)}>
						<DialogContent>
							<div style={{textAlign: 'center'}}>
								{infoString}
							</div>
						</DialogContent>
					</Dialog>
				</div>
			)
		}
	}
}

// export default withRouter(ContactThread)
