import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Moment from 'moment'
import {deepOrange, indigo, grey} from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import './history.css'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'

export default class HistoryDetail extends React.Component {
	state = {
		kamokumei: null,
		mondaiList: null,
		marubatsuList: null,
		shikenArray: null,
		shikenList: null,
		memoList: null,
		results: null,
	}

	componentWillMount() {
		const self = this
		// const mondaiType = this.props.match.params.mondaiType
		const {fetchUrl, fetchPayload} = this.props
		// const kamokuId = this.props.match.params.kamokuId
		post(fetchUrl, fetchPayload)
		.then(ret => {
			PropTypes.checkPropTypes({
				kamokumei: PropTypes.string.isRequired,
				mondaiList: PropTypes.array.isRequired,
				marubatsuList: PropTypes.array.isRequired,
				shikenList: PropTypes.array.isRequired,
				memoList: PropTypes.array.isRequired,
				results: PropTypes.array.isRequired,
			}, ret)
			// const mondaiType = parseInt(this.props.match.params.mondaiType, 10)
			// const list = (mondaiType === 2) ? ret.mondaiList : ret.marubatsuList
			const shikenArray = _.uniq(_.map(ret.mondaiList, 'ShikenID')).sort().reverse()
			self.setState({
				kamokumei: ret.kamokumei,
				shikenArray,
				mondaiList: ret.mondaiList,
				marubatsuList: ret.marubatsuList,
				shikenList: ret.shikenList,
				memoList: ret.memoList,
				results: ret.results,
			})
		})
	}

	render() {
		const {kamokumei, mondaiList, marubatsuList, shikenArray, shikenList, memoList, results} = this.state
		if (shikenList === null) return null

		const styles = {
			subjectBox: {
				margin: 20,
				fontWeight: 700,
			},
			narrowTd: {
				width: 10,
				whiteSpace: 'nowrap',
				verticalAlign: 'middle',
			},
		}

		const mondaiType = parseInt(this.props.mondaiType, 10)

		const content = _.map(shikenArray, shikenId => {
			const shiken = _.find(shikenList, {ShikenID: shikenId})
			let mondaiRowsVd = null

			if (mondaiType === 2) {		// 選択問題
				const mondaiRows = _.sortBy(_.filter(mondaiList, {ShikenID: shikenId}), 'MondaiNum')
				mondaiRowsVd = _.map(mondaiRows, mondai => {
					const _results = _.takeRight(_.sortBy(_.filter(results, {
						MondaiID: mondai.MondaiID,
						MarubatsuID: 0
					}), "updated"), 10)
					while (_results.length < 10) {
						_results.push({
							result: 0,
						})
					}
					const _resultsVd = _.map(_results, result => {
						if (!result.result)
							return <td key={_.uniqueId("fk")}></td>
						const color = (result.result === 1) ? deepOrange[600] : indigo[600]
						const m = Moment(result.updated)
						const _date = m.format("MM/DD")
						const _time = m.format("HH:mm")
						return (
							<td key={_.uniqueId("fk")} style={{backgroundColor: color, verticalAlign: 'middle', fontSize: 12}}>{_date}<br/>{_time}
							</td>
						)
					})
					const memoIconName = _.find(memoList, {
						MondaiID: mondai.MondaiID,
						MarubatsuID: 0
					}) ? "bookmark" : "bookmark_border"
					return (
						<tr key={_.uniqueId("fk")}>
							<td style={{...styles.narrowTd, cursor: 'pointer'}}
									onClick={this.props.onShowSentaku.bind(this, mondai.MondaiID)}>
								<span className="badge bg-light-blue">{mondai.MondaiNum}</span>
							</td>
							<td style={styles.narrowTd}>
							</td>
							<td style={styles.narrowTd}>
								<IconButton style={{color: 'white'}} aria-label="メモ"
														onClick={this.props.onOpenMemo.bind(this, mondai.MondaiID, 0)}>
									<Icon color="primary">{memoIconName}</Icon>
								</IconButton>
							</td>
							{_resultsVd}
						</tr>
					)
				})
			} else {			// ◯✕問題
				const marubatsuRows = _.sortBy(_.filter(marubatsuList, {ShikenID: shikenId}), 'MondaiNum')
				mondaiRowsVd = _.map(marubatsuRows, marubatsu => {
					const _results = _.takeRight(_.sortBy(_.filter(results, {MarubatsuID: marubatsu.MarubatsuID}), "updated"), 10)
					while (_results.length < 10) {
						_results.push({
							result: 0,
						})
					}
					const _resultsVd = _.map(_results, result => {
						if (!result.result)
							return <td key={_.uniqueId("fk")}></td>
						const color = (result.result === 1) ? deepOrange[600] : indigo[600]
						const m = Moment(result.updated)
						const _date = m.format("MM/DD")
						const _time = m.format("HH:mm")
						return (
							<td key={_.uniqueId("fk")} style={{backgroundColor: color, verticalAlign: 'middle', fontSize: 12}}>{_date}<br/>{_time}
							</td>
						)
					})
					const memoIconName = _.find(memoList, {
						MondaiID: marubatsu.MondaiID,
						MarubatsuID: marubatsu.MarubatsuID
					}) ? "bookmark" : "bookmark_border"
					return (
						<tr key={_.uniqueId("fk")}>
							<td style={{...styles.narrowTd, cursor: 'pointer'}}
									onClick={this.props.onShowOx.bind(this, marubatsu.MarubatsuID)}>
								<span className="badge bg-light-blue">{marubatsu.MondaiNum}</span>
							</td>
							<td style={styles.narrowTd}>
							</td>
							<td style={styles.narrowTd}>
								<IconButton style={{color: 'white'}} aria-label="メモ"
														onClick={this.props.onOpenMemo.bind(this, marubatsu.MondaiID, marubatsu.MarubatsuID)}>
									<Icon color="primary">{memoIconName}</Icon>
								</IconButton>
							</td>
							{_resultsVd}
						</tr>
					)
				})
			}
			mondaiRowsVd.unshift(
				<tr key={_.uniqueId("fk")} style={{backgroundColor: grey[300]}}>
					<th colSpan="13">{shiken.Shikenkaisu} ｜ {shiken.Nendo}年度実施</th>
				</tr>
			)
			return mondaiRowsVd
		})
		return (
			<Paper style={{padding:10}}>
				<BsButton color="primary" onClick={this.props.onGoBack}>科目一覧に戻る</BsButton>
				<div style={styles.subjectBox}>
					{kamokumei}
				</div>
				<div>
					問題ごとの解答結果を表示しています。（正解した問題は赤・不正解の問題は青）<br/>
					問題番号をタップすると詳細が表示されます
				</div>
				<div>
					<table className="history-detail-table table table-striped table-bordered">
						<tbody>
							{content}
						</tbody>
					</table>
				</div>
			</Paper>
		)
	}
}
