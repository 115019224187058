import React from 'react'
import Paper from '@material-ui/core/Paper'
import Moment from 'moment'
// import {deepOrange, indigo, grey} from '@material-ui/core/colors';
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
import {post} from '../../sonodalib/sl_utils/Http'
// import './history.css'
// import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import Button from "@material-ui/core/Button";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
// import Grid from "@material-ui/core/Grid";
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";

export default class PaymentHistory extends React.Component {
	state = {
		list: null,
		dantaiId: new TextInputState({validations:"required"}),
		paymentServiceCode: new TextInputState({validations:"required"}),
	}

	componentWillMount() {
		const self = this
		const {user_id} = this.props
		post("admin/payment_history", {user_id})
		.then(ret => {
			self.setState({
				list: ret.list
			})
		})
	}

	handleDelete = (row) => () => {
		if (window.confirm("削除してよろしいですか？")) {
			post("admin/upsert_users_payment_history", {
				users_payment_id: row.uuid,
				user_id: row.user_id,
				row: {
					"valid": 0,
				}
			})
			.then((ret) => {
				this.setState({
					list: ret.list
				})
				alert("削除しました")
			})
		}
	}

	handleSubmit = () => {
		const self = this
		const {user_id} = this.props
		const {dantaiId, paymentServiceCode} = this.state
		self.setState({
			dantaiId: 					dantaiId.validate(),
			paymentServiceCode: paymentServiceCode.validate(),
		}, () => {
			const {dantaiId, paymentServiceCode} = self.state
			if (dantaiId.isValid() && paymentServiceCode.isValid()) {
				post("admin/upsert_users_payment_history", {
					users_payment_id: null,
					user_id,
					row: {
						"user_id": 					user_id,
						"user_type": 				"d"+dantaiId.getValue(),
						"service": 	 				paymentServiceCode.getValue(),
						"payment_order_id": null,
						"payed_date": 			Moment().format("YYYY-MM-DD HH:mm:ss"),
						"valid": 						1,
					}
				})
				.then((ret) => {
					self.setState({
						list: ret.list
					})
					alert("追加しました")
				})
			}
		})
	}

	render() {
		const {list} = this.state
		if (list === null) return null

		const content = list.map((row) => {
			let pay_service = row.service
			if (!row.pay_error) {
				pay_service = <span style={{color:'red'}}>{pay_service}（不明）</span>
			}
			return (
				<tr key={row.uuid}>
					<td>{Moment(row.payed_date).format("YYYY/MM/DD HH:mm:ss")}</td>
					<td>{row.dantai_name}</td>
					<td>{pay_service}</td>
					<td>{row.payment_order_id}</td>
					<td>
						<Button size="small" variant="contained" color="primary" onClick={this.handleDelete(row)}>
							削除
						</Button>
					</td>
				</tr>
			)
		})
		if (!content.length) {
			content.push(
				<tr key="no-data">
					<td style={{color:'black'}}>
						課金履歴はありません
					</td>
				</tr>
			)
		}
		return (
			<Paper style={{padding:10}}>
				<table className="table table-striped table-bordered">
					<thead>
						<tr>
							<th>課金日時</th>
							<th>団体名</th>
							<th>課金サービスコード</th>
							<th>注文ID</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody>
						{content}
					</tbody>
				</table>
				<div>
					<div>
						任意追加
					</div>
					<div style={{maxWidth:400}}>
						<SlTextField
							label="団体ID"
							placeholder="例：1700"
							state={this.state.dantaiId}
							onChange={(newState) => {
								this.setState({dantaiId: newState})
							}}
						/>
						<SlTextField
							label="課金サービスコード"
							placeholder="例：seishin"
							state={this.state.paymentServiceCode}
							onChange={(newState) => {
								this.setState({paymentServiceCode: newState})
							}}
						/>
					</div>
					<div>
						<Button variant="contained" color="primary" onClick={this.handleSubmit}>
							追加
						</Button>
					</div>
				</div>
			</Paper>
		)
	}
}
