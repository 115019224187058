// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import CurrentService from "../CurrentService";
import Button from "@material-ui/core/Button";
import {SelectorInputState} from "../../../sonodalib/sl_utils/SlForms";
import TextFieldSelector from "../../../sonodalib/sl_mui/TextFieldSelector";
import G from '../../../sonodalib/SlGlobal'
import {updateWmSubjectMenu, updateWmMondaiTsvAC} from '../../MyPageAction'
import {Instruction} from './common'


type Props = {}
type State = {
	// subjectMenu: SelectorInputState,
	// mondai: string,
}
class WmMondai extends React.Component<Props, State> {
	state = {
	}

	handleUpdate = () => {
		const {currService, mondaiTsv, subjectMenu} = this.props
		const csv = mondaiTsv.trim()
		if (!csv.length) {
			alert("１行目は見出し行で、削除できません。")
		} else {
			post_wm("admin/prepare_mondai", {
				service_code: currService.service,
				subject_code: subjectMenu.getValue(),
				csv_lines:    csv,
			})
			.then((ret) => {
				if (ret.errors.length) {
					let errorText = `${ret.errors.length} 件のエラーがあります\n`
					ret.errors.forEach((error:string) => {
						errorText = errorText + error + "\n"
					})
					alert(errorText)
				} else {
					let text = ""
					if (ret.added) 		text = `${ret.added}件 追加\n`
					if (ret.modified) text = text + `${ret.modified}件 修正\n`
					if (ret.removed)  text = text + `${ret.removed}件 削除\n`
					if (!text.length) {
						alert("変更はありません")
					} else {
						if (window.confirm(text + "更新してよろしいですか？")) {
							post_wm("admin/update_mondai", {
								service_code: currService.service,
								subject_code: subjectMenu.getValue(),
								csv_lines:    csv,
							})
							.then((ret) => {
								alert("更新しました")

							})
						}
					}
				}
			})
		}
	}

	render() {
		const {currService, subjectMenu, mondaiTsv} = this.props
		const subjectSelectorVd = (!subjectMenu) ? null : (
			<TextFieldSelector
				label="科目"
				state={subjectMenu}
				fullWidth={true}
				onChange={(newState: SelectorInputState) => {
					updateWmSubjectMenu(newState)
				}}
				InputLabelProps={{
					shrink: true,
					disableAnimation: true,
				}}
			/>
		)
		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<h3>問題の編集</h3>
						<div>
							{subjectSelectorVd}
						</div>
						<textarea
							id="mondai"
							className="form-control"
							onChange={(el)=>{
								G.store.dispatch(updateWmMondaiTsvAC(el.target.value))
								// this.setState({
								// 	mondai: el.target.value,
								// })
							}}
							value={mondaiTsv}
							rows={36}
						/>
						<div style={{marginTop:30}}>
							<div>
								<Button
									variant="contained"
									onClick={this.handleUpdate}
									color="primary"
								>
									更新
								</Button>
							</div>
							<div>
								すでに採点データがある問題を削除したり、配点を変更した場合、集計結果に齟齬が生じる可能性があります
							</div>
						</div>
					</React.Fragment>
				)}
				<Instruction/>
			</div>
		)
	}
}
export default connect(
	state => ({
		subjectMenu:   state.mypage.subjectMenu,
		mondaiTsv:   	 state.mypage.mondaiTsv,
		currService:   state.mypage.currService,
	}),
)(WmMondai)