import {post} from "../sonodalib/sl_utils/Http"
import G from "../sonodalib/SlGlobal"
import {updateGroupListAC} from "../mypage/MyPageAction"
import {normalizeRow} from "../sonodalib/sl_utils/Utils"


export const fetchGroupList = (dantaiId) => {
	return new Promise((resolve, reject) => {
		post("admin/fetch_group_list", {dantai_id: dantaiId})
		.then(ret => {
			ret = normalizeRow(ret)
			resolve(ret)
			G.store.dispatch(updateGroupListAC({selectedDantaiId: dantaiId}))
		})
		.catch(ret => {
			reject()
		})
	})
}
