// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import EditInfo from './EditInfo'
import type {InfoText} from '../../shared/DbModel'
import type {EtcInfoFetchResult} from '../../shared/ServerInterface'
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import G from "../../sonodalib/SlGlobal";

type States = {
	list: Array<InfoText>,
	editingUuid?: string,		// <EditInfo/>を、undefinedなら非表示 ""なら新規追加  !=""なら修正(UUID)
}
export default class InfoList extends React.Component<{}, States> {
	state = {
		list: [],
		editingUuid: undefined,
	}

	componentWillMount() {
		this.fetch()
	}

	fetch = () => {
		post("etc/info/fetch", {user_token: "@"})
		.then((ret: EtcInfoFetchResult) => {
			this.setState({
				list: ret.info_texts,
			})
		})
	}

	handleAdd = () => {
		this.setState({
			editingUuid: "",
		})
	}

	handleCloseAddInfo = (isCanceled:boolean) => {
		this.setState({
			editingUuid: undefined,
		})
		if (!isCanceled) this.fetch()
	}

	handleEdit = (uuid:string) => {
		this.setState({
			editingUuid: uuid,
		})
	}

	handleDelete = (infoTextId:string, subject:string) => {
		if (window.confirm(subject+" を削除してもよろしいですか？")) {
			const payload = {
				info_text_id: infoTextId,
			}
			post("etc/info/delete", payload)
			.then(() => {
				G.store.dispatch(updateSnackbarAC("削除しました"))
				this.fetch()
			})
		}
	}

	render() {
		const {list, editingUuid} = this.state
		if (editingUuid !== undefined) {		// fetchの完了を待つ
			const infoText: InfoText = (editingUuid.length) ? _.find(list, {uuid: editingUuid})
				: {uuid: "", order: 0, subject: "", subject_color: "primary", body: "", view_dantai: "", view_group1: "", view_group2: "", hide_dantai: "", hide_group1: "", hide_group2: ""}
			// const infoGroups: Array<InfoGroup> = (editingUuid.length) ? _.filter(groups, {info_text_id: editingUuid}) : []
			return (
				<EditInfo
					infoText={infoText}
					onClose={this.handleCloseAddInfo}
				/>
			)
		}

		const vd = list.map((row: any, index: number) => {
			return (
				<div key={index}>
					<div>
						優先順位設定 #{row.order}
					</div>
					<Grid container direction="row">
						<Grid item xs={10}>
							<div className={`box box-${row.subject_color} box-solid`}>
								<div className="box-header with-border">
									<h3 className="box-title">{row.subject}</h3>
								</div>
								<div className="box-body" dangerouslySetInnerHTML={{__html: row.body.replace(/\n/g,"<br />")}}>
								</div>
							</div>
						</Grid>
						<Grid item xs={2}>
							<button onClick={this.handleEdit.bind(this,row.uuid)} type="button" className="btn btn-success">修正</button>
							<br/>
							<br/>
							<button onClick={this.handleDelete.bind(this,row.uuid, row.subject)} type="button" className="btn btn-danger">削除</button>
						</Grid>
					</Grid>
				</div>
			)
		})
		return (
			<div style={{margin: 8}}>
				<h3>インフォメーション</h3>
				<div>
					{vd}
					<div>
						<button onClick={this.handleAdd} type="button" className="btn btn-block btn-primary">追加</button>
					</div>
				</div>
			</div>
		)
	}
}


