// @flow
import React from 'react';
import Button from '@material-ui/core/Button'
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";

type Props = {
	editGroupId: TextInputState,
	editServiceCode: TextInputState,
	editPayServiceCode: TextInputState,
	editLicense: TextInputState,
	editDesc: TextInputState,
	onChangeGroupId: (newState:TextInputState) => void,
	onChangeServiceCode: (newState:TextInputState) => void,
	onChangePayServiceCode: (newState:TextInputState) => void,
	onChangeLicense: (newState:TextInputState) => void,
	onChangeDesc: (newState:TextInputState) => void,
	onUpdate: () => void,
	onCancel: () => void,
}
export default class LinkSettingEdit extends React.Component<Props> {
	render() {
		const {editGroupId, editServiceCode, editPayServiceCode, editLicense, editDesc, onChangeGroupId, onChangeServiceCode, onChangePayServiceCode, onChangeLicense, onChangeDesc, onUpdate, onCancel} = this.props
		if (!editGroupId) return null
		return (
			<div>
				<SlTextField
					label="団体ID"
					state={editGroupId}
					onChange={onChangeGroupId}
					placeholder="例：1234"
				/>
				<SlTextField
					label="模試サービスコード"
					state={editServiceCode}
					onChange={onChangeServiceCode}
					placeholder="例：201304"
				/>
				<SlTextField
					label="課金サービスコード（空欄なら無指定、カンマ区切りで複数指定）"
					state={editPayServiceCode}
					onChange={onChangePayServiceCode}
					placeholder="例：shakai,seishin"
				/>
				<SlTextField
					label="ライセンスコード（空欄 または 999ならすべてのライセンス、カンマ区切りで複数指定）"
					state={editLicense}
					onChange={onChangeLicense}
					placeholder="例：01,012"
				/>
				<SlTextField
					label="リンク表示テキスト"
					state={editDesc}
					onChange={onChangeDesc}
					placeholder="例：社会福祉士・精神保健衛生士"
				/>
				<div style={{marginTop:30}}>
					<Button
						variant="contained"
						onClick={onUpdate}
						color="primary"
					>
						更新
					</Button>
					<Button
						variant="contained"
						onClick={onCancel}
						style={{marginLeft:18}}
					>
						キャンセル
					</Button>
				</div>
			</div>
		)
	}
}
