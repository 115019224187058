// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import Moment from 'moment'
import {connect} from "react-redux";
import {saveAs} from 'file-saver'
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";


type Props = {
	currService: WmService,
}
type State = {
	cbIncludeTaishogai: boolean,
}
class WmShukeiDlSaitenSum extends React.Component<Props, State> {
	state = {
		cbIncludeTaishogai: false,
	}

	handleDownloadSaiten = () => {
		const {currService} = this.props
		const {cbIncludeTaishogai} = this.state
		post_wm("admin/dl_saiten_sum", {
			service_code: currService.service,
			ignore_flag: cbIncludeTaishogai,
		})
		.then((ret) => {
			const csvs = ret.dl_data.map((row) => (
				`"${row.id}",\
"${row.user_id}",\
"${currService.name}",\
"${row.shurui}",\
"${row.score}",\
"${row.haiten}",\
"${row.rate}"`
			))
			csvs.unshift('"ID","利用者ID","サービス名","種類","得点","配点","得点率"')
			const csv = csvs.join("\n")
			const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
			const blob = new Blob([bom, csv], {"type": "text/csv"});
			saveAs(blob, `saiten_sum_${currService.service}_${Moment().format("YYYY-MM-DD HH:mm:ss")}.csv`)
			// snackbar("採点データをダウンロードしました")
		})
	}

	handleToggleCheckShukeiTaishogai = () => {
		this.setState({
			cbIncludeTaishogai: !this.state.cbIncludeTaishogai
		})
	}

	render() {
		const {cbIncludeTaishogai} = this.state
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>カテゴリ別採点データのダウンロード</h4>
				<div>
					特定の採点情報を集計から除外／復帰させる際の参考になります
				</div>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item>
						<Checkbox
							checked={cbIncludeTaishogai}
							onChange={this.handleToggleCheckShukeiTaishogai}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</Grid>
					<Grid item>
						集計対象外ユーザの採点データも含める
					</Grid>
				</Grid>
				<div style={{marginTop:10}}>
					<Button
						variant="contained"
						onClick={this.handleDownloadSaiten}
						color="primary"
					>
						採点合計をダウンロード
					</Button>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmShukeiDlSaitenSum)