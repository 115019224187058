import React from 'react';
import {connect} from 'react-redux'
import ContentHeader from '../ContentHeader'
import Cookies from "js-cookie";
import {Flex, Box} from 'reflexbox'
import Selector from '../../sonodalib/sl_adminlte/Selector'
import {post} from '../../sonodalib/sl_utils/Http'
import {fetchGroupList} from '../../common/Common'
import {normalizeRow} from '../../sonodalib/sl_utils/Utils'
import {updateGroupListAC} from '../MyPageAction'
import _ from 'lodash'
import G from '../../sonodalib/SlGlobal'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon'
import {common, blue} from '@material-ui/core/colors'
import {downloadCsv} from '../../sonodalib/sl_utils/Download'
import {licenseList} from '../../alt/constants'
import Moment from 'moment'
import BsButton from "../../sonodalib/sl_adminlte/BsButton";

const csvFileNames = {
	"list_all": "学習履歴一覧.csv",
	"list_sentaku": "学習履歴一覧選択問題.csv",
	"list_ox": "学習履歴一覧OX問題.csv",
	"detail_sentaku": "学習履歴詳細選択問題.csv",
	"detail_ox": "学習履歴詳細OX問題.csv",
}

class GroupList extends React.Component {
	state = {
		dantaiList: null,
		dantaiGroupList: null,
		csvDlAnchorEl: null,
		anchorGroupEl: {el:[]},
	}

	componentWillMount() {
		this.fetchAll()
	}

	fetchAll() {
		const self = this
		post("admin/fetch_dantai_list", {})
		.then(ret => {
			ret = normalizeRow(ret)
			const dantaiList = ret.dantaiList
			self.setState({
				dantaiList,
			})
			if (ret.dantaiList.length) {
				let selectedDantaiId = G.store.getState().mypage.groupList.get("selectedDantaiId")
				// const lastSelectedDantaiId = selectedDantaiId
				if (selectedDantaiId === null || !ret.dantaiList.selectedDantaiId) {
					selectedDantaiId = ret.dantaiList[0].uuid
				}
				self.fetchGroupList(selectedDantaiId)
			}
		})
	}

	fetchGroupList = (dantaiId) => {
		const self = this
		fetchGroupList(dantaiId)
		.then(ret => {
			const el = ret.groupList.map((row) => (
				{uuid:row.uuid, csvEl:null, etcEl:null}
			))
			self.setState({
				dantaiGroupList: ret.groupList,
				anchorGroupEl: {el},
			})
			G.store.dispatch(updateGroupListAC({selectedDantaiId: dantaiId}))
		})
		.catch(ret => {
		})
	}

	createGroupList(selectedDantaiId, groupList) {
		const list = []
		// const row = _.find(this.state.dantaiList, {id: selectedDantaiId})
		// const dantai_uuid = row.uuid
		let a1 = _.filter(groupList, {layer: 20, parent: selectedDantaiId})
		a1 = _.sortBy(a1, data => (data.id))
		_.forEach(a1, group1 => {
			const row = {
				txt_id1: group1.id,
				txt_name1: group1.name,
				txt_id2: "",
				txt_name2: "",
				// txt_attr1: group1.attr1,
				txt_population: group1.population,
				uuid: group1.uuid,
				parent: group1.parent,
				// clk_delete: clk_delete,
			}
			list.push(row)

			let a2 = _.filter(groupList, {layer: 30, parent: group1.uuid})
			a2 = _.sortBy(a2, data => (data.id))
			_.forEach(a2, group2 => {
				const row = {
					txt_uuid: group2.uuid,
					txt_id1: group1.id,
					txt_name1: group1.name,
					txt_id2: group2.id,
					txt_name2: group2.name,
					// txt_attr1: group2.attr1,
					txt_population: group2.population,
					uuid: group2.uuid,
					parent: group2.parent,
					// clk_delete: clk_delete,
				}
				list.push(row)
			})
		})
		return list
	}

	handleDantaiSelect = (value) => {
		this.fetchGroupList(value)
	}


	// CSV elの更新
	updateCsvEl = (uuid, newCsvEl) => {
		const row = _.find(this.state.anchorGroupEl.el, {uuid})
		row.csvEl = newCsvEl
		const newEl = {el:this.state.anchorGroupEl.el}
		this.setState({anchorGroupEl: newEl});
	}

	// ETC elの更新
	updateEtcEl = (uuid, newEtcEl) => {
		const row = _.find(this.state.anchorGroupEl.el, {uuid})
		row.etcEl = newEtcEl
		const newEl = {el:this.state.anchorGroupEl.el}
		this.setState({anchorGroupEl: newEl});
	}

	// グループCSVダウンロードボタンクリック
	handleClickGroupCsvDlMenu = (uuid, event) => {
		this.updateCsvEl(uuid, event.currentTarget)
	}

	// グループCSVダウンロードメニュークリック
	handleCloseGroupCsvDlMenu = (uuid, mondaiType) => {
		this.updateCsvEl(uuid, null)

		if (!mondaiType) return

		if (mondaiType.indexOf("list_") === 0) {
			this.downloadList(uuid, mondaiType)
		} else {
			this.downloadDetail(uuid, mondaiType)
		}
	}

	// グループETCボタンクリック
	handleClickGroupMenu = (uuid, event) => {
		this.updateEtcEl(uuid, event.currentTarget)
	}

	// グループETCメニュークリック
	handleCloseGroupMenu = (group, menu) => {
		this.updateEtcEl(group.uuid, null)
		if (!menu) return

		const selectedGroupName = (group.txt_name2) ? group.txt_name2 : group.txt_name1
		switch (menu) {
			case 'delete':
				if (window.confirm(selectedGroupName+" を削除してよろしいですか？")) {
					post("admin/delete_group", {group_id:group.uuid})
					.then((ret) => {
						if (ret.status === "group_has_children") {
							alert("このグループには、子グループがあるので削除できません")
						} else {
							alert("削除しました")
							this.fetchAll()
						}
					})
				}
				break;
			default:
				break;
		}
	}

	// 学習履歴ダウンロード（単独）ボタンクリック
	handleClickCsvDlMenu = (event) => {
		this.setState({csvDlAnchorEl: event.currentTarget});
	}

	// 学習履歴ダウンロード（単独）メニュークリック
	handleCloseCsvDlMenu = (mondaiType) => {
		this.setState({csvDlAnchorEl: null});
		if (!mondaiType) return
		const {selectedDantaiId} = this.props.groupList

		if (mondaiType.indexOf("list_") === 0) {
			this.downloadList(selectedDantaiId, mondaiType)
		} else {
			this.downloadDetail(selectedDantaiId, mondaiType)
		}
	}

	downloadDetail = (selectedDantaiId, mondaiType) => {
		const mondaiTypes = {"detail_sentaku": "sentaku", "detail_ox": "ox"}
		post("admin/list_history_detail", {dantai_id: selectedDantaiId, mondai_type: mondaiTypes[mondaiType]})
		.then((ret) => {
			// console.log(ret)
			const list = ["団体,グループ１,グループ２,氏名,利用者ID,利用資格,科目,試験ＩＤ,問題番号,履歴詳細"]
			ret.history.forEach((row) => {
				const user = _.find(ret.users, {uuid: row.user_id})
				const license = _.find(licenseList, {id: user.license}).label
				list.push(
					user.dantaiId + "," +
					user.group1Id + "," +
					user.group2Id + "," +
					user.name + "," +
					user.uniqueId + "," +
					license + "," +
					row.KamokuID + "," +
					row.ShikenID + "," +
					row.MondaiNum + "," +
					'"' + row.results + '"'
					// ((row.updated) ? Moment(row.updated).format("YYYY/MM/DD HH:mm:ss") : "") + "," +
				)
			})
			downloadCsv(csvFileNames[mondaiType], list)
		})
	}

	downloadList = (selectedDantaiId, mondaiType) => {
		const mondaiTypes = {"list_all": "all", "list_sentaku": "sentaku", "list_ox": "ox"}
		post("admin/list_history", {dantai_id: selectedDantaiId, mondai_type: mondaiTypes[mondaiType]})
		.then((ret) => {
			console.log(ret)
			let list = []
			switch (mondaiType) {
				case 'list_all':
					list = ["団体,グループ１,グループ２,氏名,利用者ID,利用資格,（選択）科目,最終学習日時,日数,実施度,習熟度,（〇×）科目,最終学習日時,日数,実施度,習熟度"]
					ret.users.forEach((user) => {
						const license = _.find(licenseList, {id: user.license}).label
						let sentaku = _.filter(ret.history, {user_id: user.uuid, MondaiType: 2})
						sentaku = _.sortBy(sentaku, "view_order")
						let ox = _.filter(ret.history, {user_id: user.uuid, MondaiType: 1})
						ox = _.sortBy(ox, "view_order")
						for (let i = 0; i < sentaku.length; i++) {
							const _st = sentaku[i]
							const _ox = ox[i]
							list.push(
								user.dantaiId + "," +
								user.group1Id + "," +
								user.group2Id + "," +
								user.name + "," +
								user.uniqueId + "," +
								license + "," +
								_st.KamokuID + "," +
								((_st.updated) ? Moment(_st.updated).format("YYYY/MM/DD HH:mm:ss") : "") + "," +
								_st.dayCount + "," +
								_st.Jisshido + "," +
								_st.Shujukudo + "," +
								_st.KamokuID + "," +
								((_ox.updated) ? Moment(_ox.updated).format("YYYY/MM/DD HH:mm:ss") : "") + "," +
								_ox.dayCount + "," +
								_ox.Jisshido + "," +
								_ox.Shujukudo + ","
							)
						}
					})
					downloadCsv(csvFileNames[mondaiType], list)
					break
				case 'list_sentaku':
					list = ["団体,グループ１,グループ２,氏名,利用資格,科目,最終学習日時,日数,実施度,習熟度"]
					ret.users.forEach((user) => {
						const license = _.find(licenseList, {id: user.license}).label
						let sentaku = _.filter(ret.history, {user_id: user.uuid, MondaiType: 2})
						sentaku = _.sortBy(sentaku, "view_order")
						sentaku.forEach((row) => {
							list.push(
								user.dantaiId + "," +
								user.group1Id + "," +
								user.group2Id + "," +
								user.name + "," +
								license + "," +
								row.KamokuID + "," +
								((row.updated) ? Moment(row.updated).format("YYYY/MM/DD HH:mm:ss") : "") + "," +
								row.dayCount + "," +
								row.Jisshido + "," +
								row.Shujukudo + ","
							)
						})
					})
					downloadCsv(csvFileNames[mondaiType], list)
					break
				case 'list_ox':
					list = ["団体,グループ１,グループ２,氏名,利用資格,科目,最終学習日時,日数,実施度,習熟度"]
					ret.users.forEach((user) => {
						const license = _.find(licenseList, {id: user.license}).label
						let ox = _.filter(ret.history, {user_id: user.uuid, MondaiType: 1})
						ox = _.sortBy(ox, "view_order")
						ox.forEach((row) => {
							list.push(
								user.dantaiId + "," +
								user.group1Id + "," +
								user.group2Id + "," +
								user.name + "," +
								license + "," +
								row.KamokuID + "," +
								((row.updated) ? Moment(row.updated).format("YYYY/MM/DD HH:mm:ss") : "") + "," +
								row.dayCount + "," +
								row.Jisshido + "," +
								row.Shujukudo + ","
							)
						})
					})
					downloadCsv(csvFileNames[mondaiType], list)
					break

				default:
			}
		})
	}

	handleDeleteDantai = () => {
		const {dantaiList} = this.state
		const {selectedDantaiId} = this.props.groupList
		const dantai = _.find(dantaiList, {uuid: selectedDantaiId})
		if (window.confirm("この団体を削除してもよろしいですか？")) {
			post("admin/get_pops_dantai", {
				dantai_id: selectedDantaiId,
			})
			.then((ret) => {
				if (window.confirm(`【最終確認】${dantai.name} を削除してもよろしいですか？ 所属する${ret.count}名が「所属なし」になります`)) {
					post("admin/delete_dantai", {
						user_token: Cookies.get("sl_users"),
						dantai_id: selectedDantaiId,
					})
					.then((ret) => {
						alert("削除しました")
						this.fetchAll()
					})
				}
			})
		}
	}

	render() {
		const {selectedDantaiId} = this.props.groupList
		if (selectedDantaiId === null) return null
		const {dantaiList, csvDlAnchorEl} = this.state
		const options = _.map(dantaiList, dantai => {
			return (
				<option key={dantai.uuid} value={dantai.uuid}>{dantai.id} {dantai.name}</option>
			)
		})
		const dantaiSelectorVd = (
			<div style={{marginBottom:50}}>
				<div className="col-sm-9">
					<Selector
						className="form-control"
						value={selectedDantaiId}
						onChange={this.handleDantaiSelect.bind(this)}
					>
						{options}
					</Selector>
				</div>
				<div className="col-sm-3">
					<BsButton
						color="danger"
						onClick={this.handleDeleteDantai}
					>
						この団体を削除
					</BsButton>
				</div>
			</div>
		)
		let groupListVd = null
		let tableRows = (
			<tr>
				<td colspan="5">読み込み中です</td>
			</tr>
		)
		if (this.state.dantaiGroupList) {
			const list = this.createGroupList(selectedDantaiId, this.state.dantaiGroupList)
			tableRows = _.map(list, group => {
				const row = _.find(this.state.anchorGroupEl.el, {uuid:group.uuid})
				if (!row) {
					debugger
				}
				return (
					<tr key={group.uuid}>
						<td>{group.txt_id1}</td>
						<td>{group.txt_name1}</td>
						<td>{group.txt_id2}</td>
						<td>{group.txt_name2}</td>
						<td>{group.txt_population}</td>
						<td>
							<div>
								<Button
									variant="contained"
									size="small"
									aria-owns={row.csvEl ? 'simple-menu' : null}
									aria-haspopup="true"
									onClick={this.handleClickGroupCsvDlMenu.bind(this,group.uuid)}
									style={{minWidth:30,padding:0,color: common.white, backgroundColor: blue[500]}}
								>
									<Icon style={{padding:0}}>get_app</Icon>
								</Button>
								<Menu
									id="csv-dl-menu"
									anchorEl={row.csvEl}
									open={Boolean(row.csvEl)}
									onClose={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, null)}
								>
									<MenuItem onClick={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, "list_all")}>一覧（すべて）</MenuItem>
									<MenuItem onClick={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, "list_sentaku")}>一覧（選択問題）</MenuItem>
									<MenuItem onClick={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, "list_ox")}>一覧（◯✕問題）</MenuItem>
									<MenuItem onClick={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, "detail_sentaku")}>科目詳細（選択問題）</MenuItem>
									<MenuItem onClick={this.handleCloseGroupCsvDlMenu.bind(this, group.uuid, "detail_ox")}>科目詳細（◯✕問題）</MenuItem>
								</Menu>
							</div>
						</td>
						<td>
							<div>
								<Button
									variant="contained"
									size="small"
									aria-owns={row.etcEl ? 'simple-menu' : null}
									aria-haspopup="true"
									onClick={this.handleClickGroupMenu.bind(this,group.uuid)}
									style={{minWidth:30,padding:0,color: common.white, backgroundColor: blue[500]}}
								>
									<Icon style={{padding:0}}>more_vert</Icon>
								</Button>
								<Menu
									id="group-menu"
									anchorEl={row.etcEl}
									open={Boolean(row.etcEl)}
									onClose={this.handleCloseGroupMenu.bind(this, group, null)}
								>
									<MenuItem onClick={this.handleCloseGroupMenu.bind(this, group, "delete")}>削除</MenuItem>
								</Menu>
							</div>
						</td>
					</tr>
				)
			})
			groupListVd = (
				<table className="table table-hover">
					<tbody>
					<tr>
						<th>グループ1ID</th>
						<th>グループ1名称</th>
						<th>グループ2ID</th>
						<th>グループ2名称</th>
						<th>人数</th>
						<th>CSV</th>
					</tr>
					{tableRows}
					</tbody>
				</table>
			)
		}
		return (
			<div>
				<ContentHeader
					title="グループリスト"
					subTitle="List groups"
				/>
				<section className="content">
					<div style={{marginBottom: 20}}>
						<Button
							variant="contained"
							aria-owns={csvDlAnchorEl ? 'simple-menu' : null}
							aria-haspopup="true"
							onClick={this.handleClickCsvDlMenu}
							style={{color: common.white, backgroundColor: blue[500]}}
						>
							<Icon>get_app</Icon> 学習履歴ダウンロード
						</Button>
						<Menu
							id="csv-dl-menu"
							anchorEl={csvDlAnchorEl}
							open={Boolean(csvDlAnchorEl)}
							onClose={this.handleCloseCsvDlMenu.bind(this, null)}
						>
							<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, "list_all")}>一覧（すべて）</MenuItem>
							<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, "list_sentaku")}>一覧（選択問題）</MenuItem>
							<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, "list_ox")}>一覧（◯✕問題）</MenuItem>
							<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, "detail_sentaku")}>科目詳細（選択問題）</MenuItem>
							<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, "detail_ox")}>科目詳細（◯✕問題）</MenuItem>
						</Menu>
					</div>
					<Flex column>
						<Box p={1} col={5}>
							{dantaiSelectorVd}
						</Box>
						<Box p={1}>
							{groupListVd}
						</Box>
					</Flex>
				</section>
			</div>
		)
	}
}

export default connect(
	state => ({
		groupList: state.mypage.groupList,
	}),
	dispatch => ({})
)(GroupList)