import React from 'react'
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import {purple, red, common} from '@material-ui/core/colors'
import {post} from '../../sonodalib/sl_utils/Http'
import Moment from 'moment'
import _ from 'lodash'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
import {FormState} from '../../sonodalib/sl_utils/SlForms'
import {withBbsDrawer} from './BbsDrawer'
import Cookies from "js-cookie";

class BbsPost extends React.Component {
	state = {
		topic: null,
		posts: null,
		titleState: new TextInputState({validations: "required"}),
		handleNameState: new TextInputState({validations: ["required",{max:16}]}),
		bodyState: new TextInputState({validations: "required"}),
	}

	componentWillMount() {
		this.fetch_posts(this.props.threadId)
	}

	fetch_posts(threadId) {
		const self = this
		const payload = {
			user_token: Cookies.get("sl_users"),
			thread_id: threadId,
		}
		post("admin/fetch_posts", payload)
		.then(ret => {
			self.setState({
				topic: ret.topic,
				posts: ret.posts,
			})
		})
	}

	handleReply = () => {
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				if (window.confirm("返信してよろしいですか？")) {
					const threadId = this.props.threadId
					post("bbs/post_comment", {
						user_token: Cookies.get("sl_users"),
						thread_id: threadId,
						title: this.state.titleState.getValue(),
						handle_name: this.state.handleNameState.getValue(),
						text: this.state.bodyState.getValue(),
					})
					.then(ret => {
						this.fetch_posts(threadId)
						this.setState({
							titleState: this.state.titleState.setValue(""),
							handleNameState: this.state.handleNameState.setValue(""),
							bodyState: this.state.bodyState.setValue(""),
						})
						alert("返信しました")
					})
				}
			}
		})
	}

	handleShowQuestion = () => {
		const {mondai_id, marubatsu_id} = this.state.topic
		if (!marubatsu_id) {
			this.props.history.push("/q/sentaku/show/" + mondai_id)
		} else {
			this.props.history.push("/q/ox/show/" + marubatsu_id)
		}
	}

	handleHidePost = (postId) => {
		this.switchHiddenPost(postId, true)
	}

	handleShowPost = (postId) => {
		this.switchHiddenPost(postId, false)
	}

	switchHiddenPost = (postId, hidden) => {
		post("bbs/update_post_hidden", {
			user_token: Cookies.get("sl_users"),
			post_id: postId,
			hidden,
		})
		.then((ret) => {
			this.fetch_posts(this.props.threadId)
		})
	}
	handleEditPost = (postId, post) => {
		this.props.bbsDrawer.openToEditPost(
			postId,
			post.title,
			post.handle_name,
			post.text,
			() => {
				this.fetch_posts(this.props.threadId)
			},
		)
	}

	handleDelThreadPerm = () => {
		if (window.confirm("完全に削除されますが、よろしいですか？")) {
			post("admin/del_thread_perm", {
				user_token: Cookies.get("sl_users"),
				thread_id: this.props.threadId,
			})
			.then((ret) => {
				this.props.onClose()
			})
		}
	}

	render() {
		const {topic, posts} = this.state
		let topicVd = <div>...</div>
		if (topic) {
			const hiddenVd = (topic.hidden) ? (
				<span className="badge bg-red" style={{marginRight:8}}>非表示中</span>
			) : null
			const hiddenSwitch = (topic.hidden) ? (
				<BsButton
					color="navy"
					onClick={this.handleShowPost.bind(this,topic.post_id)}
				>
					表示する
				</BsButton>
			) : (
				<BsButton
					color="purple"
					onClick={this.handleHidePost.bind(this,topic.post_id)}
				>
					非表示化
				</BsButton>
			)
			topicVd = (
				<div style={{backgroundColor: "#d4e3fc", padding: 16}}>
					<Grid container>
						<Grid item xs={6}>
						<span style={{fontSize: 16, fontWeight: 800, color: purple[700]}}>
							{hiddenVd}
							{topic.title}
						</span>
						</Grid>
						<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: purple[600]}}>
							{topic.handle_name}
						</span>
						</Grid>
						<Grid item xs={3}>
						<span style={{fontSize: 12, fontWeight: 600, color: common.fullBlack}}>
							{Moment(topic.updated).format("YYYY/MM/DD HH:mm")}
						</span>
						</Grid>
						<Grid item xs={4}>
							{topic.email}
						</Grid>
						<Grid item xs={4}>
							利用者ID：{topic.unique_id}
						</Grid>
						<Grid item xs={4}>
							名前：{topic.name}
						</Grid>
						<Grid item xs={12}>
							<div dangerouslySetInnerHTML={{__html: topic.text.replace(/\n/g, "<br />")}}></div>
						</Grid>
						<Grid item xs={12}>
							<div style={{textAlign: 'right'}}>
								{hiddenSwitch}
								<BsButton
									onClick={this.handleEditPost.bind(this, topic.post_id, topic)}
									color="olive"
									style={{marginLeft: 10}}
								>
									編集
								</BsButton>
								<BsButton
									onClick={this.handleDelThreadPerm}
									color="danger"
									style={{marginLeft: 10}}
								>
									完全に削除
								</BsButton>
							</div>
						</Grid>
					</Grid>
				</div>
			)
		}
		const commentsVd = (posts && posts.length) ? (
			<div>
				{_.map(_.orderBy(posts, ["updated"],["desc"]), comment => {
					const hiddenVd = (comment.hidden) ? (
						<span className="badge bg-red" style={{marginRight:8}}>非表示中</span>
					) : null
					const hiddenSwitch = (comment.hidden) ? (
						<BsButton
							color="navy"
							onClick={this.handleShowPost.bind(this, comment.uuid)}
						>
							表示する
						</BsButton>
					) : (
						<BsButton
							color="purple"
							onClick={this.handleHidePost.bind(this, comment.uuid)}
						>
							非表示化
						</BsButton>
					)
					return (
						<div key={comment.uuid} style={{padding: 16, marginBottom: 4, backgroundColor: "#e8eaf6"}}>
							<Grid container>
								<Grid item xs={6}>
									<span style={{fontSize: 16, fontWeight: 800, color: red[700]}}>
										{hiddenVd}
										{comment.title}
									</span>
								</Grid>
								<Grid item xs={3}>
									<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
										{comment.handle_name} ({comment.unique_id})
									</span>
								</Grid>
								<Grid item xs={3}>
									<span style={{fontSize: 12, fontWeight: 600, color: common.minBlack}}>
										{Moment(comment.updated).format("YYYY/MM/DD HH:mm")}
									</span>
								</Grid>
								<Grid item xs={12}>
									<div dangerouslySetInnerHTML={{__html: comment.text.replace(/\n/g, "<br />")}}></div>
								</Grid>
								<Grid item xs={12}>
									<div style={{textAlign: 'right'}}>
										{hiddenSwitch}
										<BsButton
											onClick={this.handleEditPost.bind(this, comment.uuid, comment)}
											color="olive"
											style={{marginLeft: 10}}
										>
											編集
										</BsButton>
									</div>
								</Grid>
							</Grid>
						</div>
					)
				})}
			</div>
		) : (
			<div></div>
		)
		return (
			<div>
				<div style={{margin: 10}}>
					<BsButton color="primary" onClick={this.props.onClose}>戻る</BsButton>
					{topicVd}
					<div style={{marginTop: 10}}>
						{commentsVd}
					</div>
					<Grid container style={{marginTop: 10}}>
						<Grid item xs={6} style={{padding: 10}}>
							<TextInput
								label="タイトル"
								state={this.state.titleState}
								onChange={(newState) => {
									this.setState({titleState: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6} style={{padding: 10}}>
							<TextInput
								label="ハンドルネーム"
								state={this.state.handleNameState}
								onChange={(newState) => {
									this.setState({handleNameState: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} style={{padding: 10}}>
							<TextInput
								label="内容"
								multiline
								rows={11}
								state={this.state.bodyState}
								onChange={(newState) => {
									this.setState({bodyState: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12} style={{padding: 10}}>
							<BsButton
								color="primary"
								onClick={this.handleReply}
							>
								返信を投稿
							</BsButton>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}
export default withRouter(withBbsDrawer(BbsPost))
