// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconSelector from '../../sonodalib/sl_mui/IconSelector'
import TextFieldSelector from '../../sonodalib/sl_mui/TextFieldSelector'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from '../../sonodalib/sl_utils/SlForms'
import {connect} from "react-redux";
import type {WmService, WmServiceType} from '../../shared/ServerInterface'
import G from "../../sonodalib/SlGlobal";
import {updateWmCurrService, updateWmServices} from "../MyPageAction";
import CurrentService from './CurrentService'
import {SelectorInputState} from "../../sonodalib/sl_utils/SlForms";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import Checkbox from '@material-ui/core/Checkbox';
import {loading} from "../../sonodalib/sl_utils/Utils";


type Props = {
	history: any,
	wmServiceList: Array<WmService>,
	currService:   WmService,
	wmServiceTypes:Array<WmServiceType>,
}
type States = {
	openAdd: boolean,									// 追加フォームの表示
	addServiceCode: any,	//TextInputState,
	addName: any,	//TextInputState,
	addType: any,	//SelectorInputState,
	addCopySrc: any,	//SelectorInputState,
}

function createInitialState(wmServiceTypes: Array<WmServiceType>) {
	return {
		addServiceCode: new TextInputState({validations: "require,integer"}),
		addName: new TextInputState({validations: "require"}),
		addType: new SelectorInputState({name: "type", menuList: wmServiceTypes}),
		addCopySrc: 0,
	}
}

class WmServiceList extends React.Component<Props, States> {
	state = {
		...createInitialState(this.props.wmServiceTypes),
		openAdd: false,
	}

	handleTogglePublicity = (service:WmService) => () => {
		const {currService} = this.props
		const changeTo 			 = (service.is_public) ? 0 : 1
		const changeToLabels = ["非公開", "公開"]
		if (window.confirm(service.name + " を " + changeToLabels[changeTo] + "　にしてもよろしいですか？")) {
			loading(true)
			post_wm("admin/service_publicity", {
				"service_code": service.service,
				"is_public":		changeTo,
			})
			.then((ret) => {
				if (ret.status === "ng") {
					alert(changeToLabels[changeTo] + "化に失敗しました")
				} else {
					updateWmServices(currService, ret.services)
					// G.store.dispatch(updateWmServicesAC(ret.services))
					G.store.dispatch(updateSnackbarAC(changeToLabels[changeTo] + "状態に変更しました"))
				}
			})
			.finally(() => {
				loading(false)
			})
		}
	}

	handleDelete = (service:WmService) => () => {
		const {currService} = this.props
		if (window.confirm(service.name + " を削除してよろしいですか？（"+service.name+"の問題、採点データ、アップロードファイル等もすべて削除されます）")) {
			if (window.confirm(service.service + " を本当に削除します（元に戻せません）")) {
				// if (currService && currservice.service === service.service) {
				// 	updateWmCurrService(undefined)
				// }
				loading(true)
				post_wm("admin/del_service", {
					"service_code": service.service
				})
				.then((ret) => {
					if (ret.status === "ng") {
						alert("削除に失敗しました")
					} else {
						updateWmServices(currService, ret.services)
						// G.store.dispatch(updateWmServicesAC(ret.services))
						G.store.dispatch(updateSnackbarAC("削除しました"))
					}
				})
				.finally(() => {
					loading(false)
				})
			}
		}
	}

	handleToggleShindanButton = (service:WmService) => () => {
		const {currService} = this.props
		const label = (service.hide_shindan) ? "表示状態" : "非表示"
		if (window.confirm(service.service + " の診断ボタンを "+label+" にします。よろしいですか？")) {
			post_wm("admin/upsert_shindan_button", {
				service_code: service.service,
				hide_shindan: service.hide_shindan ^ 1,
			})
			.then((ret) => {
				updateWmServices(currService, ret.services)
				// G.store.dispatch(updateWmServicesAC(ret.services))
			})
		}
	}

	handleToggleLock = (service:WmService) => () => {
		const {currService} = this.props
		const label = (service.locked) ? "編集可能" : "ロック"
		if (window.confirm(service.service + " を "+label+" 状態にします。よろしいですか？")) {
			post_wm("admin/upsert_lock", {
				service_code: service.service,
				locked: service.locked ^ 1,
			})
			.then((ret) => {
				updateWmServices(currService, ret.services)
				// G.store.dispatch(updateWmServicesAC(ret.services))
			})
		}
	}

	// １回目の 追加ボタン押下
	handleAdd = () => {
		this.setState(	{
			...createInitialState(this.props.wmServiceTypes),
			openAdd: true,
		})
	}

	// ２回目の 追加ボタン押下
	handleAdd2 = () => {
		const {currService} = this.props
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const serviceCode = this.state.addServiceCode.getValue()
				if (serviceCode < 100000 || 999999 < serviceCode) {
					alert("サービスコードは6桁の数値で入力してください")
				} else {
					post_wm("admin/add_service", {
						service_code: serviceCode,
						service_type: this.state.addType.getValue(),
						service_name: this.state.addName.getValue(),
					})
					.then((ret) => {
						if (ret.status === "ok") {
							updateWmServices(currService, ret.services)
							// G.store.dispatch(updateWmServicesAC(ret.services))
							alert("追加しました")
							this.setState({
								openAdd: false,
							})
						} else if (ret.status === "insert failed") {
							alert("追加に失敗しました（サービスコードの重複など。社会精神・介護の両方で重複できません）")
							// G.store.dispatch(updateSnackbarAC("追加に失敗しました（サービスコードの重複など。社会精神・介護の両方で重複できません）"))
						}
					})
					.catch(() => {
						alert("エラーが発生しました")
					})
				}
			}
		})
	}


	handleToggleCheckbox = (service?:WmService) => () => {
		updateWmCurrService(service)
	}

	render() {
		const {wmServiceList, currService, wmServiceTypes} = this.props
		const {openAdd, addServiceCode, addName} = this.state
		if (!wmServiceList || !wmServiceTypes || !wmServiceTypes.length) return null

		const typeObj = {}
		wmServiceTypes.forEach((row) => {
			typeObj[row.id] = row
		})

		const listVd = wmServiceList.map((service:WmService) => (
			<Grid key={service.service} container direction="row" alignItems="center" style={{marginTop:2, width:1000}}>
				<Grid key={service.service+"radio"} item style={{width:50}}>
					<span style={{fontSize:16}}>
						{(currService && currService.service === service.service) ? (
							<Checkbox
								checked={true}
								onChange={this.handleToggleCheckbox(undefined)}
								value="1"
								style={{width: 24, height: 24}}
							/>
						) : (
							<Checkbox
								checked={false}
								onChange={this.handleToggleCheckbox(service)}
								value="2"
								style={{width: 24, height: 24}}
							/>
						)}
					</span>
				</Grid>
				<Grid key={service.service+"service"} item style={{width:100}}>
					<span style={{fontSize:16}}>{service.service}</span>
				</Grid>
				<Grid key={service.service+"name"} item style={{width:1000-540-50}}>
					<span style={{fontSize:16, marginRight:10}}>{service.name}</span>
					{(service.is_public) ? <span className="label label-success" style={{fontSize:14}}>公開中</span> : <span className="label label-default" style={{fontSize:12}}>非公開中</span>}
				</Grid>
				<Grid key={service.service+"service_type"} item style={{width:120}}>
					<span style={{fontSize:12}}>{typeObj[service.service_type].label}</span>
				</Grid>
				<Grid item style={{width:80, fontSize:12}}>
					{(service.hide_shindan === 1) ? <span>非表示中</span> : <span>表示中</span>}
				</Grid>
				<Grid item style={{width:80, fontSize:12}}>
					{(service.shukei_agent === "no") ? <span></span> : <span style={{fontWeight:700,color:'red'}}>集計中</span>}
				</Grid>
				<Grid item style={{width:80}}>
					{(service.locked === 1) ? <span>ロック中</span> : <span> </span>}
				</Grid>
				<Grid key={service.service+"ctrl"} item style={{width:80, padding:0}}>
					<IconSelector
						menuList={[
							{id: 1, label: "公開・非公開切替", onClick: this.handleTogglePublicity(service)},
							{id: 2, label: "削除", 					onClick: this.handleDelete(service)},
							{id: 3, label: "診断ボタン切替",		onClick: this.handleToggleShindanButton(service)},
							{id: 4, label: "ロック切替", 			onClick: this.handleToggleLock(service)},
						]}
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider/>
				</Grid>
			</Grid>
		))

		const addPart = (!openAdd) ? null : (
			<div>
				<div style={{padding:20, fontWeight:700}}>
					サービス追加
				</div>
				<Grid container direction="row">
					<Grid item xs={6} style={{paddingRight:20}}>
						<SlTextField
							name="serviceCode"
							label="サービスコード"
							placeholder="例：202006"
							state={addServiceCode}
							onChange={(newState: TextInputState) => {
								this.setState({addServiceCode: newState})
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextFieldSelector
							label="サービスタイプ"
							state={this.state.addType}
							fullWidth={true}
							onChange={(newState: SelectorInputState) => {
								this.setState({addType: newState})
							}}
							InputLabelProps={{
								shrink: true,
								disableAnimation: true,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SlTextField
							name="name"
							label="名称"
							placeholder="例：2020年 第1回 Web模擬試験 社会福祉士・精神保健福祉士"
							state={addName}
							onChange={(newState: TextInputState) => {
								this.setState({addName: newState})
							}}
						/>
					</Grid>
				</Grid>
				<div style={{marginTop:30}}>
					<Button
						variant="contained"
						onClick={this.handleAdd2}
						color="primary"
					>
						追加
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							this.setState({
								openAdd: false,
							})
						}}
						style={{marginLeft:20}}
					>
						キャンセル
					</Button>
				</div>
			</div>
		)

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				<h3>サービスリスト</h3>
				<Grid container direction="row" alignItems="center" style={{marginTop:20, width:1000}}>
					<Grid item style={{width:150, fontSize:11,fontWeight:600}}>
						サービスコード
					</Grid>
					<Grid item style={{width:1000-540-50, fontSize:11,fontWeight:600}}>
						サービス名
					</Grid>
					<Grid item style={{width:120, fontSize:11,fontWeight:600}}>
						タイプ
					</Grid>
					<Grid item style={{width:80, fontSize:11,fontWeight:600}}>
						診断表ボタン
					</Grid>
					<Grid item style={{width:80, fontSize:11,fontWeight:600}}>
						自動集計
					</Grid>
					<Grid item style={{width:80, fontSize:11,fontWeight:600}}>
						ロック
					</Grid>
					<Grid item style={{width:80, fontSize:11,fontWeight:600}}>
						操作
					</Grid>
				</Grid>
				{listVd}
				{(openAdd) ? null : (
					<div style={{marginTop:30}}>
						<Button
							variant="contained"
							onClick={this.handleAdd}
							color="primary"
						>
							追加
						</Button>
					</div>
				)}
				<div style={{marginTop:30}}>
					{addPart}
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		wmServiceList: state.mypage.wmServiceList,
		currService:   state.mypage.currService,
		wmServiceTypes:state.mypage.wmServiceTypes,
	}),
)(withRouter(WmServiceList))