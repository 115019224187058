// @flow
import React from 'react'
import Moment from 'moment'
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {snackbar} from "../../sonodalib/sl_utils/Utils";


type State = {
	tasks: Array<any>,
}
export default class WmTasks extends React.Component<{},State> {
	state = {
		tasks: [],
		reject_shukei: false,
	}

	componentDidMount() {
		this.fetch(false)
	}

	fetch = (reject_shukei) => {
		post_wm("admin/fetch_tasks", {reject_shukei})
		.then((ret) => {
			this.setState({
				tasks: ret.tasks,
				reject_shukei: ret.reject_shukei,
			})
		})
	}

	update = (reject_shukei) => () => {
		this.fetch(reject_shukei)
		snackbar("更新しました")
	}

	handleCancel = (task) => () => {
		if (window.confirm(task.title + " の "+ task.method + " をキャンセルして良いですか？")) {
			post_wm("admin/cancel_task", {
				task_id: task.uuid,
				reject_shukei: this.state.reject_shukei,
			})
			.then((ret) => {
				this.setState({
					tasks: ret.tasks
				})
			})
		}
	}

	render() {
		const {tasks} = this.state
		const taskList = tasks.map((task) => {
			let result = task.result
			if (task.state === "処理待ち") {
				result = (
					<a onClick={this.handleCancel(task)} style={{cursor:'pointer'}}>予約をキャンセル</a>
				)
			} else if (task.state === "中止") {
				result = <span>キャンセル日時 {Moment(task.canceled).format("M/D HH:mm")}</span>
			}
			return (
				<TableRow key={task.uuid}>
					<TableCell padding="default">
						{task.title}
					</TableCell>
					<TableCell padding="default">
						{task.method}
					</TableCell>
					<TableCell padding="default">
						{Moment(task.requested).format("M/D HH:mm")}
					</TableCell>
					<TableCell padding="default">
						{(task.start_time) ? Moment(task.start_time).format("M/D HH:mm") : ""}
					</TableCell>
					<TableCell padding="default">
						{(task.end_time) ? Moment(task.end_time).format("M/D HH:mm") : ""}
					</TableCell>
					<TableCell padding="default">
						{task.state}
					</TableCell>
					<TableCell padding="default">
						{result}
					</TableCell>
				</TableRow>
			)
		})
		return (
			<div style={{padding: 20}}>
				<h3>採点・集計処理（直近 50件）</h3>
				<span>社会・精神、介護の両方の採点・集計処理が表示されます</span><br/><br/>
				<div style={{marginTop:20}}>
					<Button onClick={this.update(false)} variant="contained" color="primary" size="small">
						更新
					</Button>
					<Button onClick={this.update(true)} variant="contained" color="primary" size="small" style={{marginLeft:20}}>
						更新（定時集計は表示しない）
					</Button>
				</div>
				<Paper style={{marginTop:20}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell padding="default">サービス名</TableCell>
								<TableCell padding="default">処理内容</TableCell>
								<TableCell padding="default">予約日時</TableCell>
								<TableCell padding="default">開始日時</TableCell>
								<TableCell padding="default">終了日時</TableCell>
								<TableCell padding="default" style={{width:100}}>状態</TableCell>
								<TableCell padding="default">詳細</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{taskList}
						</TableBody>
					</Table>
				</Paper>
			</div>
		)
	}
}