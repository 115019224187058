// @flow
import React from 'react';
import type {WmService} from "../../shared/ServerInterface";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
import {connect} from "react-redux";
import CurrentService from "./CurrentService";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import G from "../../sonodalib/SlGlobal";
import {updateWmHanteiThAC} from "../MyPageAction";


type Props = {
	history: any,
	currService:   WmService,
	hanteiThList:	 Array<any>,
}
type States = {
	// values: Array<any>,
}

class WmHanteiTh extends React.Component<Props, States> {
	state = {
		// values: [],
	}

	// componentDidMount() {
	// 	this.fetch()
	// }
	//
	// fetch() {
	// 	const {currService} = this.props
	// 	if (currService) {
	// 		post_wm("admin/fetch_hantei_th", {
	// 			service_code: currService.service
	// 		})
	// 		.then((ret) => {
	// 			const values = ret.values.map((row) => {
	// 				row._th = new TextInputState({value:row.th})
	// 				return row
	// 			})
	// 			this.setState({
	// 				values,
	// 			})
	// 		})
	// 	}
	// }

	handleUpdate = (row) => () => {
		const {currService} = this.props
		if (currService) {
			let error = null
			const th = row._th.getValue().trim()
			if (th.length) {
				const _th = th.split(",")
				if (3 !== _th.length) {
					error = "書式が間違っています。 60,50,40 のように、カンマ区切りで、半角で入力してください"
				} else {
					_th.forEach((val) => {
						if (!val.match(/\d/)) error = "数値とカンマ以外は入力できません"
					})
				}
			}
			if (error) {
				alert(error)
			} else {
				if (window.confirm("更新してよろしいですか？")) {
					post_wm("admin/upsert_hantei_th", {
						service_code: currService.service,
						shiken_index: row.shiken_index,
						shindan_index: row.shindan_index,
						th: th,
					})
					.then((ret) => {
						alert("更新しました")
					})
				}
			}
		}
	}

	render() {
		const {currService, hanteiThList} = this.props
		const rowsVd = hanteiThList.map((row, index:number) => {
			return (
				<React.Fragment key={index}>
					<Grid item xs={4}>
						<span style={{fontSize:16}}>
							{row.label}
						</span>
					</Grid>
					<Grid item xs={7}>
						<SlTextField
							name={"th"+index}
							placeholder="空欄なら 60,50,40"
							state={row._th}
							onChange={(newState: TextInputState) => {
								// row._th = newState
								G.store.dispatch(updateWmHanteiThAC(hanteiThList.map((x) => (x.key !== row.key) ? x : {
									...row,
									_th: newState,
								})))
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<Button
							variant="contained"
							onClick={this.handleUpdate(row)}
							color="primary"
							size="small"
							disabled={(currService.locked === 1)}
						>
							更新
						</Button>
					</Grid>
				</React.Fragment>
			)
		})

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<div style={{margin:20}}>
							<h3>判定しきい値の編集</h3>
						</div>
						<Grid container style={{width:'100%'}} alignItems="center">
							<Grid item xs={4}>
								試験
							</Grid>
							<Grid item xs={8}>
								設定
							</Grid>
							{rowsVd}
						</Grid>
					</React.Fragment>
				)}
				<Paper elevation={0} style={{marginTop:50, padding:10}}>
					ロックがかかっている場合は、更新できません
				</Paper>
				<Paper elevation={0} style={{marginTop:10, padding:10}}>
					しきい値の値は、以下のようになります。<br/>
					60,50,40 の場合<br/>
					得点率 60%以上なら Ａ判定<br/>
					得点率 50%以上なら Ｂ判定<br/>
					得点率 40%以上なら Ｃ判定<br/>
					得点率 40%未満なら Ｄ判定
				</Paper>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
		hanteiThList:   state.mypage.hanteiThList,
	}),
)(WmHanteiTh)