import React from 'react';
// import { connect } from 'react-redux'
import ContentHeader from '../ContentHeader'
import { Flex, Box } from 'reflexbox'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import Button from '@material-ui/core/Button'
import {post} from '../../sonodalib/sl_utils/Http'
import { loadingVisibilityAC } from '../../sonodalib/sl_utils/SlUtilsAction'
import _ from 'lodash'
import G from '../../sonodalib/SlGlobal'
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'

class UserAdd extends React.Component {
	state = {
		tsv: new TextInputState({validation:"required"}),
		errorLine: 0,
		errorText: "",
		successText: "",
	}

	handleRun = () => {
		const tsv = this.state.tsv.getValue().trim()
		if (!tsv.length) {
			alert("CSVを入力してください")
			return
		}
		const tsvs = tsv.split("\n")
		const errorHappened = _.some(tsvs, (line, index) => {
			const cols = line.trim().split("\t")
			if (cols.length !== 10) {
				alert((index+1)+"行目は、10列である必要があります")
				return true
			}
			return false
		})
		if (!errorHappened) {
			const self = this
			const payload = {
				tsv,
			}
			const dispatch = G.store.dispatch
			dispatch(loadingVisibilityAC(true))
			post("admin/add_users",payload)
				.then(ret => {
					dispatch(loadingVisibilityAC(false))
					if (ret.error) {
						self.setState({
							errorLine: ret.error.line,
							errorText: ret.error.text,
							successText: "",
						})
					} else {
						self.setState({
							errorLine: 0,
							errorText: "",
							successText: <span>正常に処理されました<br/>{ret.added_count}件追加しました<br/>{ret.exist_count}件は既存でした<br/>{ret.delete_count}件削除しました</span>,
						})
					}
				})
				.catch(ret => {
					dispatch(loadingVisibilityAC(false))
					alert("不明なエラーが発生しました")
				})
		}
	}

	render() {
		const errorVd = (!this.state.errorLine) ? null : (
			<Box p={1}>
				<div className="alert alert-danger">
					<h4><i className="icon fa fa-ban"></i> {this.state.errorLine}行目でエラーが発生しました</h4>
					{this.state.errorText}
				</div>
			</Box>
		)

		const successVd = (!this.state.successText) ? null : (
			<Box p={1}>
				<div className="alert alert-success">
					<h4><i className="icon fa fa-check"></i> 処理に成功しました</h4>
					{this.state.successText}
				</div>
			</Box>
		)

		return (
			<div>
				<ContentHeader
					title="ユーザ一括登録"
					subTitle="Add users"
				/>
				<section className="content">
					<Flex column>
						<Box p={1}>
							<TextInput
								// label="本文"
								type="text"
								multiline={true}
								rows="8"
								state={this.state.tsv}
								onChange={(state) => {this.setState({tsv:state})}}
							/>
						</Box>
						{errorVd}
						{successVd}
						<Box p={1}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleRun.bind(this)}
							>
								実行
							</Button>
						</Box>
					</Flex>
					<div>
						書式<br />
						<a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1wSZEI3wGaEHUFH0mur_er8TGb57YVs0gGTIpfvvQhc0/edit?usp=sharing">マニュアル</a><br />
						団体ID　グループ１ID　グループ２ID　学生番号　パスワード　名前　備考　メールアドレス　登録　利用資格<br />
						※空白は、タブ<br />
						※登録は、本登録なら1  または 招待なら2<br />
						※利用資格は、0（お試し） 01（社会） 02（精神） 012（両方） 1（社会専門のみ） 2（精神専門のみ）<br />
					</div>
				</section>
			</div>
		)
	}
}

export default UserAdd