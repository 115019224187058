// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from "@material-ui/core/Paper";
import type {WmService} from "../../../shared/ServerInterface";
import Moment from "moment";


type Props = {
	currService: WmService,
}
type State = {
	fetched: boolean,
	stats: Array<any>,
}
class WmShukeiStats extends React.Component<Props, State> {
	state = {
		fetched: false,
		stats: [],
	}

	fetch = () => {
		const {currService} = this.props
		post_wm("admin/fetch_shukei_stats", {
			service_code: currService.service
		})
		.then((ret) => {
			this.setState({
				fetched: true,
				stats: ret.shukei_stats,
			})
		})
	}

	render() {
		const {fetched, stats} = this.state
		const totalCountsVd = (fetched && stats.length) ? stats.map((row) => (
			<TableRow key={row.id}>
				<TableCell padding="default">{row.service}</TableCell>
				<TableCell padding="default">{row.sum_id_label}</TableCell>
				<TableCell padding="default">{row.jukensha_count}</TableCell>
				<TableCell padding="default">{row.score_avg}</TableCell>
				<TableCell padding="default">{row.score_max}</TableCell>
				<TableCell padding="default">{row.score_min}</TableCell>
				<TableCell padding="default">{row.score_std}</TableCell>
				<TableCell padding="default">{row.gross_score}</TableCell>
				<TableCell padding="default">{row.gross_haiten}</TableCell>
				<TableCell padding="default">{Moment(row.reg_time).format("YYYY/MM/DD HH:mm:ss")}</TableCell>
			</TableRow>
		)) : (
			<TableRow>
				<TableCell padding="default">データはありません</TableCell>
			</TableRow>
		)
		const resultVd = (fetched) ? (
			<div>
				<Paper style={{marginTop:20}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell padding="default">サービス</TableCell>
								<TableCell padding="default" style={{width:100}}>集計単位</TableCell>
								<TableCell padding="default">受験者数</TableCell>
								<TableCell padding="default">平均点</TableCell>
								<TableCell padding="default">最高点</TableCell>
								<TableCell padding="default">最低点</TableCell>
								<TableCell padding="default">標準偏差</TableCell>
								<TableCell padding="default">合計得点</TableCell>
								<TableCell padding="default">合計配点</TableCell>
								<TableCell padding="default">集計日時</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{totalCountsVd}
						</TableBody>
					</Table>
				</Paper>
			</div>
		) : null
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>集計データの概要（更新ボタンを押してください）</h4>
				<div>
					<Button variant="contained" color="primary" size="small" onClick={this.fetch}>
						更新
					</Button>
				</div>
				{resultVd}
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmShukeiStats)