// @flow
import React from 'react'
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import {connect} from "react-redux";
import type {WmService} from '../../shared/ServerInterface'
import Paper from '@material-ui/core/Paper'
// import Button from '@material-ui/core/Button'

type Props = {
	history: any,
	currService: WmService
}
class CurrentService extends React.Component<Props> {
	render() {
		const {currService} = this.props
		const name = (!currService) ? "未選択" : currService.service + " " + currService.name
		// const changeButton = (
		// 	<Button style={{marginLeft:10}} color="primary" onClick={()=>{
		// 		history.push("/my/wm/service-list")
		// 	}}>変更</Button>
		// )
		return (
			<Paper style={{fontSize:14, padding:10, marginBottom:40}}>
				<Grid container direction="row" alignItems="center">
					<Grid item>
						選択中のサービス：
					</Grid>
					<Grid item>
						<span style={{fontSize:16, color:'#f44336', fontWeight:700}}>{name}</span>
					</Grid>
					{/*<Grid item>*/}
					{/*	{changeButton}*/}
					{/*</Grid>*/}
				</Grid>
			</Paper>
		)
	}
}
export default connect(
	state => ({
		currService: state.mypage.currService,
	}),
)(withRouter(CurrentService))