import React from 'react';
// import { connect } from 'react-redux'
import ContentHeader from '../ContentHeader'
import { Flex, Box } from 'reflexbox'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import Button from '@material-ui/core/Button'
import {post} from '../../sonodalib/sl_utils/Http'
import { loadingVisibilityAC } from '../../sonodalib/sl_utils/SlUtilsAction'
import _ from 'lodash'
import G from '../../sonodalib/SlGlobal'
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
// import {fetchGroupList} from '../../common/Common'

class GroupAdd extends React.Component {
	state = {
		tsv: new TextInputState({validation:"required"}),
		errorLine: 0,
		errorText: "",
		successText: "",
	}

	handleSubmit = (event) => {
		event.preventDefault()
		const tsv = this.state.tsv.getValue().trim()
		if (!tsv.length) {
			alert("CSVを入力してください")
			return
		}
		const tsvs = tsv.split("\n")
		const errorHappened = _.some(tsvs, (line, index) => {
			const cols = line.trim().split("\t")
			if (cols.length !== 3 && cols.length !== 6 && cols.length !== 9) {
				alert((index+1)+"行目は、3列, 6列, 9列である必要があります")
				return true
			}
			return false
		})
		if (!errorHappened) {
			const self = this
			const payload = {
				tsv,
			}
			const dispatch = G.store.dispatch
			dispatch(loadingVisibilityAC(true))
			post("admin/add_groups",payload)
				.then(ret => {
					dispatch(loadingVisibilityAC(false))
					if (ret.error) {
						self.setState({
							errorLine: ret.error.line,
							errorText: ret.error.text,
							successText: "",
						})
					} else {
						self.setState({
							errorLine: 0,
							errorText: "",
							successText: "正常に処理されました。リロードすると反映されます",
						})
					}
				})
				.catch(ret => {
					dispatch(loadingVisibilityAC(false))
					alert("不明なエラーが発生しました")
				})
		}
	}

	render() {
		const errorVd = (!this.state.errorLine) ? null : (
			<Box p={1}>
				<div className="alert alert-danger">
					<h4><i className="icon fa fa-ban"></i> {this.state.errorLine}行目でエラーが発生しました</h4>
					{this.state.errorText}
				</div>
			</Box>
		)

		const successVd = (!this.state.successText) ? null : (
			<Box p={1}>
				<div className="alert alert-success">
					<h4><i className="icon fa fa-check"></i> 処理に成功しました</h4>
					{this.state.successText}
				</div>
			</Box>
		)

		return (
			<div>
				<ContentHeader
					title="グループ一括登録"
					subTitle="Add groups"
				/>
				<section className="content">
					<form onSubmit={this.handleSubmit}>
						<Flex column>
							<Box p={1}>
								<TextInput
									// label="本文"
									type="text"
									multiline={true}
									rows="8"
									state={this.state.tsv}
									onChange={(state) => {this.setState({tsv:state})}}
								/>
							</Box>
							{errorVd}
							{successVd}
							<Box p={1}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
								>
									実行
								</Button>
							</Box>
						</Flex>
					</form>
					<div>
						※
						<a href="https://docs.google.com/spreadsheets/d/12TcrjmuxMEl9KUzZoZ1HYJ1qiSoXGRzOzYfWkqe7WU0/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
							グループ一括登録仕様
						</a>
					</div>
				</section>
			</div>
		)
	}
}

export default GroupAdd