// @flow
// MK-0710

import React from 'react';
import {connect} from "react-redux";
import FileUpload from './FileUpload'
import CurrentService from "./CurrentService";
import Paper from "@material-ui/core/Paper";


type Props = {
	currService: number,
}
type States = {
	imageName1State: string,
}

class WmFileUploader extends React.Component<Props, States> {
	state = {
		imageName1State: "",
	}

	onUploaded() {
		alert("アップロードしました")
	}

	render() {
		const {currService} = this.props
		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<div>
						<FileUpload
							currService={currService}
							id={1}
							serverEndPoint="admin/upload_file"
							onUploaded={this.onUploaded}
							// service={currService.service}
						/>
						{this.state.imageName1State}

						<Paper elevation={0} style={{marginTop:50, padding:10}}>
							※問題、マークシート、解答解説PDFは、以下のようなファイル名でアップロードしてください。<br />
							<table className="table" style={{width:'100%',border:'1px solid #666'}}>
								<thead>
								<tr><th> </th><th>ファイル名</th><th>例</th></tr>
								</thead>
								<tbody>
								<tr><td>問題PDF</td><td>Q_(科目コード).pdf</td><td>Q_201301H1.pdf</td></tr>
								<tr><td>マークシートPDF</td><td>M_(科目コード).pdf</td><td>M_201301H1.pdf</td></tr>
								<tr><td>解答解説PDF</td><td>A_(科目コード).pdf</td><td>A_201301H1.pdf</td></tr>
								</tbody>
							</table>
							またはカテゴリ番号を指定すると、共通問題／各専門問題単位のPDFを指定できます。カテゴリ番号は、管理ページの「科目の管理」で確認できます
							<table className="table" style={{width:'100%',border:'1px solid #666'}}>
								<thead>
								<tr><th> </th><th>ファイル名</th><th>例</th></tr>
								</thead>
								<tbody>
								<tr><td>問題PDF</td><td>Q_(catカテゴリ番号).pdf</td><td>Q_cat0.pdf</td></tr>
								<tr><td>マークシートPDF</td><td>M_(catカテゴリ番号).pdf</td><td>M_cat0.pdf</td></tr>
								<tr><td>解答解説PDF</td><td>A_(catカテゴリ番号).pdf</td><td>A_cat0.pdf</td></tr>
								</tbody>
							</table>
						</Paper>

					</div>
				)}
			</div>
		)
	}

}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmFileUploader)
