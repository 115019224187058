// @flow
import React, {Component} from 'react'
import {TextInputState} from '../sonodalib/sl_utils/SlForms'
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import {fetchLogin} from "../sonodalib/sl_users/SlUsersAction";
import {contentLabel} from '../alt/constants'


type Props = {}
type States = {
	email: TextInputState,
	password: TextInputState,
}
export default class LoginContainer extends Component<Props, States> {
	state = {
		email: new TextInputState({validation: "required,email"}),
		password: new TextInputState({validation: "required,password"}),
	}

	handleSubmit = (event: SyntheticEvent<*>) => {
		event.preventDefault()
		this.setState({
			email: this.state.email.validate(),
			password: this.state.password.validate(),
		}, () => {
			const {email, password} = this.state
			if (email.isValid() && password.isValid()) {
				fetchLogin(email.getValue(), password.getValue(), "admin", undefined, (message:string) => {
					this.setState({
						email: email.setError(message),
						password: password.setError(message),
					})
				})
			}
		})
	}

	render() {
		return (
			<div className="login-box">
				<div className="login-logo">
					赤マル福祉管理ページ（{contentLabel}）
				</div>
				<div className="login-box-body">
					<form onSubmit={this.handleSubmit}>
						<SlTextField
							label="メールアドレス"
							placeholder="jcedu@example.com"
							state={this.state.email}
							onChange={(state) => {
								this.setState({email: state})
							}}
						/>
						<SlTextField
							type="password"
							label="パスワード"
							placeholder="パスワード"
							state={this.state.password}
							onChange={(state) => {
								this.setState({password: state})
							}}
						/>
						<div className="row">
							<div className="col-xs-8">
							</div>
							<div className="col-xs-4">
								<button type="submit" className="btn btn-primary btn-block btn-flat">ログイン</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
