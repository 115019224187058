// @flow
import React from 'react'

export default class HowToUserImage extends React.Component<{}> {
	render() {
		return (
			<div>
				<section className="content-header">
					<h1>
						画像を貼る方法
						<small>How to use image</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">How to use image</li>
					</ol>
				</section>
				<section className="content">

					<div className="box box-success">
						<div className="box-header with-border">
							<h3 className="box-title">画像ファイルのアップロード</h3>
						</div>
						<div className="box-body">
							<a href="https://forest.watch.impress.co.jp/library/software/winscp/" target="_blank" rel="noopener noreferrer">WinSCP</a> 等のツールを使って、アップロードします。<br /><br />
							<span style={{fontWeight:'bold'}}>接続</span><br />
							<table className="table table-bordered">
								<tbody>
								<tr>
									<th>サーバ</th>
									<th>プロトコル</th>
									<th>ホスト</th>
									<th>ポート</th>
									<th>ユーザ名</th>
									<th>パスワード</th>
								</tr>
								<tr>
									<td>開発サーバ</td>
									<td>SFTP</td>
									<td>sanjoaquin.sndpr.net</td>
									<td>22</td>
									<td>devel</td>
									<td>i8iFoCTEBs47Ye</td>
								</tr>
								<tr>
									<td>本番サーバ</td>
									<td>SFTP</td>
									<td>www2.akamaru.jp</td>
									<td>22</td>
									<td>devel</td>
									<td>i8iFoCTEBs47Ye</td>
								</tr>
								</tbody>
							</table>
							<br />
							<table className="table table-bordered">
								<tbody>
								<tr>
									<td>画像ファイルを置く場所</td>
									<td>/home/devel/work/fukushi/static/img<br />※自由にサブディレクトリを作成できます</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="box box-warning">
						<div className="box-header with-border">
							<h3 className="box-title">問題データ等での書き方</h3>
						</div>
						<div className="box-body">
							<pre>{`<img src="/img/ファイル名"/>`}</pre>
							例：/home/devel/work/fukushi/static/img/q6114.jpg　を表示させる場合
							<pre>{`<img src="/img/q6114.jpg"/>`}</pre>
						</div>
					</div>

				</section>
			</div>
		)

	}
}
