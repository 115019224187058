import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {genericModal} from '../Utils'
import {OkCancelModal} from '../../sl_adminlte/Modals'

class GenericModal extends Component {

	render() {
		return (
			<OkCancelModal
				show={this.props.open}
				okText="閉じる"
				bodyText={this.props.text}
				onOk={() => {
					genericModal(false)
				}}
			/>
		)
	}
}
GenericModal.propTypes = {
	open: PropTypes.bool.isRequired,
};
export default connect(
	state => ({
		open: state.sl_utils.genericModalOpen,
		text: state.sl_utils.genericModalText,
	}),
)(GenericModal);
