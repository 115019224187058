// @flow
import React from 'react'
import Cookies from "js-cookie";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import Grid from '@material-ui/core/Grid'
import Divider from "@material-ui/core/Divider";
import {loading} from '../../sonodalib/sl_utils/Utils'
import type {WmService} from "../../shared/ServerInterface";
// import Divider from '@material-ui/core/Divider'

type Props = {
	id: string,
	// service: number,
	currService: WmService,
}
type States = {
	files: Array<any>
}

export default class FileUpload extends React.Component<Props, States> {
	state = {
		files: [],
	}

	updateFiles(service, files) {
		const _files = files.map((row) => {
			return {
				uuid: row.uuid,
				// src: `/files/${service}/${row.uuid}${row.ext}`,
				src: `/files/${service}/${row.original}`,
				isImage: [".jpg", ".gif", ".png"].indexOf(row.ext.toLowerCase()) !== -1,
				original: row.original,
			}
		})
		this.setState({
			files: _files,
		})
	}

	onChangeImage = (event:any) => {
		const {id, serverEndPoint, currService} = this.props
		const {service} = currService
		const file = event.target.files[0]
		if (!file) return
		const fd = new FormData(document.forms.namedItem("upload_form"+id))
		fd.append("params",JSON.stringify({
			user_token: Cookies.get("user_token"),
			form_name: "name-input-el"+id,
			service,
		}))
		loading(true)
		post_wm(serverEndPoint, fd)
		.then((ret) => {
			this.updateFiles(service, ret.files)
		})
		.finally(()=>{
			loading(false)
		})
	}

	componentDidMount() {
		this.fetch()
	}

	fetch() {
		const {currService} = this.props
		post_wm("admin/fetch_uploaded_files", {
			service: currService.service
		})
		.then((ret) => {
			this.updateFiles(currService.service, ret.files)
		})
	}

	delete = (row) => () => {
		if (window.confirm(row.original + " を削除してもよろしいですか？")) {
			const {currService} = this.props
			post_wm("admin/delete_uploaded_file", {
				file_id: row.uuid,
			})
			.then((ret) => {
				this.updateFiles(currService.service, ret.files)
				alert("削除しました")
			})
		}
	}

	render() {
		const {id, currService} = this.props
		const {files} = this.state
		const fileListVd = (files.length) ? files.map((row) => {
			return (row.isImage) ? (
				<React.Fragment key={row.uuid}>
					<Grid item xs={10} style={{marginTop:10}}>
						<a href={row.src} target="_blank" style={{marginRight:20}}>
							{row.original}
							<img src={row.src} alt="img" style={{marginLeft:20, verticalAlign:'middle', height:48}}/>
						</a>
						<span style={{fontFamily:'monospace',fontSize:16}}>[[img:{row.original}]]</span>
					</Grid>
					<Grid item xs={2}>
						{(currService.locked) ? "ロック中" : (
							<button style={{marginRight:20}} onClick={this.delete(row)}>削除</button>
						)}
					</Grid>
					<Grid item xs={12} style={{marginTop:10, marginBottom:10}}>
						<Divider/>
					</Grid>
				</React.Fragment>
			) : (
				<React.Fragment key={row.uuid}>
					<Grid item xs={10} style={{marginTop:10}}>
						<a href={row.src} target="_blank">
							{row.original}
						</a>
					</Grid>
					<Grid item xs={2}>
						{(currService.locked) ? "ロック中" : (
							<button style={{marginRight:20}} onClick={this.delete(row)}>削除</button>
						)}
					</Grid>
					<Grid item xs={12} style={{marginTop:10, marginBottom:10}}>
						<Divider/>
					</Grid>
				</React.Fragment>
			)
		}) : (
			<Grid item xs={12}>
				アップロードされたファイルはありません
			</Grid>
		)
		return (
			<div style={{padding: 20}}>
				<div>
					<Grid container direction="row" alignItems="center" style={{width:'100%'}}>
						{fileListVd}
					</Grid>
					{(currService.locked) ? "ロック中なので、アップロードできません" : (
						<div style={{marginTop:40}}>
							<form id={"upload_form"+id} encType="multipart/form-data">
								<input id={"input-element-"+id}
											 onChange={this.onChangeImage}
											 name={"name-input-el"+id}
											 accept="*/*"
											 type="file"
								/>
							</form>
						</div>
					)}
				</div>
			</div>
		)
	}
}
