// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import {post} from "../../sonodalib/sl_utils/Http";
import {updateSnackbarAC} from '../../sonodalib/sl_utils/SlUtilsAction'
import G from "../../sonodalib/SlGlobal";

type Props = {
	infoText: any,
	onClose: (isCanceled: boolean) => void,
}
type States = {
	dantaiState: TextInputState,
	orderState: TextInputState,
	bodyState: TextInputState,
}

export default class EditLoginInfo extends React.Component<Props, States> {
	state = {
		dantaiState: new TextInputState({validations: "required"}),
		orderState: new TextInputState({validations: "required"}),
		bodyState: new TextInputState({validations: "required"}),
	}

	componentWillMount() {
		const infoText = this.props.infoText
		const {dantaiState, orderState, bodyState} = this.state
		this.setState({
			dantaiState: dantaiState.setValue(infoText.dantai_id),
			orderState: orderState.setValue(infoText.order),
			bodyState: bodyState.setValue(infoText.body),
		})
	}

	handleSave = () => {
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const {dantaiState, orderState, bodyState} = this.state
				const payload = {
					row: {
						uuid: "",
						dantai_id: dantaiState.getValue(),
						order: orderState.getValue(),
						body: bodyState.getValue(),
					},
				}
				if (this.props.infoText.uuid.length) {	// 編集時
					payload.row.uuid = this.props.infoText.uuid
				}
				post("etc/logininfo/update", payload)
				.then(() => {
					G.store.dispatch(updateSnackbarAC("編集しました"))
					this.props.onClose(false)
				})
			}
		})
	}

	handleCancel = () => {
		G.store.dispatch(updateSnackbarAC("編集をキャンセルしました"))
		this.props.onClose(true)
	}

	render() {
		const {dantaiState, orderState, bodyState} = this.state
		return (
			<div>
				<div className="box box-success">
					<div className="box-header with-border">
						<h3 className="box-title">表示内容</h3>
					</div>
					<div className="box-body">
						<Grid container direction="row">
							<Grid item xs={6}>
								<SlTextField
									state={dantaiState}
									label="団体ID（0=その他）"
									multiline
									placeholder="団体ID（0=その他）"
									onChange={(newState) => {
										this.setState({dantaiState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<SlTextField
									type="number"
									inputProps={{
										min: 0,
										max: 99,
									}}
									state={orderState}
									label="表示順（値が小さいほど上　0なら非表示）"
									placeholder="1〜99"
									onChange={(newState) => {
										this.setState({orderState: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<SlTextField
									state={bodyState}
									label="本文"
									multiline
									placeholder="本文（タグの利用、複数行可）"
									onChange={(newState) => {
										this.setState({bodyState: newState})
									}}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<Grid item xs={12}>
					<button type="button" className="btn btn-primary" onClick={this.handleSave}>保存</button>
					<button type="button" className="btn btn-default" style={{marginLeft: 30}} onClick={this.handleCancel}>キャンセル
					</button>
				</Grid>
			</div>
		)
	}
}
