import React from 'react'
import Selector from './Selector'
import { getPrefectures } from '../sl_utils/Address'
import _ from 'lodash'

export default class PrefSelector extends React.Component {

	componentWillMount() {
		let children = []
		let list = []
		let index = 0

		_.forEach(getPrefectures(), pref => {
			children.push(
				<option key={pref.id} value={index}>{pref.label}</option>
			)
			list[index++] = pref.id
		})
		this.setState({
			"children": children,
			"list": list,
		})
	}

	render() {
		const { ...props } = this.props
		return (
			<Selector
				{...props}
			>
				{this.state.children}
			</Selector>
		)
	}

}
