// @flow
import React from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Moment from 'moment'
import {licenseList} from '../../alt/constants'
import {Flex, Box} from 'reflexbox'
import {getPrefectures} from '../../sonodalib/sl_utils/Address'
import {OkCancelModal} from '../../sonodalib/sl_adminlte/Modals'
import Selector from '../../sonodalib/sl_adminlte/Selector'
import PrefSelector from '../../sonodalib/sl_adminlte/PrefSelector'
import _ from 'lodash'
import TextInput from "../../sonodalib/sl_adminlte/TextInput";
import {post} from '../../sonodalib/sl_utils/Http'
import {normalizeRow} from '../../sonodalib/sl_utils/Utils'
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import G from "../../sonodalib/SlGlobal";
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import HistoryKamokuHyouka from './HistoryKamokuHyouka'
import HistoryDetail from './HistoryDetail'
import MyPagePreview from './MyPagePreview'
import Cookies from "js-cookie";
import {saveAs} from 'file-saver'
import {loadingVisibilityAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import {urlPref, hostPrefix, nendoList} from '../../alt/constants'
import PaymentHistory from "./PaymentHistory";

const limitedList = ["集計対象", "除外する"]

type UserRow = {
	uuid: string,
	uniqueId: number,
	kana: TextInputState,
	name: TextInputState,
	email: TextInputState,
	telno: TextInputState,
	zipcode: TextInputState,
	addressPref: number,
	addressCity: TextInputState,
	addressStreet: TextInputState,
	groupText: string,
	license: string,
	limited: number,
	dantaiId: string,
	group1Id: string,
	group2Id: string,
}

type Props = {
	userId: string,
	onClose: ()=>{},
	allGroups: {
		groupList: any,
	},
	// match: any,
	history: any,
}
type States = {
	editing: boolean,
	showSaveModal: boolean,
	showRemoveModal: boolean,
	showHistory: boolean,
	showMyPage: boolean,
	showHistoryDetail: boolean,
	showPaymentHistory: boolean,
	historyMondaiType: number,
	historyKamokuID: number,
	userRow: UserRow,
	calendarYear: number,
	calendarMonth: number,
}
class UserDetail extends React.Component<Props, States> {
	userRowBackup: UserRow

	state:States = {
		editing: false,
		showSaveModal: false,
		showRemoveModal: false,
		showHistory: false,
		showMyPage: false,
		showHistoryDetail: false,
		showPaymentHistory: false,
		historyMondaiType: 0,
		historyKamokuID: 0,
		userRow: {
			uuid: "",
			uniqueId: 0,
			kana: new TextInputState(),
			name: new TextInputState(),
			email: new TextInputState({validation:"required,email"}),
			telno: new TextInputState(),
			zipcode: new TextInputState(),
			addressPref: 0,
			addressCity: new TextInputState(),
			addressStreet: new TextInputState(),
			groupText: "",
			license: "",
			limited: 0,
			dantaiId: "",
			group1Id: "",
			group2Id: "",
		},
		calendarYear: 0,
		calendarMonth: 0,
	}

	componentWillMount() {
		const payload = {
			// user_id: this.props.match.params.userId,
			user_id: this.props.userId,
		}
		post("admin/fetch_user", payload)
		.then((ret) => {
			const r:any = normalizeRow(ret)
			const curr = this.state.userRow

			const groupList = this.props.allGroups.groupList.toJS()
			let dantaiId = r.parent
			let group1Id = ""
			let group2Id = ""
			if (dantaiId) {
				const parent = _.find(groupList, {uuid:r.parent})
				switch (parent.layer) {
					case 20:
						dantaiId = parent.parent
						group1Id = r.parent
						break;
					case 30:
						const group1 = _.find(groupList, {uuid: parent.parent})
						dantaiId = group1.parent
						group1Id = parent.parent
						group2Id = r.parent
						break;
					default:
				}
			}
			const update:UserRow = {
				...curr,
				uuid: r.uuid,
				uniqueId: r.uniqueId,
				kana: curr.kana.setValue(r.kana),
				name: curr.name.setValue(r.name),
				email: curr.email.setValue(r.email),
				telno: curr.telno.setValue(r.telno),
				zipcode: curr.zipcode.setValue(r.zipcode),
				addressPref: r.addressPref,
				addressCity: curr.addressCity.setValue(r.addressCity),
				// addressStreet: curr.addressStreet.setValue(r.addressStreet),
				groupText: r.groupText,
				license: r.license,
				limited: r.limited,
				dantaiId,
				group1Id,
				group2Id,
			}
			const d = new Date()
			this.setState({
				userRow: update,
				calendarYear: d.getFullYear(),
				calendarMonth: d.getMonth()+1,
			})
		})
		.catch(ret => {
			alert("不明なエラーが発生しました")
			window.location.href = "/"
		})
	}

	buildForm(paramName, paramValue, label, editVd) {
		const inputVd = (editVd && this.state.editing) ? editVd : (
			<p className="form-control-static">{paramValue}</p>
		)
		return (
			<div className="form-group">
				<label htmlFor={paramName} className="col-sm-3 control-label">{label}</label>
				<div className="col-sm-9">
					{inputVd}
				</div>
			</div>
		)
	}

	handleRemove = () => {
		this.setState({showRemoveModal: true})
	}

	handleOkRemoveModal = () => {
		const dispatch = G.store.dispatch
		const curr = this.state.userRow
		dispatch(loadingVisibilityAC(true))
		post("user/delete_perm", {
			user_token: Cookies.get("sl_users"),
			user_id: curr.uuid,
			// uuid: curr.uuid,
		})
		.then(ret => {
			this.setState({
				showRemoveModal: false,
			})
			alert("削除しました")
			// G.store.dispatch(updateSnackbarAC("削除しました"))
			this.props.onClose()
			// this.props.history.push("/my/user/search")
		})
		.catch(ret => {
			this.setState({showRemoveModal: false})
			G.store.dispatch(updateSnackbarAC("不明なエラーが発生しました"))
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	handleSuperLogin = () => {
		const curr = this.state.userRow
		post("user/prepare-super-login", {
			user_id: curr.uuid,
		})
		.then((ret) => {
			if (ret.token) {
				// window.open("http://fukushi.millbrae.sndpr.net:8003/super-login/"+ret.token)
				const host = window.location.host
				// window.open(window.location.protocol + "//" + host.substr(6) + "/super-login/" + ret.token)
				const prefix = hostPrefix+"."
				if (host.startsWith(prefix)) {
					window.open(window.location.protocol + "//" + host.substr(prefix.length) + urlPref + "/super-login/" + ret.token)
				}
				if (host === 'fukushi.localhost.sndpr.net:8005') {
					window.open("http://fukushi.localhost.sndpr.net:8003/k/super-login/" + ret.token)
				}
			}
		})
	}

	handleShowMyPage = () => {
		this.setState({
			showMyPage: true,
			showHistory: false,
			showPaymentHistory: false,
		})
	}

	handleShowHistory = () => {
		this.setState({
			showHistory: true,
			showMyPage: false,
			showPaymentHistory: false,
		})
	}

	handleAllHistoryCsvDl = () => {
		if (window.confirm("すべての学習履歴をダウンロードしてよろしいですか？")) {
			const curr = this.state.userRow
			post("admin/fetch_all_history_csv", {
				user_id: curr.uuid,
			})
			.then(ret => {
				const csvs = ret.history.map((row) => (
					`"${Moment(row.updated).format("YYYY/MM/DD HH:mm:ss")}",\
"${row.type_text}",\
"${row.MondaiID}",\
"${row.MarubatsuID}",\
"${row.KamokuID}",\
"${row.Kamokumei}",\
"${row.mondai_bun}",\
"${row.choose}",\
"${row.result}"`
				))
				csvs.unshift("日付,問題種類,問題ID,OXID,科目ID,科目名,問題文冒頭,選択,結果")
				const csv = csvs.join("\n")
				const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
				const blob = new Blob([bom, csv], {"type": "text/csv"});
				saveAs(blob, "全学習履歴_"+curr.uniqueId+".csv")
			})
			.catch(ret => {
			})

		}
	}

	handleShowPaymentHistory = () => {
		this.setState({
			showHistory: false,
			showHistoryDetail: false,
			showMyPage: false,
			showPaymentHistory: true,
		})

	}

	handleEdit = () => {
		this.userRowBackup = this.state.userRow
		this.setState({editing: true})
	}

	handleCancel = () => {
		this.setState({
			userRow: this.userRowBackup,
			editing: false,
		})
	}

	handleSave = () => {
		this.setState({showSaveModal: true})
	}

	handleOkSaveModal = () => {
		const curr = this.state.userRow
		let parent = curr.dantaiId
		if (curr.group1Id) parent = curr.group1Id
		if (curr.group2Id) parent = curr.group2Id
		if (!parent.length) parent = null
		const userRow = {
			uuid: curr.uuid,
			unique_id: curr.uniqueId,
			kana: curr.kana.getValue(),
			name: curr.name.getValue(),
			email: curr.email.getValue(),
			telno: curr.telno.getValue(),
			zipcode: curr.zipcode.getValue(),
			address_pref: curr.addressPref,
			address_city: curr.addressCity.getValue(),
			address_street: curr.addressStreet.getValue(),
			group_text: curr.groupText,
			license: curr.license,
			limited: curr.limited,
			parent,
		}
		const payload = {
			row: userRow,
		}
		post("admin/update_user", payload)
		.then(ret => {
			this.setState({editing: false, showSaveModal: false})
		})
		.catch(ret => {
			this.setState({showSaveModal: false})
			if (ret && ret.statusCode === 401) {
				G.store.dispatch(updateSnackbarAC("メールアドレスが重複しています"))
			} else {
				G.store.dispatch(updateSnackbarAC("不明なエラーが発生しました"))
			}
		})
	}

	handleBack = () => {
		this.props.onClose()
		// this.props.history.goBack()
	}

	handleClickSubject = (MondaiType, KamokuID) => {
		this.setState({
			showHistory: false,
			showHistoryDetail: true,
			showPaymentHistory: false,
			historyMondaiType: MondaiType,
			historyKamokuID: KamokuID,
		})
	}

	handleShowOx = () => {
	}

	handleOpenMemo = () => {
	}

	handleShowSentaku = () => {
	}

	handleBackFromDetail = () => {
		this.setState({
			showHistory: true,
			showHistoryDetail: false,
			showPaymentHistory: false,
		})
	}

	handleChangeCalendarDate = (year, month) => {
		this.setState({
			calendarYear: year,
			calendarMonth: month,
		})
	}

	render() {
		const {userRow, showHistory, showMyPage, showHistoryDetail, showPaymentHistory} = this.state
		const {uniqueId, kana, name, email, zipcode, addressPref, addressCity, dantaiId, group1Id, group2Id, groupText, license, limited} = userRow
		const userIdVd = this.buildForm("", uniqueId, "ID", null)
		const kanaVd = this.buildForm("kana", kana.getValue(), "氏名（カナ）", (
			<TextInput
				type="text"
				className="form-control"
				id="kana"
				hintText="氏名（カナ）"
				state={kana}
				onChange={value => {
					this.setState({userRow: {...userRow, kana: value}})
				}}
			/>
		))
		const nameVd = this.buildForm("name", name.getValue(), "氏名", (
			<TextInput
				type="text"
				className="form-control"
				id="name"
				hintText="氏名"
				state={name}
				onChange={value => {
					this.setState({userRow: {...userRow, name: value}})
				}}
			/>
		))
		const emailVd = this.buildForm("email", email.getValue(), "メールアドレス", (
			<TextInput
				type="email"
				className="form-control"
				id="email"
				hintText="メールアドレス"
				state={email}
				onChange={value => {
					this.setState({userRow: {...userRow, email: value}})
				}}
			/>
		))
		const zipcodeVd = this.buildForm("zipcode", zipcode.getValue(), "郵便番号", (
			<TextInput
				type="text"
				className="form-control"
				id="zipcode"
				hintText="郵便番号"
				state={zipcode}
				onChange={value => {
					this.setState({userRow: {...userRow, zipcode: value}})
				}}
			/>
		))
		const _addressPref = _.find(getPrefectures(), {id:addressPref})
		const addressPrefVd = (!_addressPref) ? null : this.buildForm("addressPref", _addressPref.label, "都道府県", (
			<PrefSelector
				className="form-control"
				value={addressPref}
				onChange={value => {
					this.setState({userRow: {...userRow, addressPref: value}})
				}}
			/>
		))
		const addressCityVd = this.buildForm("addressCity", addressCity.getValue(), "住所１", (
			<TextInput
				type="text"
				className="form-control"
				id="addressCity"
				hintText="住所１"
				state={addressCity}
				onChange={value => {
					this.setState({userRow: {...userRow, addressCity: value}})
				}}
			/>
		))

		const groupList = this.props.allGroups.groupList.toJS()
		const group1Text = (group1Id) ? _.find(groupList, {uuid:group1Id}).name : ""
		const group1Vd = this.buildForm("group1", group1Text, "グループ１", (
			<Selector
				className="form-control"
				value={group1Id}
				onChange={value => {
					this.setState({userRow: {...userRow, group1Id: value, group2Id:""}})
				}}
			>
				{[<option key={_.uniqueId("grp1")} value={""}>（無指定）</option>].concat(
					_.filter(groupList,{parent:dantaiId, layer:20}).map(row => (
					<option key={row.uuid} value={row.uuid}>{row.name}</option>
				)))}
			</Selector>
		))

		const group2Text = (group2Id) ? _.find(groupList, {uuid:group2Id}).name : ""
		const group2Vd = this.buildForm("group2", group2Text, "グループ２", (
			<Selector
				className="form-control"
				value={group2Id}
				onChange={value => {
					this.setState({userRow: {...userRow, group2Id: value}})
				}}
			>
				{[<option key={_.uniqueId("grp2")} value={""}>（無指定）</option>].concat(
					_.filter(groupList,{parent:group1Id, layer:30}).map(row => (
					<option key={row.uuid} value={row.uuid}>{row.name}</option>
				)))}
			</Selector>
		))

		// const addressStreetVd = this.buildForm("addressStreet", addressStreet.getValue(), "住所２", (
		// 	<TextInput
		// 		type="text"
		// 		className="form-control"
		// 		id="addressStreet"
		// 		hintText="住所２"
		// 		state={addressStreet}
		// 		onChange={value => {
		// 			this.setState({userRow: {...userRow, addressStreet: value}})
		// 		}}
		// 	/>
		// ))
		const _license = _.find(licenseList, {id:license})
		const licenseVd = (!_license) ? null : this.buildForm("license", _license.label+"（"+_license.id+"）", "利用資格", (
			<Selector
				className="form-control"
				value={license}
				onChange={value => {
					this.setState({userRow: {...userRow, license: value}})
				}}
			>
				{licenseList.map(row => (
					<option key={row.id} value={row.id}>{row.label}（{row.id}）</option>
				))}
			</Selector>
		))
		const _limitedLabel = limitedList[limited]
		const limitedVd = this.buildForm("limited", _limitedLabel, "集計除外", (
			<Selector
				className="form-control"
				value={limited}
				onChange={value => {
					this.setState({userRow: {...userRow, limited: value}})
				}}
			>
				{limitedList.map((row,index) => (
					<option key={index} value={index}>{row}</option>
				))}
			</Selector>
		))
		const groupTextVd = this.buildForm("", groupText, "所属団体", null)
		const actions = (this.state.editing) ? (
			<div>
				<button type="button" className="btn btn-default" onClick={this.handleCancel}>キャンセル</button>
				<button type="button" className="btn btn-danger" onClick={this.handleSave} style={{marginLeft:10}}>保存</button>
			</div>
		) : (
			<div>
				<button type="button" className="btn btn-info" onClick={this.handleEdit} style={{marginRight:8}}>編集</button>
				<button type="button" className="btn btn-info" onClick={this.handleShowHistory} style={{marginRight:8}}>学習履歴</button>
				<button type="button" className="btn btn-info" onClick={this.handleShowMyPage} style={{marginRight:8}}>表示確認</button>
				<button type="button" className="btn btn-info" onClick={this.handleSuperLogin} style={{marginRight:8}}>特権ログイン</button>
				<button type="button" className="btn btn-danger" onClick={this.handleRemove} style={{marginLeft:8}}>アカウントの削除</button>
				<button type="button" className="btn btn-primary pull-right" onClick={this.handleBack}>検索結果に戻る</button>
				<br />
				<br />
				<button type="button" className="btn btn-info" onClick={this.handleAllHistoryCsvDl} style={{marginRight:8}}>すべての学習履歴をCSVでダウンロード</button>
				<button type="button" className="btn btn-info" onClick={this.handleShowPaymentHistory} style={{marginRight:8}}>課金履歴</button>
			</div>
		)
		const hyouka = (showHistory && userRow.uuid) ? (
			<HistoryKamokuHyouka
				user_id={userRow.uuid}
				onClickSubject={this.handleClickSubject}
			/>
		) : null
		const second_row_of_nendo_list = nendoList[1].value
		const historyDetail = (showHistoryDetail) ? (
			<HistoryDetail
				key={_.uniqueId()}
				mondaiType={this.state.historyMondaiType}
				onShowSentaku={this.handleShowSentaku}
				onShowOx={this.handleShowOx}
				onOpenMemo={this.handleOpenMemo}
				fetchUrl="admin/history_detail"
				fetchPayload={{
					user_id: userRow.uuid,
					second_row_of_nendo_list,
					kamoku_id: this.state.historyKamokuID,
					mondai_type: this.state.historyMondaiType+"",
				}}
				onGoBack={this.handleBackFromDetail}
			/>
		) : null
		const myPagePreview = (showMyPage && userRow.uuid) ? (
			<MyPagePreview
				user_id={userRow.uuid}
				calendarYear={this.state.calendarYear}
				calendarMonth={this.state.calendarMonth}
				onChange={this.handleChangeCalendarDate}
			/>
		) : null
		const paymentHistory = (showPaymentHistory) ? (
			<PaymentHistory
				user_id={userRow.uuid}
			/>
		) : null

		return (
			<div>
					<OkCancelModal
						show={this.state.showSaveModal}
						okText="保存"
						bodyText="保存してよろしいですか？"
						onOk={this.handleOkSaveModal}
						onCancel={() => {
							this.setState({showSaveModal: false})
						}}
					/>
					<OkCancelModal
						show={this.state.showRemoveModal}
						okText="アカウントの削除"
						bodyText="削除してよろしいですか？（元に戻せません）"
						onOk={this.handleOkRemoveModal}
						onCancel={() => {
							this.setState({showRemoveModal: false})
						}}
					/>
					<Flex column>
						<Box p={1}>
							<form className="form-horizontal">
								{userIdVd}
								{kanaVd}
								{nameVd}
								{groupTextVd}
								{emailVd}
								{zipcodeVd}
								{addressPrefVd}
								{addressCityVd}
								{group1Vd}
								{group2Vd}
								{licenseVd}
								{limitedVd}
								{actions}
							</form>
						</Box>
					</Flex>
					<div>
						{hyouka}
						{historyDetail}
						{myPagePreview}
						{paymentHistory}
					</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		allGroups: state.mypage.allGroups,
	}),
	dispatch => ({})
)(withRouter(UserDetail))
