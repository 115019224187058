// @flow
import { createAction } from 'redux-actions'
import {Record} from "immutable";
import G from '../sonodalib/SlGlobal'
import type {WmService} from "../shared/ServerInterface";
import {post_wm} from "../sonodalib/sl_utils/Http";
import {SelectorInputState, TextInputState} from "../sonodalib/sl_utils/SlForms";


const GroupListRecord = Record({
  selectedDantaiId: null,
})
export class GroupList extends GroupListRecord {
}

// const EmailTemplateListRecord = Record({
//   templateList: null,
// })
// export class EmailTemplateList extends EmailTemplateListRecord {
// }

const AllGroupsRecord = Record({
  groupList: [],
})
export class AllGroups extends AllGroupsRecord {
}

export type UserSearchResultCheckbox = {
	checked: boolean,
	user_id: string,
}
export type UserSearchResultState = {
	result: Array<any>,
	checkboxes: Array<UserSearchResultCheckbox>,
}
// export class UserSearchResult {
//   result: Array<any>
//
//   constructor() {
//     this.result = []
//   }
//
//   setResult = (result: Array<any>) => {
//     this.result = result
//     return this
//   }
// }
export const defaultUserSearchResult:UserSearchResultState = {
	result: [],
	checkboxes: [],
}


// GroupList Page
export const UPDATE_GROUP_LIST = 'UPDATE_GROUP_LIST'
export const updateGroupListAC = createAction(UPDATE_GROUP_LIST, entity => (entity));

// All email templates
// export const UPDATE_EMAIL_TEMPLATE_LIST = 'UPDATE_EMAIL_TEMPLATE_LIST'
// export const updateEmailTemplateListAC = createAction(UPDATE_EMAIL_TEMPLATE_LIST, entity => (entity));

// AllGroups Page
export const UPDATE_ALL_GROUPS = 'UPDATE_ALL_GROUPS'
export const updateAllGroupsAC = createAction(UPDATE_ALL_GROUPS, entity => (entity));

// UserSearchResult
export const UPDATE_USERSEARCHRESULT = 'UPDATE_USERSEARCHRESULT'
export const updateUserSearchResultAC = createAction(UPDATE_USERSEARCHRESULT, entity => (entity));
export const updateUserSearchResult = (entity:any) => {
	const state = G.store.getState()
	G.store.dispatch(updateUserSearchResultAC({...state.mypage.userSearchResultState,...entity}))
}

export const UPDATE_WM_SERVICES = 'UPDATE_WM_SERVICES'
export const updateWmServicesAC = createAction(UPDATE_WM_SERVICES, entity => (entity));

export const updateWmServices = (oldCurrService:WmService, services:Array<WmService>) => {
	const newCurrService = (oldCurrService) ? services.find((service:WmService) => (service.service === oldCurrService.service)) : undefined
	G.store.dispatch(updateWmServicesAC(services))
	updateWmCurrService(newCurrService)
}

export const UPDATE_WM_CURR_SERVICE = 'UPDATE_WM_CURR_SERVICE'
const updateWmCurrServiceAC = createAction(UPDATE_WM_CURR_SERVICE, entity => (entity));

export const UPDATE_WM_SUBJECT_MENU = 'UPDATE_WM_SUBJECT_MENU'
export const updateWmSubjectMenuAC = createAction(UPDATE_WM_SUBJECT_MENU, entity => (entity));

export const UPDATE_WM_SUBJECT_MENU_DL_SAITEN = 'UPDATE_WM_SUBJECT_MENU_DL_SAITEN'
export const updateWmSubjectMenuDlSaitenAC = createAction(UPDATE_WM_SUBJECT_MENU_DL_SAITEN, entity => (entity));

export const UPDATE_WM_SUBJECT_MENU_DEL_SAITEN = 'UPDATE_WM_SUBJECT_MENU_DEL_SAITEN'
export const updateWmSubjectMenuDeleteSaitenAC = createAction(UPDATE_WM_SUBJECT_MENU_DEL_SAITEN, entity => (entity));

export const UPDATE_WM_MONDAI_TSV = 'UPDATE_WM_MONDAI_TSV'
export const updateWmMondaiTsvAC = createAction(UPDATE_WM_MONDAI_TSV, entity => (entity));

export const updateWmCurrService = (service?:WmService) => {
	G.store.dispatch(updateWmCurrServiceAC(service))
	if (service) {
		// snackbar(service.name+" を選択しました")
		localStorage.setItem("wmCurrService", ""+service.service)

		post_wm("admin/fetch_subjects", {
			service_code: service.service
		})
		.then((ret) => {
			const menuList = ret.subjects.map((subject) => ({
				id: subject.subjectcode,
				label: `${subject.subjectcode} ${subject.name}`,
			}))
			updateWmSubjectMenu(new SelectorInputState({name: "subject", menuList: menuList}))
			const menuListAddAllSubject = [{
				id: "",
				label: "すべての科目"
			}].concat(menuList)
			G.store.dispatch(updateWmSubjectMenuDlSaitenAC(new SelectorInputState({name: "subject", menuList: menuListAddAllSubject})))
			G.store.dispatch(updateWmSubjectMenuDeleteSaitenAC(new SelectorInputState({name: "subject", menuList: menuListAddAllSubject})))
		})

		post_wm("admin/fetch_hantei_th", {
			service_code: service.service
		})
		.then((ret) => {
			const values = ret.values.map((row) => {
				row._th = new TextInputState({value:row.th})
				return row
			})
			G.store.dispatch(updateWmHanteiThAC(values))
		})

		post_wm("admin/fetch_kohyo", {
			service_code: service.service
		})
		.then((ret) => {
			G.store.dispatch(updateWmKohyoShikensAC(ret.kohyo_texts_shikens))
			G.store.dispatch(updateWmKohyoCategoriesAC(ret.kohyo_texts_categories))
			G.store.dispatch(updateWmKohyoSubjectsAC(ret.kohyo_texts_subjects))
			// this.setState({
			// 	kohyo_texts_shikens: 		ret.kohyo_texts_shikens,
			// 	kohyo_texts_categories: ret.kohyo_texts_categories,
			// 	kohyo_texts_subjects:		ret.kohyo_texts_subjects,
			// })
		})

		post_wm("admin/fetch_information", {
			service_code: service.service
		})
		.then((ret) => {
			G.store.dispatch(updateWmInfoAC(ret.info))
		})

		post_wm("admin/fetch_shukei_etc", {
			service_code: service.service
		})
		.then((ret) => {
			G.store.dispatch(updateWmShukeiEtc(ret.shukei_etc))
			const categoriesToIgnoreCheckBoxes = ret.shukei_etc.categories.map((category) => {
				return {
					id:    category.id,
					label: category.label,
					value: false,
				}
			})
			G.store.dispatch(updateWmCategoriesToIgnoreCheckBoxes(categoriesToIgnoreCheckBoxes))
		})


	} else {
		localStorage.removeItem("wmCurrService")
	}
}

export const updateWmSubjectMenu = (subjectMenu: SelectorInputState) => {
	G.store.dispatch(updateWmSubjectMenuAC(subjectMenu))
	if (subjectMenu.menuList.length) {
		post_wm("admin/fetch_mondai_tsv", {
			subject_code: subjectMenu.getValue()
		})
		.then((ret) => {
			// G.store.dispatch(updateWmSubjectMenuAC(newState))
			G.store.dispatch(updateWmMondaiTsvAC(ret.mondai_tsv))
			// this.setState({
			// 	subjectMenu: newState,
			// 	mondai: ret.mondai_tsv,
			// })
		})
	} else {
		G.store.dispatch(updateWmMondaiTsvAC(""))
	}
}

export const UPDATE_WM_SERVICE_TYPES = 'UPDATE_WM_SERVICE_TYPES'
export const updateWmServiceTypesAC = createAction(UPDATE_WM_SERVICE_TYPES, entity => (entity));

export const UPDATE_WM_HANTEITH = 'UPDATE_WM_HANTEITH'
export const updateWmHanteiThAC = createAction(UPDATE_WM_HANTEITH, entity => (entity));

export const UPDATE_WM_KOHYO_SHIKENS = 'UPDATE_WM_KOHYO_SHIKENS'
export const updateWmKohyoShikensAC = createAction(UPDATE_WM_KOHYO_SHIKENS, entity => (entity));
export const UPDATE_WM_KOHYO_CATEGORIES = 'UPDATE_WM_KOHYO_CATEGORIES'
export const updateWmKohyoCategoriesAC = createAction(UPDATE_WM_KOHYO_CATEGORIES, entity => (entity));
export const UPDATE_WM_KOHYO_SUBJECTS = 'UPDATE_WM_KOHYO_SUBJECTS'
export const updateWmKohyoSubjectsAC = createAction(UPDATE_WM_KOHYO_SUBJECTS, entity => (entity));

export const UPDATE_WM_INFO = 'UPDATE_WM_INFO'
export const updateWmInfoAC = createAction(UPDATE_WM_INFO, entity => (entity));

export const UPDATE_WM_SHUKEI_ETC = 'UPDATE_WM_SHUKEI_ETC'
export const updateWmShukeiEtc = createAction(UPDATE_WM_SHUKEI_ETC, entity => (entity));

export const UPDATE_WM_CATEGORIESTOIGNORECHECKBOXES = 'UPDATE_WM_CATEGORIESTOIGNORECHECKBOXES'
export const updateWmCategoriesToIgnoreCheckBoxes = createAction(UPDATE_WM_CATEGORIESTOIGNORECHECKBOXES, entity => (entity));
