// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
// import {snackbar} from "../../sonodalib/sl_utils/Utils";
// import Moment from 'moment'
import {connect} from "react-redux";
import SlTextField from "../../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from '../../../sonodalib/sl_utils/SlForms'
import Button from "@material-ui/core/Button";
import {SelectorInputState} from "../../../sonodalib/sl_utils/SlForms";
import TextFieldSelector from "../../../sonodalib/sl_mui/TextFieldSelector";
import G from '../../../sonodalib/SlGlobal'
import {updateWmSubjectMenuDeleteSaitenAC} from '../../MyPageAction'
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";
import {loading} from "../../../sonodalib/sl_utils/Utils";


type Props = {
	currService: WmService,
}
type State = {
	userUniqueIdToDelete: TextInputState,
	checkBoxes: Array<boolean>,
}
class WmSaitenDelete extends React.Component<Props, State> {
	state = {
		userUniqueIdToDelete: new TextInputState({validations: "integer"}),
		checkBoxes: [false, false, false],
	}

	handleToggleCheck = (index:number) => () => {
		const {checkBoxes} = this.state
		checkBoxes[index] = !checkBoxes[index]
		this.setState({
			checkBoxes,
		})
	}

	handleDelete = () => {
		const {currService, subjectMenuDeleteSaiten} = this.props
		const {userUniqueIdToDelete, checkBoxes} = this.state
		if (!(checkBoxes[0] && checkBoxes[1] && checkBoxes[2])) {
			alert("チェックボックスをすべてONにしてください")
		} else {
			const newState = FormState.validate(this.state)
			this.setState(newState, () => {
				if (FormState.isValid(this.state)) {
					if (window.confirm("削除を実行します")) {
						loading(true)
						post_wm("admin/delete_saiten", {
							service_code: currService.service,
							user_id: 			userUniqueIdToDelete.getValue(),
							subject_code: subjectMenuDeleteSaiten.getValue(),
						})
						.then((ret) => {
							alert("削除しました")
							this.setState({
								checkBoxes: [false, false, false]
							})
						})
						.catch(() => {
							alert("エラーが発生しました")
						})
						.finally(() => {
							loading(false)
						})
					}
				}
			})
		}
	}


	render() {
		const {subjectMenuDeleteSaiten} = this.props
		const {userUniqueIdToDelete, checkBoxes} = this.state
		const subjectSelectorVd = (!subjectMenuDeleteSaiten) ? null : (
			<TextFieldSelector
				label="科目"
				state={subjectMenuDeleteSaiten}
				fullWidth={true}
				onChange={(newState: SelectorInputState) => {
					G.store.dispatch(updateWmSubjectMenuDeleteSaitenAC(newState))
				}}
				InputLabelProps={{
					shrink: true,
					disableAnimation: true,
				}}
			/>
		)
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>削除</h4>
				<div>
					<SlTextField
						name="uniqueId"
						label="利用者ID"
						placeholder="例：123456"
						state={userUniqueIdToDelete}
						onChange={(newState: TextInputState) => {
							this.setState({userUniqueIdToDelete: newState})
						}}
					/>
					空欄ならすべてのユーザの採点が削除されます
				</div>
				<div>
					{subjectSelectorVd}
				</div>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item>
						<Checkbox
							checked={checkBoxes[0]}
							onChange={this.handleToggleCheck(0)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[1]}
							onChange={this.handleToggleCheck(1)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[2]}
							onChange={this.handleToggleCheck(2)}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</Grid>
					<Grid item>
						３つすべてをONにして、削除ボタンを押してください（元に戻せません）
					</Grid>
				</Grid>
				<div style={{marginTop:20}}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleDelete}
					>
						削除
					</Button>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		subjectMenuDeleteSaiten:   state.mypage.subjectMenuDeleteSaiten,
		currService:   state.mypage.currService,
	}),
)(WmSaitenDelete)