// @flow
import React from 'react';
import {post, post_wm} from "../../../sonodalib/sl_utils/Http";
// import {snackbar} from "../../sonodalib/sl_utils/Utils";
import Moment from 'moment'
import {connect} from "react-redux";
import {saveAs} from 'file-saver'
import Button from "@material-ui/core/Button";
import {normalizeRow} from '../../../sonodalib/sl_utils/Utils'
import {SelectorInputState} from "../../../sonodalib/sl_utils/SlForms";
import TextFieldSelector from "../../../sonodalib/sl_mui/TextFieldSelector";
import G from '../../../sonodalib/SlGlobal'
import {updateWmSubjectMenuDlSaitenAC} from '../../MyPageAction'
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";
import {loadingVisibilityAC} from "../../../sonodalib/sl_utils/SlUtilsAction";


type Props = {
	currService: WmService,
}
type State = {
	checkShukeiTaishogai: boolean,
	dantaiList?: SelectorInputState,
}
class WmSaitenDownload extends React.Component<Props, State> {
	state = {
		checkShukeiTaishogai: false,
		dantaiList:						null,
	}

	componentDidMount() {
		this.fetchAll()
	}

	fetchAll() {
		const self = this
		post("admin/fetch_dantai_list", {})
		.then(ret => {
			ret = normalizeRow(ret)
			const dantaiList = [{
				id: "",
				label: "すべての団体"
			}].concat(ret.dantaiList.map(row => ({
				id: row.uuid,
				label: `${row.name} (${row.id})`,
			})))
			this.setState({
				dantaiList: new SelectorInputState({name: "subject", menuList: dantaiList})
			})
		})
	}

	handleDownloadSaiten = () => {
		const {currService, subjectMenuDlSaiten} = this.props
		const {checkShukeiTaishogai, dantaiList} = this.state
		const dispatch = G.store.dispatch
		dispatch(loadingVisibilityAC(true))
		post_wm("admin/dl_saiten", {
			service_code: currService.service,
			subject_code: subjectMenuDlSaiten.getValue(),
			dantai_uuid:  dantaiList.getValue(),
			ignore_flag:  checkShukeiTaishogai,
		})
		.then((ret) => {
			const csvs = ret.dl_data.map((row) => (
				`"${row.id}",\
"${row.unique_id}",\
"${row.subject}",\
"${row.score}",\
"${Moment(row.reg_time).format("YYYY/MM/DD HH:mm:ss")}"`
			))
			csvs.unshift('"ID","利用者ID","科目","得点","日付"')
			const csv = csvs.join("\n")
			const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
			const blob = new Blob([bom, csv], {"type": "text/csv"});
			saveAs(blob, `saiten_${currService.service}_${Moment().format("YYYY-MM-DD HH:mm:ss")}.csv`)
			// snackbar("採点データをダウンロードしました")
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	handleDownloadMark = () => {
		const {currService, subjectMenuDlSaiten} = this.props
		const {checkShukeiTaishogai, dantaiList} = this.state
		const dispatch = G.store.dispatch
		dispatch(loadingVisibilityAC(true))
		post_wm("admin/dl_mark", {
			service_code: currService.service,
			subject_code: subjectMenuDlSaiten.getValue(),
			dantai_uuid:  dantaiList.getValue(),
			ignore_flag: checkShukeiTaishogai,
		})
		.then((ret) => {
			const csvs = ret.dl_data.map((row) => (
				`"${row.id}",\
"${row.unique_id}",\
"${row.subject}",\
"${row.orderno}",\
"${row.answer}",\
"${row.score}"`
			))
			csvs.unshift('"ID","利用者ID","科目名","問題番号","マーク値","得点"')
			const csv = csvs.join("\n")
			const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
			const blob = new Blob([bom, csv], {"type": "text/csv"});
			saveAs(blob, `mark_${currService.service}_${Moment().format("YYYY-MM-DD HH:mm:ss")}.csv`)
			// snackbar("マークデータをダウンロードしました")
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	handleToggleCheckShukeiTaishogai = () => {
		this.setState({
			checkShukeiTaishogai: !this.state.checkShukeiTaishogai
		})
	}

	render() {
		const {subjectMenuDlSaiten} = this.props
		const {checkShukeiTaishogai, dantaiList} = this.state
		const subjectSelectorVd = (!subjectMenuDlSaiten) ? null : (
			<TextFieldSelector
				label="科目"
				state={subjectMenuDlSaiten}
				fullWidth={true}
				onChange={(newState: SelectorInputState) => {
					G.store.dispatch(updateWmSubjectMenuDlSaitenAC(newState))
				}}
				InputLabelProps={{
					shrink: true,
					disableAnimation: true,
				}}
			/>
		)
		const dantaiSelectorVd = (!dantaiList) ? null : (
			<TextFieldSelector
				label="団体"
				state={dantaiList}
				fullWidth={true}
				onChange={(newState: SelectorInputState) => {
					this.setState({
						dantaiList: newState
					})
				}}
				InputLabelProps={{
					shrink: true,
					disableAnimation: true,
				}}
			/>
		)
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>ダウンロード</h4>
				<div>
					{subjectSelectorVd}
				</div>
				<div>
					{dantaiSelectorVd}
				</div>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item>
						<Checkbox
							checked={checkShukeiTaishogai}
							onChange={this.handleToggleCheckShukeiTaishogai}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</Grid>
					<Grid item>
						集計対象外ユーザの採点データも含める
					</Grid>
				</Grid>
				<div style={{marginTop:30}}>
					<Button
						variant="contained"
						onClick={this.handleDownloadSaiten}
						color="secondary"
					>
						採点データをダウンロード
					</Button>
					<Button
						variant="contained"
						onClick={this.handleDownloadMark}
						color="secondary"
						style={{marginLeft:20}}
					>
						マークデータをダウンロード
					</Button>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		subjectMenuDlSaiten:   state.mypage.subjectMenuDlSaiten,
		currService:   state.mypage.currService,
	}),
)(WmSaitenDownload)