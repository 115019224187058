// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import {post} from '../../sonodalib/sl_utils/Http'
// import _ from 'lodash'
import {updateSnackbarAC} from '../../sonodalib/sl_utils/SlUtilsAction'
import G from "../../sonodalib/SlGlobal";
// import Moment from 'moment'
import type {AdminAttr} from '../../shared/ServerInterface'

const keys = ['emails_notify_recieve_contact', 'disabled_otameshi_touroku', 'disabled_order']
const keys_title = {
	"emails_notify_recieve_contact": "管理者通知先メールアドレス（セミコロン区切り）",
	// "moshi_for_all": "全利用者向けWeb模試リンク先サービス（例：201501　空欄＝非表示）",
	"disabled_otameshi_touroku": "お試し登録の停止（停止する場合は '1' を入力　空欄または '0' なら登録可能）",
	"disabled_order": "申し込みの停止（停止する場合は '1' を入力　空欄または '0' なら登録可能）",
}

type Row = {
	key: string,
	valueState: TextInputState,
}
type States = {
	// admin_attr: Array<AdminAttr>,
	rows: Array<Row>,
}
export default class EtcSetting extends React.Component<{}, States> {
	state = {
		// admin_attr: [],
		rows: [],
	}

	componentWillMount() {
		post("admin/fetch_etc_setting")
		.then((ret: any) => {
			const rows = keys.map((key) => {
				const r = ret.admin_attr.find(a => (a.key === key))
				return {
					key,
					valueState: new TextInputState({value:(r) ? r.value : ""}),
				}
			})
			// const rows = ret.admin_attr.filter((row:AdminAttr) => keys.indexOf(row.key) !== -1).map((row) => (
			// 	{
			// 		key: row.key,
			// 		valueState: new TextInputState({value:row.value}),
			// 	}
			// ))
			this.setState({
				// admin_attr: ret.admin_attr,
				rows,
			})
		})
	}

	handleChangeValueState = (row:Row) => (newState:TextInputState) => {
		row.valueState = newState
		this.setState({
			rows: this.state.rows,
		})
	}

	handleUpdate = (row:Row) => () => {
		if (window.confirm("更新します")) {
			post("admin/update_etc_setting", {
				key: row.key,
				value: row.valueState.getValue(),
			})
			.then((ret) => {
				G.store.dispatch(updateSnackbarAC("更新しました"))
			})
		}
	}

	render() {
		const {rows} = this.state
		const rowsVd = rows.map((row:AdminAttr) => (
			<React.Fragment key={row.key}>
				<Grid item xs={5}>
					{keys_title[row.key]}
				</Grid>
				<Grid item xs={6}>
					<TextInput
						type="text"
						state={row.valueState}
						onChange={this.handleChangeValueState(row)}
					/>
				</Grid>
				<Grid item xs={1}>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						onClick={this.handleUpdate(row)}
					>
						更新
					</Button>
				</Grid>
			</React.Fragment>
		))
		return (
			<div style={{margin: 8}}>
				<h3>その他の設定</h3>
				<Grid container direction="row">
					{rowsVd}
				</Grid>
			</div>
		)
	}
}
