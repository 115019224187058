// @flow
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Moment from 'moment'
import yellow from '@material-ui/core/colors/yellow'
import {post} from '../../sonodalib/sl_utils/Http'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import orange from "@material-ui/core/colors/orange";
import lime from "@material-ui/core/colors/lime";


type Props = {
	user_id: string,
	year: number,
	month: number,
	onChange: (number, number) => void,
}
type States = {
	list?: Array<any>,
	openMonthPicker: boolean,
}

export default class VerticalCalendar extends React.Component<Props, States> {
	state = {
		list: undefined,
		openMonthPicker: false,
	}

	componentWillMount() {
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps(props: any) {
		// const m = ("0" + props.month).slice(-2)
		post("admin/fetch_mypage_preview_calendar", {
			user_id: props.user_id,
			// year_month: props.year + "-" + m,
			year: props.year,
			month: props.month,
		})
		.then((ret) => {
			this.setState({
				list: ret.list,
			})
		})
	}

	handleOpenChangeMonth = () => {
		this.setState({
			openMonthPicker: true,
		})
	}

	handleChangeMonth = (year:number, month:number) => {
		this.setState({
			openMonthPicker: false,
		})
		this.props.onChange(year, month)
	}

	handleNextMonth = () => {
		let {year, month} = this.props
		const d = new Date()
		let thisYear = d.getFullYear()
		if (d.getMonth() < 4) thisYear--
		if (thisYear+1 === year && month === 3) return
		month++
		if (month===13) {
			month = 1
			year++
		}
		this.props.onChange(year, month)
	}

	handlePrevMonth = () => {
		let {year, month} = this.props
		const d = new Date()
		let thisYear = d.getFullYear()
		if (d.getMonth() < 4) thisYear--
		if (thisYear === year && month === 4) return
		month--
		if (!month) {
			month = 12
			year--
		}
		this.props.onChange(year, month)
	}

	render() {
		const styles = {
			monthPickerC: {width: 160, marginRight: 8, marginTop: 8, backgroundColor: lime[400], border: '3px solid black'},
			monthPicker1: {width: 160, marginRight: 8, marginTop: 8, backgroundColor: lime[400]},
			monthPicker2: {width: 160, marginRight: 8, marginTop: 8, backgroundColor: yellow[600]},
		}
		const {list} = this.state
		if (!list) return null
		const now = new Date()
		let year = now.getFullYear() + 1
		let year100 = year * 100
		let month = 4
		const pullDownVd = []
		do {
			month--
			if (month === 0) {
				month = 12
				year--
				year100--
			}
			pullDownVd.push(<option key={month} value={year100 + month}>{year}年{month}月</option>)
		} while (month !== 4)

		const today = new Date()
		const currYear = today.getFullYear()
		const thisMonth = today.getMonth() + 1
		const thisDate = today.getDate()
		const dayofweekLabels = ['日', '月', '火', '水', '木', '金', '土']
		const date = Moment(`${this.props.year}-${this.props.month}`, "YYYY-M")
		let dayofweek = date.day()
		const last_day = date.daysInMonth()
		let day = 1
		const vd = []
		while (day <= last_day) {
			const value = list[day] || ""
			const style: any = {
				height: 26,

			}
			const dayStyle: any = {
				paddingLeft: 10,
				width: 100,
			}
			if (currYear === this.props.year && thisMonth === this.props.month && thisDate === day) {
				style.borderBottom = '2px solid #f50057'	// grey[300]
				dayStyle.fontWeight = 800
				dayStyle.color = '#f50057'
			} else if (value) {
				if (value === "学習日") {
					style.backgroundColor = orange[200]
				} else {
					style.backgroundColor = lime[500]
				}
				// style.backgroundColor = orange[(day & 1) * 100 + 200]
			}
			vd.push(
				<TableRow hover key={day} style={style}>
					<TableCell padding="none"
										 style={dayStyle}>{day}日（{dayofweekLabels[dayofweek]}）</TableCell>
					<TableCell padding="none">{value}</TableCell>
				</TableRow>
			)
			day++
			dayofweek++
			if (dayofweek > 6) dayofweek = 0
		}

		const d = new Date()
		let thisYear = d.getFullYear()
		if (d.getMonth() < 4) thisYear--
		let _year = thisYear

		const monthPickerVd = []
		month = 4
		let count = 0
		while (count < 12) {
			const yearVd = (month === 4 || month === 1) ? _year + "年 " : ""
			const _style = (_year === this.props.year && month === this.props.month) ? styles.monthPickerC : (thisYear === _year) ? styles.monthPicker1 : styles.monthPicker2
			monthPickerVd.push(
				<Button
					style={_style}
					key={"monthpicker" + month}
					onClick={this.handleChangeMonth.bind(this, _year, month)}
				>
					{yearVd}{month}月
				</Button>
			)
			month++
			if (month > 12) {
				_year++
				month = 1
			}
			count++
		}

		return (
			<div>
				<div>
					<Grid container direction="row" justify="space-between" style={{marginBottom: 4}}>
						<Grid item>
							<Button
								style={{fontSize: 16}}
								onClick={this.handlePrevMonth}
							>
								＜
							</Button>
						</Grid>
						<Grid item>
							<Button
								fullWidth
								style={{fontSize: 16}}
								onClick={this.handleOpenChangeMonth}
							>
								{this.props.year}年{this.props.month}月
							</Button>
						</Grid>
						<Grid item>
							<Button
								style={{fontSize: 16}}
								onClick={this.handleNextMonth}
							>
								＞
							</Button>
						</Grid>
					</Grid>
					<Table>
						<TableBody style={{cursor: 'pointer'}}>
							{vd}
						</TableBody>
					</Table>
				</div>
				<Dialog open={this.state.openMonthPicker}>
					<DialogContent>
						<div style={{textAlign:'center'}}>
							{monthPickerVd}
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({
									openMonthPicker: false,
								})
							}}
						>
							キャンセル
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
