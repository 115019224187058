// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
// import IconSelector from '../../sonodalib/sl_mui/IconSelector'
import {post, post_wm} from '../../sonodalib/sl_utils/Http'
import type {AdminMoshiDantaiOnOff} from '../../shared/ServerInterface'
import G from "../../sonodalib/SlGlobal";
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";

type Props = {
	history: any,
}
type States = {
	// edit: string,
	// editGroupId: TextInputState,
	link: Array<AdminMoshiDantaiOnOff>,
	editServiceCode: TextInputState,
}
class LinkDantaiOnOff extends React.Component<Props, States> {
	state = {
		// edit: "",			// 編集中のUUID（"NEW"=新規）
		// editGroupId: new TextInputState({validations:"required"}),
		link: [],
		editServiceCode: new TextInputState({}),
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post("admin/fetch_moshi_dantai_on_off", {})
		.then((ret: {link:Array<AdminMoshiDantaiOnOff>}) => {
			this.setState({
				link: ret.link,
				editServiceCode: this.state.editServiceCode.setValue(ret.webmoshi_service)
			})
		})
	}

	// handleEdit = (row:AdminMoshiDantaiOnOff) => () => {
	// 	this.setState({
	// 		edit: row.uuid,
	// 		editGroupId: this.state.editGroupId.setValue(row.id),
	// 	})
	// }

	handleSwitchHide = (row:AdminMoshiDantaiOnOff) => () => {
		post("admin/switch_hide_moshi_link_on_off", {
			group_id: row.group_id,
		})
		.then((ret:{link:Array<AdminMoshiDantaiOnOff>}) => {
			this.setState({
				link: ret.link,
				// edit: "",
			})
		})
	}

	// handleDelete = (row:AdminMoshiDantaiOnOff) => () => {
	// 	if (window.confirm("削除してもよろしいですか？")) {
	// 		post("admin/delete_moshi_link_on_off", {
	// 			uuid: row.uuid,
	// 		})
	// 		.then((ret: {link:Array<AdminMoshiDantaiOnOff>}) => {
	// 			this.setState({
	// 				link: ret.link,
	// 			})
	// 		})
	// 	}
	// }

	// handleAdd = () => {
	// 	this.setState({
	// 		edit: "NEW",
	// 		editGroupId: this.state.editGroupId.setValue(""),
	// 	})
	// }

	// handleCancel = () => {
	// 	this.setState({
	// 		edit: "",
	// 	})
	// }
	//
	// handleUpdate = () => {
	// 	this.setState(FormState.validate(this.state), ()=>{
	// 		if (FormState.isValid(this.state)) {
	// 			let error = false
	// 			post("admin/update_moshi_link_on_off", {
	// 				uuid: this.state.edit,
	// 				group_id: this.state.editGroupId.getValue(),
	// 			})
	// 			.then((ret: any) => {		//{link:Array<AdminMoshiDantaiOnOff>} | {status:string}
	// 				if (ret.status === "group_id not found") {
	// 					alert("指定の団体IDが見つかりません")
	// 					error = true
	// 				} else {
	// 					this.setState({
	// 						link: ret.link,
	// 					})
	// 				}
	// 			})
	// 			.catch((ret:any) => {
	// 				alert("すでに指定の団体ID、サービスコードは入力済みです")
	// 				error = true
	// 			})
	// 			.finally(() => {
	// 				if (!error) this.setState({edit:""})
	// 			})
	// 		}
	// 	})
	// }

	// handleAddOnOff = () => {
	// 	this.setState({
	// 		edit: "NEW",
	// 		editGroupId: this.state.editGroupId.setValue(""),
	// 	})
	// }

	handleUpdateServiceCode = () => {
		const {editServiceCode} = this.state
		const service_code = editServiceCode.getValue()
		post_wm("admin/check_service_code", {
			stype: "webmoshi",
			service_code
		})
		.then(ret => {
			if (ret.err) {
				alert(ret.err)
			} else {
				if (ret.warn) {
					alert(ret.warn)
				}
				post("admin/update_etc_setting", {
					key: "webmoshi_service",
					value: editServiceCode.getValue(),
				})
				.then((ret) => {
					G.store.dispatch(updateSnackbarAC("更新しました"))
				})
			}
		})
	}

	render() {
		const {editServiceCode} = this.state
		// if (edit.length > 0) {
		// 	return (
		// 		<div style={{padding: 20}}>
		// 			<h3>編集：模試システムへのリンク設定</h3>
		// 			<LinkDantaiOnOffEdit
		// 				editGroupId={editGroupId}
		// 				onChangeGroupId={(newState: TextInputState) => {
		// 					this.setState({editGroupId: newState})
		// 				}}
		// 				onUpdate={this.handleUpdate}
		// 				onCancel={this.handleCancel}
		// 			/>
		// 		</div>
		// 	)
		// }

		const hideText = [<span style={{color:'red',fontWeight:700}}>表示</span>, "隠す"]
		const rows = []
		this.state.link.forEach((row:AdminMoshiDantaiOnOff) => {
			rows.push(
				<Grid key={row.uuid+"id"} item xs={2}>
					{row.id}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"name"} item xs={5}>
					{row.name}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"valid"} item xs={2}>
					{hideText[row.hide]}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"ctrl"} item xs={2} style={{padding:0,marginTop:2}}>
					<Button variant="contained" color="primary" size="small" onClick={this.handleSwitchHide(row)}>
						表示切替
					</Button>
					{/*<IconSelector*/}
					{/*	menuList={[*/}
					{/*		{id: 0, label: "編集", onClick: this.handleEdit(row)},*/}
					{/*		{id: 1, label: (row.hide) ? "隠さない" : "隠す", onClick: this.handleSwitchHide(row)},*/}
					{/*		{id: 2, label: "削除", onClick: this.handleDelete(row)},*/}
					{/*	]}*/}
					{/*/>*/}
				</Grid>
			)
		})

		return (
			<div style={{padding: 20}}>
				<h3>Web模試設定</h3>
				<div style={{marginTop:30}}>
					<div style={{fontWeight:700}}>
						マイページに設置するリンク先のサービスコード
					</div>
					<div>
						<SlTextField
							state={editServiceCode}
							style={{width:150, marginLeft:10, marginRight:10}}
							onChange={(newState: TextInputState) => {
								this.setState({editServiceCode: newState})
							}}
							placeholder="例：201304"
						/>
						<Button
							variant="contained"
							onClick={this.handleUpdateServiceCode}
							color="secondary"
							size="small"
							style={{marginTop:16}}
						>
							更新
						</Button>
					</div>
					<div>
						空欄の場合は、Web模試へのリンクが表示されません
					</div>
				</div>
				<div style={{marginTop:50}}>
					<div style={{fontWeight:700}}>
						団体ON/OFF設定（※デフォルトはOFF）
					</div>
				</div>
				<Grid container direction="row" alignItems="center" style={{marginTop:20}}>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						団体ID
					</Grid>
					<Grid item xs={5} style={{fontSize:11,fontWeight:600}}>
						団体名
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						リンク表示
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						操作
					</Grid>
					{rows}
				</Grid>
				{/*<div style={{marginTop:30}}>*/}
				{/*	<Button*/}
				{/*		variant="contained"*/}
				{/*		onClick={this.handleAddOnOff}*/}
				{/*		color="primary"*/}
				{/*	>*/}
				{/*		追加*/}
				{/*	</Button>*/}
				{/*</div>*/}
			</div>
		)
	}
}
export default withRouter(LinkDantaiOnOff)
