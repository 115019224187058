// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import {post} from '../../sonodalib/sl_utils/Http'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import {AdminContentSearchJireiMondai} from '../../shared/ServerInterface'
import {JireiMondai} from '../../shared/DbModel'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import type {Mondai} from "../../shared/DbModel"
import _ from "lodash"

type States = {
	MondaiID: TextInputState,
	KamokuID: TextInputState,
	ShikenID: TextInputState,
	JireiID: TextInputState,
	Mondaibun: TextInputState,
	Seito: TextInputState,
	Kaisetsu: TextInputState,
	result: Array<JireiMondai>,
	editingMondaiId: string,
	editKamokuID: TextInputState,
	editShikenID: TextInputState,
	editJireiID: TextInputState,
	editMondaibun: TextInputState,
	editSeito: TextInputState,
	editKaisetsu: TextInputState,
}

export default class EditJireiMondai extends React.Component<{}, States> {
	state = {
		MondaiID: new TextInputState({validations: "integer"}),
		KamokuID: new TextInputState({validations: "integer"}),
		ShikenID: new TextInputState({validations: "integer"}),
		JireiID: new TextInputState({validations: "integer"}),
		Mondaibun: new TextInputState(),
		Seito: new TextInputState({validations: "integer"}),
		Kaisetsu: new TextInputState(),
		result: [],
		editingMondaiId: "",
		editKamokuID: new TextInputState({validations: "required,integer"}),
		editShikenID: new TextInputState({validations: "required,integer"}),
		editJireiID: new TextInputState({validations: "required,integer"}),
		editMondaibun: new TextInputState({validations: "required"}),
		editSeito: new TextInputState({validations: "required,integer"}),
		editKaisetsu: new TextInputState({validations: "required"}),
	}
	searchMembers = ["MondaiID", "KamokuID", "ShikenID", "JireiID", "Mondaibun", "Seito", "Kaisetsu"]
	editMembers = ["editKamokuID", "editShikenID", "editJireiID", "editMondaibun", "editSeito", "editKaisetsu"]

	handleSearch = () => {
		const searchMembers = this.searchMembers
		this.setState(FormState.validateMembers(this.state, searchMembers), () => {
			if (FormState.isValidMembers(this.state, searchMembers)) {
				post("admin/content/search-jireimondai", FormState.valueMembers(this.state, searchMembers))
				.then((ret: AdminContentSearchJireiMondai) => {
					let show = true
					if (ret.result.length > 1000) {
						show = (window.confirm(ret.result.length + "件ありますがよろしいですか？"))
					}
					if (show) {
						this.setState({
							result: ret.result,
							editingMondaiId: "",
						})
					}
				})
			}
		})
	}

	handleCancelEdit = () => {
		this.setState({
			editingMondaiId: "",
		})
	}

	handleSaveRow = (row: JireiMondai) => {
		this.setState(FormState.validateMembers(this.state, this.editMembers), () => {
			if (FormState.isValidMembers(this.state, this.editMembers)) {
				if (window.confirm("この内容で保存してもよろしいですか？")) {
					const payload = {
						MondaiID: row.MondaiID,
						KamokuID: this.state.editKamokuID.getValue(),
						ShikenID: this.state.editShikenID.getValue(),
						JireiID: this.state.editJireiID.getValue(),
						Mondaibun: this.state.editMondaibun.getValue(),
						Seito: this.state.editSeito.getValue(),
						Kaisetsu: this.state.editKaisetsu.getValue(),
					}
					post("admin/content/update-jireimondai", payload)
					.then(() => {
						row.KamokuID = payload.KamokuID
						row.ShikenID = payload.ShikenID
						row.JireiID = payload.JireiID
						row.Mondaibun = payload.Mondaibun
						row.Seito = payload.Seito
						row.Kaisetsu = payload.Kaisetsu
						this.setState({
							editingMondaiId: "",
							result: this.state.result
						})
					})
					.catch(() => {
						alert("ネットワークエラーが発生しました")
					})
				}
			}
		})
	}

	handleDeleteRow = (row: Mondai) => {
		if (window.confirm(`問題ID ${row.MondaiID} を削除します。選択肢も自動的に削除されます。`)) {
			const payload = {
				MondaiID: row.MondaiID,
			}
			post("admin/content/delete-jireimondai", payload)
			.then((ret: any) => {
				if (ret.status && ret.status === 'cant_delete_last_mondai') {
					alert(`事例文ID ${row.JireiID} の最後の問題なので削除できません。事例文を削除するとこの問題も自動的に削除されます`)
				} else if (ret.status && ret.status === 'ok') {
					const result = _.reject(this.state.result, {MondaiID: row.MondaiID})
					this.setState({
						editingMondaiId: "",
						result: result
					})
					alert("削除しました")
				} else {
					alert("不明なエラー")
				}
			})
			.catch((ret: any) => {
				alert("ネットワークエラーが発生しました")
			})
		}
	}

	handleEditRow = (row: JireiMondai) => {
		this.setState({
			editingMondaiId: row.MondaiID,
			editKamokuID: this.state.editKamokuID.setValue(row.KamokuID),
			editShikenID: this.state.editShikenID.setValue(row.ShikenID),
			editJireiID: this.state.editJireiID.setValue(row.JireiID),
			editMondaibun: this.state.editMondaibun.setValue(row.Mondaibun),
			editSeito: this.state.editSeito.setValue(row.Seito),
			editKaisetsu: this.state.editKaisetsu.setValue(row.Kaisetsu),
		})
	}

	render() {
		const resultVd = this.state.result.map((row: JireiMondai) => {
			if (this.state.editingMondaiId === row.MondaiID) {
				return (
					<tr key={"result_a_" + row.MondaiID}>
						<td>{row.MondaiID}</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editKamokuID}
								onChange={(newState: TextInputState) => {
									this.setState({editKamokuID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editShikenID}
								onChange={(newState: TextInputState) => {
									this.setState({editShikenID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editJireiID}
								onChange={(newState: TextInputState) => {
									this.setState({editJireiID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editMondaibun}
								multiline
								rows={20}
								onChange={(newState: TextInputState) => {
									this.setState({editMondaibun: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editSeito}
								onChange={(newState: TextInputState) => {
									this.setState({editSeito: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editKaisetsu}
								multiline
								rows={20}
								onChange={(newState: TextInputState) => {
									this.setState({editKaisetsu: newState})
								}}
							/>
						</td>
						<td>
							<BsButton
								onClick={this.handleSaveRow.bind(this, row)}
								color="danger"
							>
								保存
							</BsButton>
							<BsButton
								onClick={this.handleCancelEdit}
							>
								中止
							</BsButton>
						</td>
					</tr>
				)
			} else {
				return (
					<tr key={"result_b_" + row.MondaiID}>
						<td>{row.MondaiID}</td>
						<td>{row.KamokuID}</td>
						<td>{row.ShikenID}</td>
						<td>{row.JireiID}</td>
						<td>
							<div dangerouslySetInnerHTML={{__html: row.Mondaibun.replace(/\n/g, "<br />")}}></div>
						</td>
						<td>{row.Seito}</td>
						<td>
							<div dangerouslySetInnerHTML={{__html: row.Kaisetsu.replace(/\n/g, "<br />")}}></div>
						</td>
						<td>
							<BsButton
								onClick={this.handleEditRow.bind(this, row)}
								color="purple"
							>
								修正
							</BsButton>
							<BsButton
								style={{marginLeft:6}}
								onClick={this.handleDeleteRow.bind(this, row)}
								color="danger"
							>
								削除
							</BsButton>
						</td>
					</tr>
				)
			}
		})

		return (
			<div>
				<section className="content-header">
					<h1>
						事例問題データの修正
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				<section className="content">
					<Grid container direction="row">
						<Grid item xs={6}>
							<SlTextField
								label="問題ID"
								state={this.state.MondaiID}
								onChange={(newState: TextInputState) => {
									this.setState({MondaiID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="科目ID"
								state={this.state.KamokuID}
								onChange={(newState: TextInputState) => {
									this.setState({KamokuID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="試験ID"
								state={this.state.ShikenID}
								onChange={(newState: TextInputState) => {
									this.setState({ShikenID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="事例ID"
								state={this.state.JireiID}
								onChange={(newState: TextInputState) => {
									this.setState({JireiID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="問題文"
								state={this.state.Mondaibun}
								onChange={(newState: TextInputState) => {
									this.setState({Mondaibun: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="正答"
								state={this.state.Seito}
								onChange={(newState: TextInputState) => {
									this.setState({Seito: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="解説"
								state={this.state.Kaisetsu}
								onChange={(newState: TextInputState) => {
									this.setState({Kaisetsu: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<BsButton
								onClick={this.handleSearch}
								color="primary"
								// variant="contained"
							>
								事例問題を検索
							</BsButton>
						</Grid>
					</Grid>
					<div>
						<table className="table table-bordered">
							<tbody>
							<tr>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>問題ID</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>科目ID</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>試験ID</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>事例ID</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>問題文</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>正答</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>解説</th>
							</tr>
							{resultVd}
							</tbody>
						</table>
					</div>
				</section>
			</div>
		)
	}
}

