// @flow
import * as React from 'react'
import TextField from '@material-ui/core/TextField'
// import _ from 'lodash'
// import MenuItem from '@material-ui/core/Menu/MenuItem'
// import PropTypes from 'prop-types'
// import Selector from './Selector'
import type {MenuRow} from './Selector'
import {SelectorInputState} from "../sl_utils/SlForms";

/*
import SlTextFieldSelector from '../../sonodalib/sl_mui/SlTextFieldSelector'

		const grades = [
									{id: 0, label:"その他"},
									{id: 1, label:"１年"},
									{id: 2, label:"２年"},
									{id: 3, label:"３年"},
									{id: 4, label:"４年"},
									{id: 5, label:"卒業"},
								]

					<SlTextFieldSelector
						label="タイトル背景色"
						menuList={prefs}
						value={this.state.selectedPref}
						fullWidth={true}
						onChange={(menuRow) => {
							this.setState({
								selectedPref: menuRow.id,
							})
						}}
						InputLabelProps={{
							shrink: true,
							disableAnimation: true,
						}}
					/>

 */

export type Props = {
	// menuList: Array<MenuRow>,
	// value?: any,
	state: SelectorInputState,
	InputLabelProps?: any,
	onChange?: (MenuRow) => void,
	label?: string,
	// trigger: (selected?: MenuRow, (event: SyntheticEvent<HTMLButtonElement>)=>void) => ?React.Node
}
type States = {
//	open: boolean,
}
export default class SlTextFieldSelector extends React.Component<Props, States> {
	onChange = (event: SyntheticEvent<HTMLButtonElement>) => {
		const {onChange, state} = this.props
		const {menuList} = state
		const index = parseInt(event.currentTarget.value, 10)
		const menuRow = menuList[index]
		if (menuRow) {
			if (onChange) {
				onChange(state.setValue(menuRow.id))
			}
			if (menuRow.onClick) {
				menuRow.onClick()
			}
		}
	}

	render() {
		const {
			// value,
			state,
			// menuList,
			label,
			onChange,		// eslint-disable-line no-unused-vars
			InputLabelProps,
			...other
		} = this.props
		const index = state.get("index")
		const menuList = state.get("menuList")
		const inputLabelProps = (InputLabelProps) || {
			shrink: true,
			disableAnimation: true,
		}
		return (
			<TextField
				select
				label={label}
				value={index}
				error={state.isError()}
				helperText={state.getError()}
				onChange={this.onChange}
				margin="normal"
				SelectProps={{
					native: true,
				}}
				// aria-owns={this.state.open ? 'selector-menu' : null}
				// aria-haspopup="true"
				// onClick={handleOpen}
				// InputProps={{
				// 	readOnly: true,
				// }}
				InputLabelProps={inputLabelProps}
				{...other}
			>
				{menuList.map((menu: MenuRow, index: number) => (
					<option key={index} value={index}>
						{menu.label}
					</option>
				))}
			</TextField>
		)
	}
}
