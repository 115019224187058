// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import CurrentService from "../CurrentService";
import Button from "@material-ui/core/Button";
import {Instruction} from './common'
// import {SelectorInputState} from "../../sonodalib/sl_utils/SlForms";
// import TextFieldSelector from "../../sonodalib/sl_mui/TextFieldSelector";
// import G from '../../sonodalib/SlGlobal'
import {updateWmCurrService} from '../../MyPageAction'
// import Grid from "@material-ui/core/Grid";


type Props = {}
type State = {
	mondai: string,
}
class WmMondaiAdd extends React.Component<Props, State> {
	state = {
		mondai: "",
	}

	handleAdd = () => {
		const {currService} = this.props
		const {mondai} = this.state
		const csv = mondai.trim()
		if (!csv.length) {
			alert("１行目は見出し行で、削除できません。編集前に戻します")
		} else {
			post_wm("admin/mondai_add", {
				service_code: currService.service,
				csv_lines:    csv,
			})
			.then((ret) => {
				if (ret.status === "err") {
					if (ret.errors.length) {
						let errorText = `${ret.errors.length} 件のエラーがあります\n`
						ret.errors.forEach((error:string) => {
							errorText = errorText + error + "\n"
						})
						alert(errorText)
					}
				} else {
					alert(`${ret.added_count}件 追加しました`)
					updateWmCurrService(currService)
				}
			})
		}
	}

	render() {
		const {currService} = this.props
		const {mondai} = this.state
		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<h3>問題の一括追加</h3>
						<textarea
							id="mondai"
							className="form-control"
							onChange={(el)=>{
								this.setState({
									mondai: el.target.value,
								})
							}}
							value={mondai}
							rows={36}
						/>
						<div style={{marginTop:30}}>
							<Button
								variant="contained"
								onClick={this.handleAdd}
								color="primary"
							>
								追加
							</Button>
						</div>
					</React.Fragment>
				)}
				<Instruction/>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmMondaiAdd)