// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import {FormState, TextInputState} from '../../../sonodalib/sl_utils/SlForms'
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";
import {loading} from "../../../sonodalib/sl_utils/Utils";
import SlTextField from "../../../sonodalib/sl_mui/SlTextField";
import _ from 'lodash'


type Props = {
	currService: WmService,
}
type State = {
	maxPops: TextInputState,
	minRate: TextInputState,
	maxRate: TextInputState,
	checkBoxes: Array<boolean>,
}
class WmSaitenRandom extends React.Component<Props, State> {
	state = {
		maxPops: new TextInputState({validations: "integer,required"}),
		minRate: new TextInputState({validations: "integer,required"}),
		maxRate: new TextInputState({validations: "integer,required"}),
		checkBoxes: [false, false, false],
	}

	handleToggleCheck = (index:number) => () => {
		const {checkBoxes} = this.state
		checkBoxes[index] = !checkBoxes[index]
		this.setState({
			checkBoxes,
		})
	}

	handleSaiten = () => {
		const {currService} = this.props
		const {checkBoxes} = this.state
		if (!(checkBoxes[0] && checkBoxes[1] && checkBoxes[2])) {
			alert("チェックボックスをすべてONにしてください")
		} else {
			const newState = FormState.validate(this.state)
			this.setState(newState, () => {
				if (FormState.isValid(this.state)) {
					const {maxPops, minRate, maxRate} = this.state
					const _maxPops = parseInt(maxPops.getValue(), 10)
					const _minRate = parseInt(minRate.getValue(), 10)
					const _maxRate = parseInt(maxRate.getValue(), 10)
					const _state = {}
					if (_maxPops < 1 || 9999 < _maxPops) {
						_state.maxPops = maxPops.setError("1〜9999 の範囲で入力して下さい")
					}
					if (_minRate < 0 || 100 < _minRate) {
						_state.minRate = minRate.setError("0〜100 の範囲で入力して下さい")
					}
					if (_maxRate < 0 || 100 < _maxRate) {
						_state.maxRate = maxRate.setError("0〜100 の範囲で入力して下さい")
					}
					if (_minRate > _maxRate) {
						_state.minRate = minRate.setError("最大正答率以下の値を入力して下さい")
					}
					if (!_.isEmpty(_state)) {
						this.setState(_state)
					} else {
						if (window.confirm("一括ランダム採点を予約します")) {
							loading(true)
							post_wm("admin/random_saiten", {
								service_code: currService.service,
								max_pops: _maxPops,
								min_rate: _minRate,
								max_rate: _maxRate,
							})
							.then((ret) => {
								if (ret.error) {
									if (ret.error === "saiten exists") {
										alert("すでに採点データがあります。このサービスの採点データをすべて削除してから実行してください")
									} else if (ret.error === "no_saiten_marks") {
										alert("回答するユーザまたは、問題がありません")
									}
								} else {
									alert("ランダム採点処理を予約しました ユーザ数："+ret.user_count+" 採点マーク数:" + ret.mark_count)
									this.setState({
										checkBoxes: [false, false, false]
									})
								}
							})
							.catch(() => {
								alert("エラーが発生しました")
							})
							.finally(() => {
								loading(false)
							})
						}
					}
				}
			})
		}
	}

	render() {
		const {checkBoxes, maxPops, minRate, maxRate} = this.state
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>一括ランダム採点</h4>
				<div style={{marginTop:20}}>
					採点をシミュレーションします<br/>
					指定した最大数のユーザに、すべての問題を自動でランダム回答させ、採点します。<br/>
					動作を試すために使用します。<br/>
					ランダム性は 100% = サイコロで回答 ／ 0%=全問正答 を意味します。最大・最小の値を広げると、その分バラつきます<br/>
					例：最小＝0,   最大＝0   とすると、全員が全問正解します。<br/>
					例：最小＝100, 最大＝100 とすると、全員が完全にサイコロで回答します（大抵の問題は5択なので正答率は20%前後に収束）<br/>
					例：最小＝50,  最大＝100 とすると、半々〜完全にサイコロ の範囲でバラけます
				</div>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item style={{width:200, marginRight:20}}>
						<SlTextField
							name="maxPops"
							label="採点させる最大人数"
							placeholder="例：1000"
							state={maxPops}
							onChange={(newState: TextInputState) => {
								this.setState({maxPops: newState})
							}}
						/>
					</Grid>
					<Grid item style={{width:200, marginRight:20}}>
						<SlTextField
							name="minRate"
							label="ランダム性（最小：％）"
							placeholder="例：50"
							state={minRate}
							onChange={(newState: TextInputState) => {
								this.setState({minRate: newState})
							}}
						/>
					</Grid>
					<Grid item style={{width:200}}>
						<SlTextField
							name="maxRate"
							label="ランダム性（最大：％）"
							placeholder="例：75"
							state={maxRate}
							onChange={(newState: TextInputState) => {
								this.setState({maxRate: newState})
							}}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item>
						<Checkbox
							checked={checkBoxes[0]}
							onChange={this.handleToggleCheck(0)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[1]}
							onChange={this.handleToggleCheck(1)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[2]}
							onChange={this.handleToggleCheck(2)}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</Grid>
					<Grid item>
						３つすべてをONにして、ランダム採点ボタンを押してください（元に戻せません）
					</Grid>
				</Grid>
				<div style={{marginTop:20}}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleSaiten}
					>
						ランダム採点
					</Button>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmSaitenRandom)