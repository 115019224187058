import React from 'react'
import BbsThread from './BbsThread'
import SlTextField from '../../sonodalib/sl_mui/SlTextField'
import {TextInputState} from '../../sonodalib/sl_utils/SlForms'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

export default class BoardThread extends React.Component {
	state = {
		searchUniqueId: new TextInputState(),
		uniqueId: "",
	}

	handleSearch = () => {
		this.setState({
			uniqueId: this.state.searchUniqueId.getValue(),
		})
	}

	render() {
		const {boardId} = this.props
		const { uniqueId } = this.state
		return (
			<div>
				<section className="content-header">
					<h1>
						掲示板検索
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				<section className="content">
					<Grid container direction="row">
						<Grid item xs={6}>
							<SlTextField
								label="投稿者ID（省略可）"
								state={this.state.searchUniqueId}
								onChange={(newState) => {
									this.setState({searchUniqueId: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleSearch}
							>
								検索
							</Button>
						</Grid>
					</Grid>
					<div style={{marginTop: 100}}>
						お問い合わせ 検索結果
					</div>
					<BbsThread
						boardId={boardId}
						ident={null}
						uniqueId={uniqueId}
					/>
				</section>
			</div>
		)
	}
}
