import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Moment from 'moment'
import BbsThread from './BbsThread'
import Cookies from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {amber,blue} from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export default class Board1Thread extends React.Component {
	state = {
		threads: null,
		openThreadList: false,
		threadIdent: 0,
		infoString: "",
	}

	componentWillMount() {
		this.fetchThread()
	}

	fetchThread() {
		const self = this
		post("admin/fetch_threads", {
			user_token: Cookies.get("sl_users"),
			board_id: 1,
			ident: null,
			unique_id: "",
			only_unread: false,
		})
		.then(ret => {
			PropTypes.checkPropTypes({
				list: PropTypes.array.isRequired,
			}, ret)
			ret.list = _.uniqBy(ret.list, 'ident')
			ret.list.forEach((row) => {row.checked = false})
			self.setState({
				threads: ret,
			})
		})
	}

	handleClickThread = (thread) => {
		this.setState({
			openThreadList: true,
			threadIdent: thread.ident,
		})
	}

	handleToggleCheckbox = (thread:any) => () => {
		thread.checked = !thread.checked
		this.setState({
			threads: this.state.threads
		})
	}

	_checkAll = (checked) => {
		const {threads} = this.state
		if (threads) {
			threads.list.forEach((row:any) => {
				row.checked = checked
			})
			this.setState({
				threads: this.state.threads
			})
		}
	}

	handleCheckAll = () => {
		this._checkAll(true)
	}

	handleUncheckAll = () => {
		this._checkAll(false)
	}

	handleDelete = () => {
		if (window.confirm("削除してよろしいですか？（元に戻せません）")) {
			const {threads} = this.state
			if (threads) {
				let p = Promise.resolve()
				threads.list.filter((thread:any) => (thread.checked)).forEach((thread: any) => {
					p = p.then(() =>
						new Promise((resolve, reject) => {
							this.setState({
								infoString: "削除中... " + this.getThreadTitle(thread),
							})
							post("admin/del_board1_thread_perm", {
								user_token: Cookies.get("sl_users"),
								ident: thread.ident,
							})
							.then((ret) => {
								resolve(ret)
							})
							.catch((ret) => {
								reject(ret)
							})
						})
					)
				})
				p.then(() => {
					alert("削除しました")
					this.fetchThread()
				})
				.catch(() => {
					alert("不明なエラーが発生しました")
				})
				// $FlowFixMe
				.finally(() => {
					this.setState({
						infoString: "",
					})
				})
			}
		}
	}

	handleBack = () => {
		this.setState({
			openThreadList: false,
		})
	}

	getThreadTitle = (thread) => {
		return thread.Shikenshurui + " " + thread.Shikenkaisu + " " + thread.Kamokumei + " 問題 " + thread.MondaiNum
	}

	render() {
		if (this.state.openThreadList) {
			return (
				<div>
					<div style={{margin:20}}>
						<Button variant="contained" onClick={this.handleBack} style={{backgroundColor: amber[400]}}>
							学習掲示板１ 問題リストに戻る
						</Button>
					</div>
					<BbsThread
						boardId={1}
						ident={this.state.threadIdent}
						uniqueId=""
					/>
				</div>
			)
		}
		const styles = {
			listInnerDiv: {
				backgroundColor: "#e8eaf6",
			},
			nowrap: {
				whiteSpace: 'nowrap',
			}
		}
		const {threads} = this.state
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					読み込み中・・・
				</TableCell>
			</TableRow>
		) : (threads.list.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					まだ投稿はありません
				</TableCell>
			</TableRow>
		) : _.map(
			_.reverse(_.sortBy(threads.list, "updated")), thread => (
				<TableRow hover key={thread.uuid}>
					<TableCell padding="default">
						<Checkbox
							checked={thread.checked}
							onChange={this.handleToggleCheckbox(thread)}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</TableCell>
					<TableCell padding="dense" style={styles.nowrap}><a style={{cursor:'pointer'}} onClick={this.handleClickThread.bind(this, thread)}>{this.getThreadTitle(thread)}</a></TableCell>
					<TableCell padding="default">{((!thread.MarubatsuID) ? "選択問題" : "◯✕問題")}</TableCell>
					<TableCell padding="default">{Moment(thread.updated).format("YYYY/MM/DD")}</TableCell>
				</TableRow>
			)
		)

		const command_box = (!threads || threads.length === 0) ? null : (
			<div className="clearfix" style={{marginTop:20}}>
				<ul className="no-margin pull-left">
					<Button
						variant="contained"
						style={{marginLeft: 8, color: 'white', backgroundColor: blue[400]}}
						onClick={this.handleCheckAll}
					>
						すべて選択
					</Button>
					<Button
						variant="contained"
						style={{marginLeft: 8, color: 'white', backgroundColor: blue[400]}}
						onClick={this.handleUncheckAll}
					>
						選択解除
					</Button>
					<Button
						variant="contained"
						color="secondary"
						style={{marginLeft: 14}}
						onClick={this.handleDelete}
					>
						完全に削除
					</Button>
				</ul>
			</div>
		)

		return (
			<div>
				<section className="content-header">
					<h1>
						学習掲示板 1
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				{command_box}
				<section className="content">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell padding="default">問題</TableCell>
								<TableCell padding="default">種類</TableCell>
								<TableCell padding="default">スレッド更新日</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{threadList}
						</TableBody>
					</Table>
				</section>
				{command_box}
				<Dialog open={(this.state.infoString.length !== 0)}>
					<DialogContent>
						<div style={{textAlign: 'center'}}>
							{this.state.infoString}
						</div>
					</DialogContent>
				</Dialog>
			</div>
		)
	}
}
