import assert from 'assert'
import * as Action from './SlUtilsAction';
//import * as Action_sl_users from '../sl_users/actions';

const initialState = {
  loading: 0,
	snackbarText: "",
	snackbarOpen: false,
	forms: {},
	genericModalText: "",
	genericModalOpen: false,
};

export default function sl_utils(state = initialState, action) {
//	let _state;
	let p = action.payload
  switch (action.type) {
		case Action.UPDATE_FORM:
			return {...state, forms: {...state.forms, [p.formName]: p.entity}}
		// case Action.CREATE_FORM:
		// 	return {...state, forms: {...state.forms, [p.formName]: {inst: p.inst}}}
		// case Action.CREATE_INPUT:
		// 	return {...state, forms: {...state.forms, [p.formName]: p.entity}}
		// 	_state = Object.assign({}, state);
		// 	Object.assign(_state.forms[p.formName], p.object);
		// 	return _state;
		// case Action.SET_FORM_INPUT:
		// 	_state = Object.assign({}, state);
		// 	Object.assign(_state.forms[p.formName][p.inputName], p.keyValue);
		// 	return _state;
		// case Action.SET_FORM_INPUT_ERROR:
		// 	_state = Object.assign({}, state)
		// 	let entity = Object.assign({}, state.forms[p.formName])
		// 	entity[p.inputName].error = p.errorText
		// 	_state.forms[p.formName] = entity
		// 	return _state;
	  case Action.LOADING_VISIBILITY:
	  // case Action_sl_users.REQUEST_LOGIN:
	  // case Action_sl_users.LOGGED_IN_OK:
	  // case Action_sl_users.LOGGED_IN_NG:
	  // case Action_sl_users.REQUEST_INVITE:
	  // case Action_sl_users.INVITED_OK:
	  // case Action_sl_users.INVITED_NG:
			let current = state.loading
			if (p.loading)
				current++
			else
				current--
			assert(current >= 0)
      return {...state, loading: current }
	  case Action.UPDATE_SNACKBAR:
			if (p.text === false) {
	      return {...state, snackbarOpen: false }
			} else {
	      return {...state, snackbarText: p.text, snackbarOpen: true }
			}
		case Action.UPDATE_GENERIC_MODAL:
			if (p.text === false) {
				return {...state, genericModalOpen: false }
			} else {
				return {...state, genericModalText: p.text, genericModalOpen: true }
			}
    default:
      return state
  }
}
