// @flow
import React from 'react'
import {connect} from 'react-redux'
import {post} from "../sonodalib/sl_utils/Http";
import G from "../sonodalib/SlGlobal";
import {updateAllGroupsAC} from './MyPageAction'

type Props = {
	init: () => void,
	children: any,
}

class MyPageGlobal extends React.Component<Props, {}> {
	componentWillMount() {
		this.props.init()
	}

	render() {
		return this.props.children
	}
}

export default connect(
	state => ({
		// storeUserSearch: state.mypage.userSearch,
	}),
	dispatch => ({
		init: function () {
			post("admin/fetch_all_groups", {})
			.then((ret: any) => {
				G.store.dispatch(updateAllGroupsAC({groupList: ret.group_list}))
			})
		}
	})
)(MyPageGlobal)
