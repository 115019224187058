// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
// import IconSelector from '../../sonodalib/sl_mui/IconSelector'
import {post, post_wm} from '../../sonodalib/sl_utils/Http'
// import LinkDantaiOnOffEdit from "./LinkDantaiOnOffEdit";
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import G from "../../sonodalib/SlGlobal";

type Props = {
	history: any,
}
type States = {
	editServiceCode: TextInputState,
	editLicenseCode: TextInputState,
	editRejectMessage: TextInputState
}
class SelfCheckTestSetting extends React.Component<Props, States> {
	state = {
		editServiceCode: new TextInputState({}),
		editLicenseCode: new TextInputState({}),
		editRejectMessage: new TextInputState({}),
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post("admin/fetch_etc_setting", {})
		.then((ret) => {
			const r1 = ret.admin_attr.find((r1) => (r1.key === 'sct_service'))
			const editServiceCode = (r1) ? r1.value : ""
			const r2 = ret.admin_attr.find((r2) => (r2.key === 'sct_license'))
			const editLicenseCode = (r2) ? r2.value : ""
			const r3 = ret.admin_attr.find((r3) => (r3.key === 'sct_reject_message'))
			const editRejectMessage = (r3) ? r3.value : ""
			this.setState({
				editServiceCode:   this.state.editServiceCode.setValue(editServiceCode),
				editLicenseCode:   this.state.editLicenseCode.setValue(editLicenseCode),
				editRejectMessage: this.state.editRejectMessage.setValue(editRejectMessage)
			})
		})
	}

	handleUpdateServiceCode = () => {
		const {editServiceCode} = this.state
		const service_code = editServiceCode.getValue()
		post_wm("admin/check_service_code", {
			stype: "selfchecktest",
			service_code
		})
		.then(ret => {
			if (ret.err) {
				alert(ret.err)
			} else {
				if (ret.warn) {
					alert(ret.warn)
				}
				post("admin/update_etc_setting", {
					key: "sct_service",
					value: service_code,
				})
				.then((ret) => {
					G.store.dispatch(updateSnackbarAC("更新しました"))
				})
			}
		})
	}

	handleLicenseCodeServiceCode = () => {
		const {editLicenseCode} = this.state
		post("admin/update_etc_setting", {
			key: "sct_license",
			value: editLicenseCode.getValue(),
		})
		.then((ret) => {
			G.store.dispatch(updateSnackbarAC("更新しました"))
		})
	}

	handleRejectMessage = () => {
		const {editRejectMessage} = this.state
		post("admin/update_etc_setting", {
			key: "sct_reject_message",
			value: editRejectMessage.getValue(),
		})
		.then((ret) => {
			G.store.dispatch(updateSnackbarAC("更新しました"))
		})
	}

	render() {
		const {editServiceCode, editLicenseCode, editRejectMessage} = this.state

		return (
			<div style={{padding: 20}}>
				<h3>セルフチェックテスト設定</h3>
				<div style={{marginTop:30}}>
					<div style={{fontWeight:700}}>
						マイページに設置するリンク先のサービスコード
					</div>
					<div>
						<SlTextField
							state={editServiceCode}
							style={{width:200, marginLeft:10, marginRight:10}}
							onChange={(newState: TextInputState) => {
								this.setState({editServiceCode: newState})
							}}
							placeholder="例：201304"
						/>
						<Button
							variant="contained"
							onClick={this.handleUpdateServiceCode}
							color="secondary"
							size="small"
							style={{marginTop:16}}
						>
							更新
						</Button>
					</div>
					<div>
						空欄の場合は、セルフチェックテストへのリンクが表示されません
					</div>
				</div>
				<div style={{marginTop:30}}>
					<div style={{fontWeight:700}}>
						利用条件
					</div>
					<div>
						<SlTextField
							state={editLicenseCode}
							style={{width:200, marginLeft:10, marginRight:10}}
							onChange={(newState: TextInputState) => {
								this.setState({editLicenseCode: newState})
							}}
							placeholder="例：01,012,2"
						/>
						<Button
							variant="contained"
							onClick={this.handleLicenseCodeServiceCode}
							color="secondary"
							size="small"
							style={{marginTop:16}}
						>
							更新
						</Button>
					</div>
					<div>
						カンマ区切りで複数設定できます<br/>
						例：01,012,2
					</div>
				</div>
				<div style={{marginTop:30}}>
					<div style={{fontWeight:700}}>
						条件が合わない場合に表示するメッセージ
					</div>
					<div>
						<SlTextField
							state={editRejectMessage}
							style={{width:"80%", marginLeft:10, marginRight:10}}
							onChange={(newState: TextInputState) => {
								this.setState({editRejectMessage: newState})
							}}
							placeholder="例：ご利用になるには申込みが必要です"
						/>
						<Button
							variant="contained"
							onClick={this.handleRejectMessage}
							color="secondary"
							size="small"
							style={{marginTop:16}}
						>
							更新
						</Button>
					</div>
					<div>
						例：ご利用になるには申込みが必要です
					</div>
				</div>
				<div style={{marginTop:100}}>
					<div style={{marginBottom:10}}>
						サンプル
					</div>
					<div className="small-box bg-green" style={{marginBottom: 8, cursor: 'pointer', width: 400}}>
						<div className="inner" style={{textAlign: 'left'}}>
							<span style={{fontWeight: 800, fontSize: 20}}>セルフチェックテスト</span>
							<span style={{fontWeight: 800, fontSize: 14}}>＆学習ナビ</span>
							<p><span style={{fontSize: 14}}>　</span></p>
						</div>
						<div className="icon">
							<i className="fas fa-chart-line"></i>
						</div>
						<span className="small-box-footer">サイトに移動</span>
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(SelfCheckTestSetting)
