// @flow
import React from 'react'
import ContentHeader from '../ContentHeader'
import {withRouter} from 'react-router-dom'
import Selector from '../../sonodalib/sl_adminlte/Selector'
import SlTextField from '../../sonodalib/sl_mui/SlTextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {post} from '../../sonodalib/sl_utils/Http'
import Moment from 'moment'
import _ from 'lodash'
import {SelectorInputState, TextInputState} from '../../sonodalib/sl_utils/SlForms'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SlTextFieldSelector from "../../sonodalib/sl_mui/SlTextFieldSelector";
import OrderDetail from "./OrderDetail";
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import Cookies from "js-cookie";
import {saveAs} from 'file-saver'

const statusList = [
	{id: "all", label: "すべて"},
	{id: "wait-quick-payed", label: "速報情報待ち"},
	{id: "cancelled", label: "キャンセル済"},
	{id: "completed", label: "完了"},
]

type Props = {
	history: any,
}
type States = {
	searchUniqueId: TextInputState,
	searchStatus: SelectorInputState,
	searchResult?: Array<any>,
	detailOrderId: string,
	count: number,
	pageCount: number,
	currPage: number,
}

class OrderSearch extends React.Component<Props, States> {
	state = {
		searchUniqueId: new TextInputState(),
		searchStatus: new SelectorInputState({menuList: statusList}),
		searchResult: undefined,
		detailOrderId: "",
		count: 0,
		pageCount: 0,
		currPage: 1,
	}

	handleClickOrder = (order) => {
		// this.props.history.push("/my/order-manage/order/"+order.uuid)
		this.setState({
			detailOrderId: order.uuid,
		})
	}

	fetch(page) {
		const self = this
		post("order/fetch", {
			user_token: Cookies.get("sl_users"),
			unique_id: this.state.searchUniqueId.getValue(),
			status: this.state.searchStatus.getValue(),
			page: page,
		})
		.then(ret => {
			self.setState({
				searchResult: ret.list,
				currPage: ret.page,
				count: ret.count,
				pageCount: ret.page_count,
			})
		})
	}

	handleSubmit = (event: any) => {
		event.preventDefault()
		this.fetch(1)
	}

	handleCurrentPage = (value) => {
		this.fetch(value)
	}

	handleCloseDetail = () => {
		this.setState({
			detailOrderId: "",
		})
	}

	handleSearch = (download, event) => {
		event.preventDefault()
		post("order/fetch", {
			user_token: Cookies.get("sl_users"),
			unique_id: this.state.searchUniqueId.getValue(),
			status: this.state.searchStatus.getValue(),
			page: 0,	// means ALL
		})
		.then(ret => {
			const csvs = ret.list.map((order) => (
				`${Moment(order.requested_date).format("YYYY/MM/DD HH:mm:ss")},\
${order.uuid},\
${order.unique_id},\
${order.name},\
${order.service_name},\
${order.status}`
			))
			csvs.unshift("注文日,注文ID,利用者ID,利用者名,申込サービス,状態")
			const csv = csvs.join("\n")
			const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
			const blob = new Blob([bom, csv], {"type": "text/csv"});
			saveAs(blob, "注文データ.csv")
		})
		.catch(ret => {
			console.log("ret=", ret)
		})
	}

	render() {
		const styles = {
			pageSelector: {
				width: 60,
				height: 30,
				marginRight: 6,
			},
		}
		const {searchResult} = this.state
		const threadList = (searchResult === undefined) ? (
			<TableRow>
				<TableCell colSpan={4}>
					検索結果
				</TableCell>
			</TableRow>
		) : (searchResult.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					みつかりませんでした
				</TableCell>
			</TableRow>
		) : _.map(
			searchResult, order => {
				// const service = (services[order.service]) ? services[order.service].label : "不明"
				return (
					<TableRow hover key={order.uuid}>
						<TableCell style={{padding:10}} padding="none"><a style={{cursor: 'pointer'}}
																					onClick={this.handleClickOrder.bind(this, order)}>{Moment(order.requested_date).format("YYYY/MM/DD HH:mm:ss")}</a></TableCell>
						<TableCell style={{padding:10}} padding="none">{order.uuid}</TableCell>
						<TableCell style={{padding:10}} padding="none">{order.unique_id}</TableCell>
						<TableCell style={{padding:10}} padding="none">{order.name}</TableCell>
						<TableCell style={{padding:10}} padding="none">{order.service_name}</TableCell>
						<TableCell style={{padding:10}} padding="none">{order.status}</TableCell>
					</TableRow>
				)
			}
		)
		const content = (this.state.detailOrderId.length) ? (
			<OrderDetail
				orderId={this.state.detailOrderId}
				onClose={this.handleCloseDetail}
			/>
		) : (
			<form onSubmit={this.handleSubmit}>
				<Grid container direction="row">
					<Grid item xs={6}>
						<SlTextField
							label="利用者ID（省略可）"
							state={this.state.searchUniqueId}
							onChange={(newState) => {
								this.setState({searchUniqueId: newState})
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<SlTextFieldSelector
							label="未読・すべて"
							state={this.state.searchStatus}
							onChange={(newState) => {
								this.setState({searchStatus: newState})
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							検索
						</Button>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={4} style={{marginTop: 100}}>
					<Grid item>
						検索結果 {this.state.count}件 見つかりました
					</Grid>
					<Grid item>
						<BsButton
							color="primary"
							onClick={this.handleSearch.bind(this, true)}
						>
							CSVダウンロード
						</BsButton>
					</Grid>
				</Grid>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="default">注文日</TableCell>
							<TableCell padding="default">注文ID</TableCell>
							<TableCell padding="default">利用者ID</TableCell>
							<TableCell padding="default">利用者名</TableCell>
							<TableCell padding="default">申込サービス</TableCell>
							<TableCell padding="default">状態</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{threadList}
					</TableBody>
				</Table>
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<Selector
							style={styles.pageSelector}
							onChange={this.handleCurrentPage}
							value={this.state.currPage}
							minNumber={1}
							maxNumber={this.state.pageCount}
						>
						</Selector>
					</Grid>
					<Grid item>
						／ 全 {this.state.pageCount}ページ
					</Grid>
				</Grid>
			</form>
		)

		return (
			<div>
				<ContentHeader
					title="注文管理"
					subTitle="Order management"
				/>
				<section className="content">
					{content}
				</section>
			</div>
		)
	}
}

export default withRouter(OrderSearch)

