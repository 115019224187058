// @flow
import * as React from 'react'
// import type {Node} from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import _ from 'lodash'

export type MenuRow = {
	id:		 string|number,
	label: string,
	onClick?: () => void,
}
export type Props = {
	menuList: Array<MenuRow>,
	value?: any,
	onChange?: (string|number)=>void,
	trigger: (selected?: MenuRow, (event: SyntheticEvent<HTMLButtonElement>)=>void) => ?React.Node
}
export type States = {
	open: boolean,
	anchorEl?: any,
}
export default class Selector extends React.Component<Props,States> {
	state = {
		open: false,
		anchorEl: undefined,
	}

	handleOpen = (event: SyntheticEvent<HTMLButtonElement>) => {
		this.setState({
			open: true,
			anchorEl: event.currentTarget
		})
	}

	handleSelect = (menu:MenuRow) => {
		if (this.props.onChange) {
			this.props.onChange(menu.id)
		}
		if (menu.onClick) {
			menu.onClick()
		}
		this.handleRequestClose()
	}

	handleRequestClose = () => {
    this.setState({
    	open: false
		})
  }

	render() {
		const { menuList, value, trigger } = this.props
		const selected = _.find(menuList, {id: value})
		const triggerNode = trigger(selected, this.handleOpen)
		return (
			<div>
				{triggerNode}
				<Menu
					id="selector-menu"
					anchorEl={this.state.anchorEl}
					open={this.state.open}
					onClose={this.handleRequestClose}
				>
					{_.map(menuList, menu => {
						return (
							<MenuItem
								key={menu.id}
								selected={menu.id === value}
								onClick={this.handleSelect.bind(this, menu)}
							>
								{menu.label}
							</MenuItem>
					)})}
				</Menu>
			</div>
		)
	}
}
