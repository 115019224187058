// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import Moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Cookies from "js-cookie";

type Props = {
	orderId: string,
	onClose: () => void,
}
type States = {
	loaded: boolean,
	requestedDate: string,
	userId: string,
	uniqueId: string,
	name: string,
	service: string,
	status: string,
	postScript: string,
	amount: string,
	quickPayedDate: string,
	cancelledDate: string,
	paydate: string,
	conveni: string,
	receiptno: string,
}

export default class OrderDetail extends React.Component<Props, States> {
	state = {
		loaded: false,
		requestedDate: "読み込み中...",
		userId: "読み込み中...",
		uniqueId: "読み込み中...",
		name: "読み込み中...",
		service: "読み込み中...",
		status: "読み込み中...",
		postScript: "読み込み中...",
		amount: "読み込み中...",
		quickPayedDate: "読み込み中...",
		cancelledDate: "読み込み中...",
		paydate: "読み込み中...",
		conveni: "読み込み中...",
		receiptno: "読み込み中...",
	}

	componentWillMount() {
		const self = this
		post("order/fetchone", {
			user_token: Cookies.get("sl_users"),
			order_id: this.props.orderId,
		})
		.then((ret) => {
			const row = ret.row
			self.setState({
				loaded: true,
				requestedDate: Moment(row.requested_date).format("YYYY/MM/DD HH:mm:ss"),
				userId: row.user_id,
				uniqueId: row.unique_id,
				name: row.name,
				service: row.service,
				service_name: row.service_name,
				status: row.status,
				postScript: row.post_script,
				amount: row.amount,
				quickPayedDate: Moment(row.quick_payed_date).format("YYYY/MM/DD HH:mm:ss"),
				cancelledDate: Moment(row.cancelled_date).format("YYYY/MM/DD HH:mm:ss"),
				paydate: row.paydate,
				conveni: row.conveni,
				receiptno: row.receiptno,
			})
		})
		.catch((ret) => {
		})
	}

	render() {
		if (!this.state.loaded) return null
		// const service = (services[this.state.service]) ? services[this.state.service].label : "不明"
		return (
			<div>
				<div style={{maxWidth: 800}}>
					<Grid container direction="row">
						<Grid item xs={3}>
							注文ID
						</Grid>
						<Grid item xs={9}>
							{this.props.orderId}
						</Grid>
						<Grid item xs={3}>
							注文日時
						</Grid>
						<Grid item xs={9}>
							{this.state.requestedDate}
						</Grid>
						<Grid item xs={3}>
							利用者ID
						</Grid>
						<Grid item xs={9}>
							{this.state.uniqueId}
						</Grid>
						<Grid item xs={3}>
							利用者名
						</Grid>
						<Grid item xs={9}>
							{this.state.name}
						</Grid>
						<Grid item xs={3}>
							申込サービス
						</Grid>
						<Grid item xs={9}>
							{this.state.service_name}
						</Grid>
						<Grid item xs={3}>
							状態
						</Grid>
						<Grid item xs={9}>
							{this.state.status}
						</Grid>
						<Grid item xs={3}>
							連絡事項
						</Grid>
						<Grid item xs={9}>
							{this.state.postScript}
						</Grid>
						<Grid item xs={3}>
							支払金額
						</Grid>
						<Grid item xs={9}>
							{this.state.amount}
						</Grid>
						<Grid item xs={3}>
							コンビニコード
						</Grid>
						<Grid item xs={9}>
							{this.state.conveni}
						</Grid>
						<Grid item xs={3}>
							レシートNo.
						</Grid>
						<Grid item xs={9}>
							{this.state.receiptno}
						</Grid>
						<Grid item xs={3}>
							決済処理日時
						</Grid>
						<Grid item xs={9}>
							{this.state.paydate}
						</Grid>
					</Grid>
					<br/>
					<Button
						color="primary"
						onClick={this.props.onClose}
						variant="contained"
					>
						検索に戻る
					</Button>
				</div>
			</div>
		)
	}
}




