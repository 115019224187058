// @flow
import React from 'react';
import type {WmService} from "../../shared/ServerInterface";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import CurrentService from "./CurrentService";
import Button from "@material-ui/core/Button";
import G from "../../sonodalib/SlGlobal";
import {updateWmInfoAC} from "../MyPageAction";


type Props = {
	history: any,
	currService:   WmService,
	info: string,
}
type States = {
	// info: string,
}

class WmInformation extends React.Component<Props, States> {
	state = {
		// info: "",
	}

	// componentDidMount() {
	// 	this.fetch()
	// }
	//
	// fetch() {
	// 	const {currService} = this.props
	// 	if (currService) {
	// 		post_wm("admin/fetch_information", {
	// 			service_code: currService.service
	// 		})
	// 		.then((ret) => {
	// 			this.setState({
	// 				info: ret.info
	// 			})
	// 		})
	// 	}
	// }

	handleUpdate = () => {
		const {currService, info} = this.props
		if (currService) {
			if (window.confirm("更新してよろしいですか？")) {
				post_wm("admin/upsert_information", {
					service_code: currService.service,
					text: info.trim(),
				})
				.then((ret) => {
					alert("更新しました")
				})
			}
		}
	}

	render() {
		const {currService, info} = this.props

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<h3>インフォメーション</h3>
						<textarea
							id="info"
							className="form-control"
							onChange={(el)=>{
								G.store.dispatch(updateWmInfoAC(el.target.value))
								// this.setState({
								// 	info: el.target.value,
								// })
							}}
							value={info}
							rows={16}
							disabled={(currService.locked !== 0)}
						/>
						<div style={{marginTop:30}}>
							<Button
								variant="contained"
								onClick={this.handleUpdate}
								color="primary"
								disabled={(currService.locked !== 0)}
							>
								更新
							</Button>
						</div>
					</React.Fragment>
				)}
			</div>
		)
	}
}
export default connect(
	state => ({
		currService: state.mypage.currService,
		info:			   state.mypage.info,
	}),
)(WmInformation)