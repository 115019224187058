// @flow
import React from 'react'


export const Instruction = () => (
	<div>
		<div style={{margin:20}}>
			<div style={{fontWeight:700}}>
				CSVサンプル
			</div>
			<div>
				<a href="https://docs.google.com/spreadsheets/d/1RMMi0hf7foZ4JEdEkoRA0syKrhowYzi9QMM0I2luQdQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
					https://docs.google.com/spreadsheets/d/1RMMi0hf7foZ4JEdEkoRA0syKrhowYzi9QMM0I2luQdQ/edit?usp=sharing
				</a>
			</div>
		</div>
		<div style={{margin:20}}>
			<div style={{fontWeight:700}}>
				画像の挿入方法
			</div>
			<div>
				ファイルアップローダーで画像ファイルをサーバにアップロードし、問題文に<br/>
				[[img:（ファイル名）]]<br/>
				と入力する<br/>
				【例】[[img:MONDAI_001.png]]<br/>
				なお、ファイルアップローダーのファイル一覧にこのタグが表示されますので、コピーペーストする事ができます<br/>
			</div>
		</div>
		<div style={{margin:20}}>
			<div style={{fontWeight:700}}>
				改行等HTMLタグの挿入方法
			</div>
			<div>
				そのまま入力できます
			</div>
		</div>
		<div style={{margin:20}}>
			<div style={{fontWeight:700}}>
				復習問題の入力
			</div>
			<div>
				複数の場合は、カンマで区切って下さい<br/>
				無い場合は、0 としてください
			</div>
		</div>
	</div>
)