// @flow
import React from 'react'
import type {WmService} from "../../../shared/ServerInterface";
import Button from "@material-ui/core/Button";
import {TextInputState} from '../../../sonodalib/sl_utils/SlForms'
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import G from "../../../sonodalib/SlGlobal";
import {updateWmCategoriesToIgnoreCheckBoxes} from "../../MyPageAction";
import SlTextField from "../../../sonodalib/sl_mui/SlTextField";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";


type Props = {
	currService: WmService,
	shukei_etc: any,
	uniqueIdToIgnore: TextInputState,
	categoriesToIgnoreCheckBoxes: Array<boolean>,
}
export default function WmExcludeAnySaiten(props: Props) {

	const {currService, shukei_etc, uniqueIdToIgnore, onChangeUniqueIdToIgnore, categoriesToIgnoreCheckBoxes} = props

	const handleToggleCheck = (checkbox) => () => {
		checkbox.value = !checkbox.value
		G.store.dispatch(updateWmCategoriesToIgnoreCheckBoxes(categoriesToIgnoreCheckBoxes.concat()))
	}

	const handleSubmit = (method) => () => {
		const methodLabels = {
			"ignore":  "除外",
			"restore": "復帰"
		}
		const validatedUniqueId = uniqueIdToIgnore.validate()
		onChangeUniqueIdToIgnore(validatedUniqueId, () => {
			if (validatedUniqueId.isValid()) {
				const selectedCategories = categoriesToIgnoreCheckBoxes.filter((checkbox) => (checkbox.value)).map((checkbox) => (checkbox.id))
				if (!selectedCategories.length) {
					alert("カテゴリを１つ以上選択してください")
				} else {
					if (window.confirm(methodLabels[method]+"を実行してよろしいですか？")) {
						const unique_id = validatedUniqueId.getValue()
						post_wm("admin/set_ignore_flag", {
							service_code: currService.service,
							unique_id: 	  unique_id,
							op:       		method,
							categories: 	selectedCategories.join(","),
						})
						.then((ret) => {
							if (ret.error) {
								if (ret.error === "unique_id not found")
									alert("利用者ID "+unique_id+" は見つかりません")
							} else {
								alert("完了しました。次回の集計時に反映されます")
							}
						})
						.catch(()=>{
							alert("エラーが発生しました admin/set_ignore_flag")
						})
					}
				}
			}
		})
	}

	if (!shukei_etc) return null
	if (!categoriesToIgnoreCheckBoxes) return null
	return (
		<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
			<h4>特定の採点情報を、集計から除外／復帰</h4>
			<div>
				次回の集計で反映されます
			</div>
			<div>
				<SlTextField
					label="利用者ID"
					placeholder="例：200060102"
					style={{width:150, marginLeft:10, marginRight:10}}
					state={uniqueIdToIgnore}
					onChange={onChangeUniqueIdToIgnore}
				/>
			</div>
			<div style={{marginTop:20}}>
				<div>
					カテゴリ（１つ以上選択）
				</div>
				<div>
					{categoriesToIgnoreCheckBoxes.map((checkbox) => {
						return (
							<Grid container alignItems="center" key={checkbox.label}>
								<Grid item>
									<Checkbox
										checked={checkbox.value}
										onChange={handleToggleCheck(checkbox)}
										value="1"
										style={{width: 24, height: 24}}
									/>
								</Grid>
								<Grid item>
									{checkbox.label}
								</Grid>
							</Grid>
						)
					})}
				</div>
			</div>
			<div style={{marginTop:20}}>
				<div>
					操作
				</div>
				<div>
					<Button variant="contained" color="primary" size="small" onClick={handleSubmit('ignore')}>
						除外する
					</Button>
					<Button variant="contained" color="secondary" size="small" onClick={handleSubmit('restore')} style={{marginLeft:20}}>
						復帰する
					</Button>
				</div>
			</div>
		</div>
	)
}
