import React from 'react';
import { connect } from 'react-redux'
import { logout } from '../sonodalib/sl_users/SlUsersAction'
import {contentLabel} from '../alt/constants'

class AccountMenu_ extends React.Component {
  render() {
    return (
      <li className="dropdown user user-menu">
        <a className="dropdown-toggle" data-toggle="dropdown">
          <span className="hidden-xs">ログイン中</span>
          <span className="caret"></span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li><a onClick={this.props.handleLogout.bind(this)}>ログアウト</a></li>
        </ul>
      </li>
    )
  }
}

const AccountMenu = connect(
  state => ({
    name: state.sl_users.state
  }),
  dispatch => ({
    handleLogout: function() {
			logout(dispatch)
			// location.href = "/"
    },
  })
)(AccountMenu_)


export default class Header extends React.Component {
  render() {
    return (
        <header className="main-header">
          { /* Logo */ }
          <a href="index2.html" className="logo">
            { /* mini logo for sidebar mini 50x50 pixels */ }
            <span className="logo-mini">赤</span>
            { /* logo for regular state and mobile devices */ }
            <span className="logo-lg">赤マル福祉（{contentLabel}）</span>
          </a>
          <nav className="navbar navbar-static-top">
            <a className="sidebar-toggle" data-toggle="offcanvas" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <AccountMenu />
              </ul>
            </div>
          </nav>
        </header>
    )
  }
}
