// @flow
import React from 'react';
import {Flex, Box} from 'reflexbox'
import TextInput from "../../sonodalib/sl_adminlte/TextInput";
import {FormState, TextInputState} from "../../sonodalib/sl_utils/SlForms";
// import {EmailTemplateList, updateEmailTemplateListAC} from './MyPageAction'
import {post} from '../../sonodalib/sl_utils/Http'
import {updateSnackbarAC} from '../../sonodalib/sl_utils/SlUtilsAction'
import G from '../../sonodalib/SlGlobal'

// type Props = {
// 	emailTemplateList: EmailTemplateList,
// }
type States = {
	fromAddr: TextInputState,
	fromName: TextInputState,
	subject: TextInputState,
	body: TextInputState,
}

export default class EmailNotifyBbsReply extends React.Component<{}, States> {
	state = {
		fromAddr: new TextInputState({validations: "required,email"}),
		fromName: new TextInputState({validations: "required"}),
		subject: new TextInputState({validations: "required"}),
		body: new TextInputState({validations: "required"}),
	}

	componentWillMount() {
		this.update()
	}

	// componentWillReceiveProps(nextProps: Props) {
	// 	this.update(nextProps)
	// }

	update() {
		post("admin/fetch_email_template", {name: "notify_bbs_reply"})
		.then((ret) => {
			if (ret) {
				this.setState({
					fromAddr: this.state.fromAddr.setValue(ret.from_addr),
					fromName: this.state.fromName.setValue(ret.from_name),
					subject: this.state.subject.setValue(ret.subject),
					body: this.state.body.setValue(ret.body),
				})
			}
		})
	}

	onSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
		event.preventDefault()
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const payload = {
					name: "notify_bbs_reply",
					from_addr: this.state.fromAddr.getValue(),
					from_name: this.state.fromName.getValue(),
					subject: this.state.subject.getValue(),
					body: this.state.body.getValue(),
				}
				post("admin/update_email_template", payload)
				.then(() => {
					G.store.dispatch(updateSnackbarAC("保存しました"))
					// G.store.dispatch(updateEmailTemplateListAC({templateList: ret}))
				})
				.catch(() => {
					G.store.dispatch(updateSnackbarAC("エラーが発生しました"))
				})
			}
		})

	}

	render() {
		return (
			<div>
				<section className="content-header">
					<h1>
						自動送信メール 掲示板返信通知メール
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				<section className="content">

					<Flex column>
						<Box p={1}>
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<TextInput
										label="送信元メールアドレス"
										type="email"
										state={this.state.fromAddr}
										onChange={(newState) => {
											this.setState({fromAddr: newState})
										}}
									/>
								</div>
								<div className="form-group">
									<TextInput
										label="送信元表示名"
										type="text"
										state={this.state.fromName}
										onChange={(newState) => {
											this.setState({fromName: newState})
										}}
									/>
								</div>
								<div className="form-group">
									<TextInput
										label="表題"
										type="text"
										state={this.state.subject}
										onChange={(newState) => {
											this.setState({subject: newState})
										}}
									/>
								</div>
								<div className="form-group">
									<TextInput
										label="本文"
										type="text"
										multiline={true}
										rows="8"
										state={this.state.body}
										onChange={(newState) => {
											this.setState({body: newState})
										}}
									/>
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-primary">更新</button>
								</div>
							</form>
							※ 本文中： [thread-title] ＝ 該当のスレッド名が展開されます<br/>
						</Box>
					</Flex>
				</section>
			</div>
		);
	}
}

// export default connect(
// 	state => ({
// 		emailTemplateList: state.mypage.emailTemplateList,
// 	}),
// 	dispatch => ({})
// )(EmailChangeLicense)