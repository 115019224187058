// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconSelector from '../../sonodalib/sl_mui/IconSelector'
import {post} from '../../sonodalib/sl_utils/Http'
import type {AdminMoshiDantai, AdminMoshiSetting} from '../../shared/ServerInterface'
import LinkSettingEdit from "./LinkSettingEdit";
import {TextInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import _ from 'lodash'
import {licenseList} from '../../alt/constants'

type Props = {
	history: any,
}
type States = {
	edit: string,
	editGroupId: TextInputState,
	editServiceCode: TextInputState,
	editLicense: TextInputState,
	editDesc: TextInputState,
	link: Array<AdminMoshiSetting>,
}
class LinkSetting extends React.Component<Props, States> {
	state = {
		edit: "",			// 編集中のUUID（"NEW"=新規）
		editGroupId: new TextInputState({validations:"required"}),
		editServiceCode: new TextInputState({validations:"required"}),
		editPayServiceCode: new TextInputState({}),
		editLicense: new TextInputState({}),
		editDesc: new TextInputState({validations:"required"}),
		link: [],
	}

	componentWillMount() {
		post("admin/fetch_moshi_link", {})
		.then((ret: {link:Array<AdminMoshiDantai>}) => {
			this.setState({
				link: ret.link,
			})
		})
	}

	handleEdit = (row:AdminMoshiSetting) => () => {
		this.setState({
			edit: row.uuid,
			editGroupId: this.state.editGroupId.setValue(row.id),
			editServiceCode: this.state.editServiceCode.setValue(row.service),
			editPayServiceCode: this.state.editPayServiceCode.setValue((row.pay_service === null) ? "" : row.pay_service),
			editLicense: this.state.editLicense.setValue((row.license === null) ? "" : row.license),
			editDesc: this.state.editDesc.setValue(row.desc),
		})
	}

	handleSwitchValidation = (row:AdminMoshiSetting) => () => {
		post("admin/switch_validation_moshi_link", {
			uuid: row.uuid,
		})
		.then((ret:{link:Array<AdminMoshiSetting>}) => {
			this.setState({
				link: ret.link,
				edit: "",
			})
		})
	}

	handleDelete = (row:AdminMoshiSetting) => () => {
		if (window.confirm("削除してもよろしいですか？")) {
			post("admin/delete_moshi_link", {
				uuid: row.uuid,
			})
			.then((ret: {link:Array<AdminMoshiSetting>}) => {
				this.setState({
					link: ret.link,
				})
			})
		}
	}

	handleAdd = () => {
		this.setState({
			edit: "NEW",
			editGroupId: this.state.editGroupId.setValue(""),
			editServiceCode: this.state.editServiceCode.setValue(""),
			editPayServiceCode: this.state.editPayServiceCode.setValue(""),
			editLicense: this.state.editLicense.setValue(""),
			editDesc: this.state.editDesc.setValue(""),
		})
	}

	handleCancel = () => {
		this.setState({
			edit: "",
		})
	}

	handleUpdate = () => {
		this.setState(FormState.validate(this.state), ()=>{
			if (FormState.isValid(this.state)) {
				let error = false
				post("admin/update_moshi_link", {
					uuid: this.state.edit,
					group_id: this.state.editGroupId.getValue(),
					service: this.state.editServiceCode.getValue(),
					pay_service: this.state.editPayServiceCode.getValue().replaceAll(" ",""),
					license: this.state.editLicense.getValue().replaceAll(" ",""),
					desc: this.state.editDesc.getValue(),
				})
				.then((ret: any) => {		//{link:Array<AdminMoshiSetting>} | {status:string}
					if (ret.status === "group_id not found") {
						alert("指定の団体IDが見つかりません")
						error = true
					} else {
						this.setState({
							link: ret.link,
						})
					}
				})
				.catch((ret:any) => {
					alert("すでに指定の団体ID、サービスコードは入力済みです")
					error = true
				})
				.finally(() => {
					if (!error) this.setState({edit:""})	//this.props.history.push("/my/moshi/link-setting")
				})
			}
		})
	}

	render() {
		const {edit, editGroupId, editServiceCode, editPayServiceCode, editLicense, editDesc} = this.state
		if (edit.length > 0) {
			return (
				<div style={{padding: 20}}>
					<h3>編集：オリジナル模試のリンク設定</h3>
					<LinkSettingEdit
					 	editGroupId={editGroupId}
						onChangeGroupId={(newState: TextInputState) => {
							this.setState({editGroupId: newState})
						}}
						editServiceCode={editServiceCode}
						onChangeServiceCode={(newState: TextInputState) => {
							this.setState({editServiceCode: newState})
						}}
						editPayServiceCode={editPayServiceCode}
						editLicense={editLicense}
						onChangePayServiceCode={(newState: TextInputState) => {
							this.setState({editPayServiceCode: newState})
						}}
						onChangeLicense={(newState: TextInputState) => {
							this.setState({editLicense: newState})
						}}
						editDesc={editDesc}
						onChangeDesc={(newState: TextInputState) => {
							this.setState({editDesc: newState})
						}}
						onUpdate={this.handleUpdate}
						onCancel={this.handleCancel}
					/>
				</div>
			)
		}

		const validationText = ["無効", "有効"]
		const rows = []
		this.state.link.forEach((row:AdminMoshiSetting) => {
			rows.push(
				<Grid key={row.uuid+"valid"} item xs={1}>
					{validationText[row.valid]}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"id"} item xs={1}>
					{row.id}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"name"} item xs={2}>
					{row.name}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"service"} item xs={1}>
					{row.service}
				</Grid>
			)
			let pay_service = "（無指定）"
			if (row.pay_service) {
				pay_service = row.pay_service
				if (row.pay_error) {
					pay_service = <span style={{color:'red'}}>{pay_service}（不明）</span>
				}
			}
			rows.push(
				<Grid key={row.uuid+"pay_service"} item xs={2}>
					{pay_service}
				</Grid>
			)
			let license = "（無指定）"
			if (row.license) {
				license = row.license
				const licenses = license.split(",")
				const valid = (license === "999" || licenses.every(l => (_.find(licenseList, {id: l}) !== undefined)))
				if (!valid) {
					license = <span style={{color:'red'}}>{license}（不明）</span>
				}
			}
			rows.push(
				<Grid key={row.uuid+"license"} item xs={2}>
					{license}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"desc"} item xs={2}>
					{row.desc}
				</Grid>
			)
			rows.push(
				<Grid key={row.uuid+"ctrl"} item xs={1} style={{padding:0}}>
					<IconSelector
						menuList={[
							{id: 0, label: "編集", onClick: this.handleEdit(row)},
							{id: 1, label: (row.valid) ? "無効化" : "有効化", onClick: this.handleSwitchValidation(row)},
							{id: 2, label: "削除", onClick: this.handleDelete(row)},
						]}
					/>
				</Grid>
			)
		})

		return (
			<div style={{padding: 20}}>
				<h3>オリジナル模試のリンク設定</h3>
				<div style={{margin:30}}>
					<h4>団体詳細設定</h4>
				</div>
				<Grid container direction="row" alignItems="center" style={{marginTop:20}}>
					<Grid item xs={1} style={{fontSize:11,fontWeight:600}}>
						有効性
					</Grid>
					<Grid item xs={1} style={{fontSize:11,fontWeight:600}}>
						団体ID
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						団体名
					</Grid>
					<Grid item xs={1} style={{fontSize:11,fontWeight:600}}>
						模試サービスコード
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						課金サービスコード（カンマ区切り）
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						対象ライセンス（カンマ区切り）
					</Grid>
					<Grid item xs={2} style={{fontSize:11,fontWeight:600}}>
						リンク表示テキスト
					</Grid>
					<Grid item xs={1} style={{fontSize:11,fontWeight:600}}>
						操作
					</Grid>
					{rows}
				</Grid>
				<div style={{marginTop:30}}>
					<Button
						variant="contained"
						onClick={this.handleAdd}
						color="primary"
					>
						追加
					</Button>
				</div>
			</div>
		)
	}
}
export default withRouter(LinkSetting)
