// @flow
import React from 'react'
import ClassNames from 'classnames'

type Props = {
	flat?: boolean,
	block?: boolean,
	disabled?: boolean,
	color?: 'default' | 'primary' | 'danger' | 'success' | 'info' | 'warning' | 'maroon' | 'purple' | 'navy' | 'orange' | 'olive',
	size?: 'normal' | 'lg' | 'sm' | 'xs',
	children: any,
}

export default class BsButton extends React.Component<Props> {
	render() {
		const { flat, block, disabled, color, size, children, ...other } = this.props
		const prevClasses = ClassNames({
			"btn": true,
			"btn-flat": flat,
			"btn-block": block,
			"disabled": disabled,
			"btn-primary": (color === 'primary'),
			"btn-danger": (color === 'danger'),
			"btn-success": (color === 'success'),
			"btn-info": (color === 'info'),
			"btn-warning": (color === 'warning'),
			"bg-maroon": (color === 'maroon'),
			"bg-purple": (color === 'purple'),
			"bg-navy": (color === 'navy'),
			"bg-orange": (color === 'orange'),
			"bg-olive": (color === 'olive'),
			"btn-lg": (size === 'lg'),
			"btn-sm": (size === 'sm'),
			"btn-xs": (size === 'xs'),
		})
		return (
			<button type="button" className={prevClasses} {...other}>{children}</button>
		)
	}
}

