// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid'
import Cookies from "js-cookie";
import TextField from '@material-ui/core/TextField';
import {post} from '../sonodalib/sl_utils/Http'
import _ from 'lodash'
import {updateSnackbarAC} from '../sonodalib/sl_utils/SlUtilsAction'
import G from "../sonodalib/SlGlobal";
import Moment from 'moment'

type States = {
	shakaiDate: string,
	seishinDate: string,
}
export default class ScheduleSetting extends React.Component<{}, States> {
	state = {
		shakaiDate: "",
		seishinDate: "",
	}

	componentWillMount() {
		const getDateString = (event:any) => {
			return Moment((event) ? new Date(event.at_date) : new Date()).format("YYYY-MM-DD")
		}
		post("etc/calendar/fetch_events")
		.then((ret: any) => {
			const events = ret.events
			let shakaiDate = ""
			let seishinDate = ""
			shakaiDate = getDateString(_.find(events, {tag: "SHAKAI"}))
			seishinDate = getDateString(_.find(events, {tag: "SEISHIN"}))
			this.setState({
				shakaiDate,
				seishinDate,
			})
		})
	}

	handleChangeShakaiDate = (event: any) => {
		this.updateDate(event.currentTarget.value, "SHAKAI", "社会福祉士国家試験")
		this.setState({
			shakaiDate: event.currentTarget.value,
		})
	}
	handleChangeSeishinDate = (event: any) => {
		this.updateDate(event.currentTarget.value, "SEISHIN", "精神保健福祉士国家試験")
		this.setState({
			seishinDate: event.currentTarget.value,
		})
	}

	updateDate = (at_date: string, tag: string, title: string) => {
		if (at_date.length) {
			const payload = {
				user_token: Cookies.get("sl_users"),
				tag: tag,
				title: title,
				at_date: Moment(new Date(at_date)).format("YYYY-MM-DD"),
			}
			post("etc/calendar/update_events", payload)
			.then((ret: any) => {
				G.store.dispatch(updateSnackbarAC(title + " の日時を更新しました"))
			})
		}
	}

	render() {
		return (
			<div style={{margin: 8}}>
				<h3>試験日時</h3>
				<Grid container direction="row">
					<Grid item>
						<TextField
							id="date"
							label="社会福祉士国家試験 日時表示"
							type="date"
							value={this.state.shakaiDate}
							style={{width: 250}}
							InputLabelProps={{
								shrink: true,
								disableAnimation: true,
							}}
							onChange={this.handleChangeShakaiDate}
						/>
					</Grid>
					<Grid item>
						<TextField
							id="date"
							label="精神保健福祉士国家試験 日時表示"
							type="date"
							value={this.state.seishinDate}
							style={{width: 250}}
							InputLabelProps={{
								shrink: true,
								disableAnimation: true,
							}}
							onChange={this.handleChangeSeishinDate}
						/>
					</Grid>
				</Grid>
			</div>
		)
	}
}
