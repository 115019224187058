import { combineReducers } from 'redux';
import mypage from './mypage/MyPageReducer';
import sl_users from './sonodalib/sl_users/SlUsersReducer';
import sl_utils from './sonodalib/sl_utils/SlUtilsReducer';

const reducers = combineReducers({
  mypage,
  sl_users,
  sl_utils,
});

export default reducers;
