import React from 'react';
import {connect} from "react-redux";
import {Route, Switch} from 'react-router-dom'
import Header from './Header'
import SideBar from './SideBar'
import DashBoard from './DashBoard'
import EmailDigitConfirmation from './email-template/EmailDigitConfirmation'
import EmailChangeLicense from './email-template/EmailChangeLicense'
import EmailNotifyContact from './email-template/EmailNotifyContact'
import EmailNotifyBbsReply from './email-template/EmailNotifyBbsReply'
import UserSearch from '../alt/UserSearch'
import UserDetail from './user/UserDetail'
import UserAdd from './user/UserAdd'
import GroupAdd from './group/GroupAdd'
import GroupDantaiSetting from './group/GroupDantaiSetting'
import InfoList from './view-settings/InfoList'
import LoginInfoList from './view-settings/LoginInfoList'
import ScheduleSetting from '../alt/ScheduleSetting'
import EtcSetting from './etc-setting/EtcSetting'
import WmServiceList from './wm/WmServiceList'
import WmSubjects from './wm/WmSubjects'
import WmFileUploader from './wm/WmFileUploader'
import WmInformation from './wm/WmInformation'
import WmKohyo from './wm/WmKohyo'
import LinkDantaiOnOff from './moshi/LinkDantaiOnOff'
import WebJidousaitenSetting from './moshi/WebJidousaitenSetting'
import SelfCheckTestSetting from './moshi/SelfCheckTestSetting'
import LinkSetting from './moshi/LinkSetting'
import PaySetting from './order-manage/PaySetting'
import OrderSearch from './order-manage/OrderSearch'
import GroupList from './group/GroupList';
import {post_wm} from '../sonodalib/sl_utils/Http';
import G from '../sonodalib/SlGlobal';
import {updateWmCurrService, updateWmServicesAC, updateWmServiceTypesAC} from './MyPageAction';
import MyPageGlobal from './MyPageGlobal';
import EditMondai from './content/EditMondai';
import EditSentakushi from './content/EditSentakushi';
import EditMarubatsu from "./content/EditMarubatsu";
import EditJireiBun from "./content/EditJireiBun";
import EditJireiMondai from "./content/EditJireiMondai";
import HowToUserImage from "./content/HowToUserImage";
import Board1Thread from "./bbs/Board1Thread";
import Board2Thread from "./bbs/Board2Thread";
import Board3Thread from "./bbs/Board3Thread";
import Board4Thread from "./bbs/Board4Thread";
import ContactThread from "./bbs/ContactThread";
import WmSctEtc from "./wm/WmSctEtc";
import WmHanteiTh from "./wm/WmHanteiTh";
import WmMondai from "./wm/mondai/WmMondai";
import WmMondaiAdd from "./wm/mondai/WmMondaiAdd";
import _ from 'lodash'
import WmSaiten from "./wm/saiten";
import WmShukei from "./wm/shukei";
import WmTasks from "./wm/WmTasks";
// import BoardThread from "./bbs/BoardThread";
// import Posts from "./bbs/ContactPost";

// class EmailTemplateForm extends SlForm {
// 	constructor(templateName, name, store, onSubmitCb, onCancelCb) {
// 		super(name, store);
//     this.templateName = templateName
// 		this.onSubmitCb = onSubmitCb
// 		this.onCancelCb = onCancelCb
// 		this.from_addr = this.addInput(new SlFormText(this, "from_addr", ['require','email']))
// 		this.from_name = this.addInput(new SlFormText(this, "from_name", ['require']))
// 		this.subject = this.addInput(new SlFormText(this, "subject", ['require']))
// 		this.body = this.addInput(new SlFormText(this, "body", ['require']))
// 	}
//
// 	onSubmit() {
// 		const self = this
// 		this.validate()
// 		if (this.isValid()) {
// 			const payload = {
// 				name: 		self.templateName,
//         from_addr: self.from_addr.getValue(),
//         from_name: self.from_name.getValue(),
//         subject: self.subject.getValue(),
//         body: self.body.getValue(),
// 			}
// 			self.store.dispatch(loadingVisibilityAC(true));
// 			G.post("admin/update_email_template", payload)
// 				.then(ret => {
// 					self.store.dispatch(loadingVisibilityAC(false))
// 					self.store.dispatch(updateSnackbarAC("保存しました"))
// 					if (self.onSubmitCb)
// 						self.onSubmitCb()
// 				})
// 				.catch(res => {
// 					self.store.dispatch(loadingVisibilityAC(false))
// 					self.from_addr.error("不明なエラーが発生しました")
// 				})
// 		}
// 	}
// }

class MyPage extends React.Component {

  componentWillMount() {
    const {wmServiceList} = this.props
    if (!wmServiceList) {
      post_wm("admin/fetch_service_list", {})
      .then(ret => {
        G.store.dispatch(updateWmServicesAC(ret.services))
        G.store.dispatch(updateWmServiceTypesAC(ret.service_types))
        const serviceCodeStr = localStorage.getItem("wmCurrService")
        if (serviceCodeStr) {
          const service = _.find(ret.services, {service:parseInt(serviceCodeStr,10)})
          if (service) {
            updateWmCurrService(service)
          } else {
            localStorage.removeItem("wmCurrService")
          }
        }
      })
    }
  }
  // componentWillMount() {
  //   post("admin/fetch_email_templates", {})
  //     .then(ret => {
  //       G.store.dispatch(updateEmailTemplateListAC({templateList:ret}))
		// 	})
  // }

  render() {
    const {currService} = this.props
    let currServicePages = null
    if (currService) {
      currServicePages = [
        <Route key={1} exact path="/my/wm/subjects" component={WmSubjects}/>,
        <Route key={2} exact path="/my/wm/sct_etc" component={WmSctEtc}/>
      ]
    }
    return (
      <MyPageGlobal>
        <div className="wrapper">
          <Header/>
          <SideBar/>

          <div className="content-wrapper">
            <Switch>
              <Route exact path="/my/user/search" component={UserSearch}/>
              <Route exact path="/my/user/detail/:userId" component={UserDetail}/>
              <Route exact path="/my/user/add" component={UserAdd}/>
              <Route exact path="/my/content/edit-mondai" component={EditMondai}/>
              <Route exact path="/my/content/edit-sentakushi" component={EditSentakushi}/>
              <Route exact path="/my/content/edit-marubatsu" component={EditMarubatsu}/>
              <Route exact path="/my/content/edit-jireibun" component={EditJireiBun}/>
              <Route exact path="/my/content/edit-jireimondai" component={EditJireiMondai}/>
              <Route exact path="/my/content/how-to-use-image" component={HowToUserImage}/>
              <Route exact path="/my/email-template/mail-address-confirmation" component={EmailDigitConfirmation}/>
              <Route exact path="/my/email-template/change-license" component={EmailChangeLicense}/>
              <Route exact path="/my/email-template/notify-bbs-reply" component={EmailNotifyBbsReply}/>
              <Route exact path="/my/email-template/notify-contact" component={EmailNotifyContact}/>
              <Route exact path="/my/group/list" component={GroupList}/>
              <Route exact path="/my/group/add" component={GroupAdd}/>
              <Route exact path="/my/group/dantai" component={GroupDantaiSetting}/>
              <Route exact path="/my/view-settings/information" component={InfoList}/>
              <Route exact path="/my/view-settings/schedule" component={ScheduleSetting}/>
              <Route exact path="/my/view-settings/logininfo" component={LoginInfoList}/>
              <Route exact path="/my/etc-settings" component={EtcSetting}/>
              <Route exact path="/my/moshi/link-setting" component={LinkSetting}/>
              <Route exact path="/my/moshi/dantai-on-off" component={LinkDantaiOnOff}/>
              <Route exact path="/my/moshi/webjidousaiten-setting" component={WebJidousaitenSetting}/>
              <Route exact path="/my/moshi/sct-setting" component={SelfCheckTestSetting}/>
              <Route exact path="/my/wm/service-list" component={WmServiceList}/>
              {/*<Route exact path="/my/wm/subjects" component={WmSubjects}/>*/}
              <Route exact path="/my/wm/mondai-add" component={WmMondaiAdd}/>
              <Route exact path="/my/wm/mondai" component={WmMondai}/>
              <Route exact path="/my/wm/saiten" component={WmSaiten}/>
              <Route exact path="/my/wm/shukei" component={WmShukei}/>
              <Route exact path="/my/wm/uploader" component={WmFileUploader}/>
              <Route exact path="/my/wm/info" component={WmInformation}/>
              <Route exact path="/my/wm/kohyo" component={WmKohyo}/>
              <Route exact path="/my/wm/hantei_th" component={WmHanteiTh}/>
              {/*<Route exact path="/my/wm/sct_etc" component={WmSctEtc}/>*/}
              <Route exact path="/my/wm/tasks" component={WmTasks}/>
              <Route exact path="/my/pay/pay-setting" component={PaySetting}/>
              <Route exact path="/my/pay/order-manage" component={OrderSearch}/>
              <Route exact path="/my/bbs/board1-list" component={Board1Thread}/>
              <Route exact path="/my/bbs/board2-list" component={Board2Thread}/>
              <Route exact path="/my/bbs/board3-list" component={Board3Thread}/>
              <Route exact path="/my/bbs/board4-list" component={Board4Thread}/>
              <Route exact path="/my/bbs/thread-list/:boardId" component={ContactThread}/>
              {/*<Route exact path="/my/bbs/posts/:threadId" component={Posts}/>*/}
              {currServicePages}
              <Route component={DashBoard}/>
            </Switch>
          </div>

          <footer className="main-footer">
            <div className="pull-right hidden-xs">
              <b>Version</b> 0.1.0
            </div>
            <strong>Copyright © 2017 <a href="http://www.jc-edu.co.jp">JC教育研究所</a></strong> All rights reserved.
          </footer>
        </div>
      </MyPageGlobal>
    );
  }
}
export default connect(
  state => ({
    wmServiceList: state.mypage.wmServiceList,
		currService:   state.mypage.currService,
  }),
)(MyPage)
