// @flow
import React from 'react';
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
// import IconSelector from '../../sonodalib/sl_mui/IconSelector'
import {post, post_wm} from '../../sonodalib/sl_utils/Http'
// import LinkDantaiOnOffEdit from "./LinkDantaiOnOffEdit";
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import G from "../../sonodalib/SlGlobal";

type Props = {
	history: any,
}
type States = {
	editServiceCode: TextInputState,
}
class WebJidousaitenSetting extends React.Component<Props, States> {
	state = {
		editServiceCode: new TextInputState({}),
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		post("admin/fetch_etc_setting", {})
		.then((ret) => {
			const row = ret.admin_attr.find((row) => (row.key === 'webjidousaiten_service'))
			const value = (row) ? row.value : ""
			this.setState({
				editServiceCode: this.state.editServiceCode.setValue(value)
			})
		})
	}

	handleUpdateServiceCode = () => {
		const {editServiceCode} = this.state
		const service_code = editServiceCode.getValue()
		post_wm("admin/check_service_code", {
			stype: "jidosaiten",
			service_code
		})
		.then(ret => {
			if (ret.err) {
				alert(ret.err)
			} else {
				if (ret.warn) {
					alert(ret.warn)
				}
				post("admin/update_etc_setting", {
					key: "webjidousaiten_service",
					value: service_code,
				})
				.then((ret) => {
					G.store.dispatch(updateSnackbarAC("更新しました"))
				})
			}
		})
	}

	render() {
		const {editServiceCode} = this.state

		return (
			<div style={{padding: 20}}>
				<h3>Web自動採点設定</h3>
				<div style={{marginTop:30}}>
					<div style={{fontWeight:700}}>
						マイページに設置するリンク先のサービスコード
					</div>
					<div>
						<SlTextField
							state={editServiceCode}
							style={{width:150, marginLeft:10, marginRight:10}}
							onChange={(newState: TextInputState) => {
								this.setState({editServiceCode: newState})
							}}
							placeholder="例：201304"
						/>
						<Button
							variant="contained"
							onClick={this.handleUpdateServiceCode}
							color="secondary"
							size="small"
							style={{marginTop:16}}
						>
							更新
						</Button>
					</div>
					<div>
						空欄の場合は、Web自動採点へのリンクが表示されません
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(WebJidousaitenSetting)
