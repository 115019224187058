import React from 'react';
import {post} from '../../sonodalib/sl_utils/Http'
import {normalizeRow} from '../../sonodalib/sl_utils/Utils'
import ContentHeader from '../ContentHeader'
import Selector from '../../sonodalib/sl_adminlte/Selector'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import _ from 'lodash'
import {TextInputState} from "../../sonodalib/sl_utils/SlForms";
// import type {AdminMoshiSetting} from "../../shared/ServerInterface";

const kanriEnableSelector = [
	{ id: 0, label: "なし" },
	{ id: 1, label: "あり" },
]

class GroupDantaiSetting extends React.Component {
	state = {
		loginId: new TextInputState({validation:"required"}),
		password: new TextInputState({validation:"required"}),
		calendarTsv: new TextInputState(),
		dantaiList: [],
		selectedKanriEnable: 0,
		selectedDantaiId: 0,
		errorText: "",
	}

	componentWillMount() {
		post("admin/fetch_dantai_list", {})
		.then(ret => {
			ret = normalizeRow(ret)
			const dantaiList = ret.dantaiList
			this.setState({
				selectedDantaiId: dantaiList[0].uuid,
				dantaiList,
			})
			post("admin/fetch_dantai",{})
			.then((ret) => {
				this.admin_dantai = ret.admin_dantai
				this.updateForm(dantaiList[0].uuid)
			})
		})
	}

	handleSubmit = (event) => {
		event.preventDefault()
		const {selectedDantaiId, selectedKanriEnable, loginId, password, calendarTsv} = this.state
		const _loginId = loginId.getValue()
		const _password = password.getValue()
		const _calendarTsv = calendarTsv.getValue()
		const tsv = _calendarTsv.trim()
		let errorHappened = false
		let errorText = ""
		if (tsv.length) {
			const tsvs = tsv.split("\n")
			errorHappened = _.some(tsvs, (line, index) => {
				const cols = line.trim().split(",")
				if (cols.length !== 2) {
					errorText = "カレンダー設定の"+(index+1)+"行目は、2列である必要があります"
					return true
				}
				const dateCol = cols[0].split("/")
				if (dateCol.length !== 3) {
					errorText = "カレンダー設定の"+(index+1)+"行目の日付が間違っています。例）2019/3/12"
					return true
				}
				if (_.isFinite(dateCol[0]) || _.isFinite(dateCol[1] || _.isFinite(dateCol[2]))) {
					errorText = "カレンダー設定の"+(index+1)+"行目の日付が間違っています。例）2019/3/12,模試"
					return true
				}
				if (parseInt(dateCol[0],10)<2018) {
					errorText = "カレンダー設定の"+(index+1)+"行目の日付の年が異常です。例）2019"
					return true
				}
				if (parseInt(dateCol[1],10)<1 || 12<parseInt(dateCol[1],10)) {
					errorText = "カレンダー設定の"+(index+1)+"行目の日付の月が異常です。例）10"
					return true
				}
				if (parseInt(dateCol[2],10)<1 || 31<parseInt(dateCol[2],10)) {
					errorText = "カレンダー設定の"+(index+1)+"行目の日付の日が異常です。例）4"
					return true
				}
				if (cols[1].trim().length === 0) {
					errorText = "カレンダー設定の"+(index+1)+"行目の２列目に表示内容を記入してください 例）2019/2/12,模試"
					return true
				}
				return false
			})
		}
		if (!errorHappened) {
			if (_loginId.length < 5 || _loginId.length > 32) {
				errorText = "ログインIDを５〜32文字以上で設定してください"
			} else if (_password.length < 5 || _password.length > 32) {
				errorText = "パスワードを５〜32文字以上で設定してください"
			}
		}

		this.setState({
			errorText,
		})
		if (!errorText) {
			post("admin/update_dantai", {
				dantai_id: selectedDantaiId,
				dantai_kanri_enabled: selectedKanriEnable,
				login_id: _loginId,
				password: _password,
				calendar_csv: tsv,
			})
			.then((ret) => {
				this.admin_dantai = ret.admin_dantai
				alert("更新しました")
			})
		}
	}

	handleChangeKanriEnable = (id) => {
		this.setState({
			selectedKanriEnable: id,
		})
	}

	updateForm = (group_id) => {
		const row = _.find(this.state.dantaiList, {uuid:group_id})
		const dantai_id = row.id
		const dantai = _.find(this.admin_dantai, {dantai_id})
		let loginId = ""
		let password = ""
		let selectedKanriEnable = false
		let calendarTsv = ""
		if (dantai) {
			loginId = dantai.login_id
			password = dantai.password
			selectedKanriEnable = dantai.enabled
			calendarTsv = dantai.calendar_jc || ""
		}
		this.setState({
			loginId: this.state.loginId.setValue(loginId),
			password: this.state.password.setValue(password),
			selectedKanriEnable: selectedKanriEnable,
			calendarTsv: this.state.calendarTsv.setValue(calendarTsv),
		})
	}

	handleChangeDantai = (id) => {
		this.updateForm(id)
		this.setState({
			selectedDantaiId: id,
		})
	}

	render() {
		if (!this.admin_dantai) return null

		const {loginId, password, calendarTsv, selectedKanriEnable, selectedDantaiId, errorText} = this.state
		const {dantaiList} = this.state
		const options = _.map(dantaiList, dantai => {
			return (
				<option key={dantai.uuid} value={dantai.uuid}>{dantai.id} {dantai.name}</option>
			)
		})
		const dantaiSelectorVd = (
			<Selector
				className="form-control"
				value={selectedDantaiId}
				onChange={this.handleChangeDantai}
			>
				{options}
			</Selector>
		)
		return (
			<div>
				<ContentHeader
					title="団体設定"
					subTitle="Group settings"
				/>
				<section className="content">
					{dantaiSelectorVd}
					<form onSubmit={this.handleSubmit} className="form-horizontal" style={{marginTop:20}}>
						<div className="form-group">
							<label htmlFor="id" className="col-sm-3 control-label">団体用管理ページ</label>
							<div className="col-sm-9">
								<Selector
									className="form-control"
									value={selectedKanriEnable}
									onChange={this.handleChangeKanriEnable}
								>
									{_.map(kanriEnableSelector, row => (
										<option key={row.id} value={row.id}>{row.label}</option>
									))}
								</Selector>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="id" className="col-sm-3 control-label">ID</label>
							<div className="col-sm-9">
								<TextInput
									type="text"
									className="form-control"
									id="id"
									hintText="ログインID"
									state={loginId}
									onChange={value => {
										this.setState({loginId: value})
									}}
								/>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="pw" className="col-sm-3 control-label">パスワード</label>
							<div className="col-sm-9">
								<TextInput
									type="text"
									className="form-control"
									id="pw"
									hintText="パスワード"
									state={password}
									onChange={value => {
										this.setState({password: value})
									}}
								/>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="pw" className="col-sm-3 control-label">カレンダー設定</label>
							<div className="col-sm-9">
								<TextInput
									type="text"
									multiline={true}
									rows="8"
									state={calendarTsv}
									onChange={(state) => {this.setState({calendarTsv:state})}}
								/>
							</div>
						</div>
						{(errorText) && (
							<div className="alert alert-danger alert-dismissible">
								<button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
								<h4><i className="icon fa fa-ban"></i> エラー</h4>
								{errorText}
							</div>
						)}
						<BsButton
							type="submit"
							color="primary"
						>
							更新
						</BsButton>
					</form>
				</section>
			</div>
		)
	}
}

export default GroupDantaiSetting