// @flow
import React from 'react'
import { getUniqueStr } from '../sl_utils/Utils'
import ClassNames from 'classnames'
import type { TextInputState } from "../sl_utils/SlForms";
type Prop = {
	label?: string,
	id?: string,
	type: string,
	hintText?: string,
	errorText?: string,
	onChange?: (value:TextInputState) => void,
	multiline?: boolean,
	value?: string,
	state?: TextInputState,
	reserveErrorTextArea?: boolean,
	maxLength?: number,
}
export default class TextInput extends React.Component<Prop> {
	onChange = (event:SyntheticInputEvent<*>) => {
		//alert(event.currentTarget.value)
		const value = event.target.value
		const {onChange, state} = this.props
		if (onChange && state) {
			onChange(state.setValue(value))
		}
	}

	render() {
    const {
			label,
			state,
			reserveErrorTextArea,
			maxLength,
      errorText, // eslint-disable-line no-unused-vars
      hintText,
      id,
			multiline,		 // eslint-disable-line no-unused-vars
      // onBlur, // eslint-disable-line no-unused-vars
      onChange, // eslint-disable-line no-unused-vars
      // onFocus, // eslint-disable-line no-unused-vars
      type,
      ...other
    } = this.props;
		const errorText_ = errorText ? errorText : state ? state.getError() : ""
		const inputId = id || getUniqueStr()
		const rootClass = ClassNames({
			'has-error': !!errorText_,
		})
		const labelEl = (!!label) ? (
			<label className="control-label" htmlFor={inputId}>{label}</label>
		) : null
		let errorTextEl = (!!errorText_) ? (
			<span className="help-block">{errorText_}</span>
		) : null
		if (reserveErrorTextArea) {
			errorTextEl = (
				<div style={{marginTop:5,marginBottom:10,height:20}}>{errorTextEl}</div>
			)
		}
		const ml = maxLength || -1
		const inputStyle = {}
		if (multiline) {
			inputStyle.resize = "none"
		}
		// const ref = (elem) => this.input = elem
		// const formProps = (form) ? {
		// 	value:		 form.email.value,
		// 	errorText: form.email.error,
		// 	onChange:	 form.email.inst.onChange,
		// } : null
		const value = state ? state.getValue() : this.props.value
		const inputEl = (!multiline) ? (
			<input
				type={type}
				className="form-control"
				id={inputId}
				placeholder={hintText}
				onChange={this.onChange}
				// ref={ref}
//			{...formProps}
				value={value}
				maxLength={ml}
				style={inputStyle}
				{...other}
			/>
		) : (
			<textarea
				id={inputId}
				className="form-control"
				placeholder={hintText}
				onChange={this.onChange}
				// ref={ref}
				value={value}
				style={inputStyle}
				{...other}
			/>
		)

		return (
			<div className={rootClass}>
				{labelEl}
				{inputEl}
				{errorTextEl}
			</div>
		)
	}
}
