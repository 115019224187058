// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
// import EditInfo from './EditInfo'
import {updateSnackbarAC} from "../../sonodalib/sl_utils/SlUtilsAction";
import G from "../../sonodalib/SlGlobal";
import EditLoginInfo from "./EditLoginInfo";

type States = {
	list: Array<any>,
	editingUuid?: string,		// <EditInfo/>を、undefinedなら非表示 ""なら新規追加  !=""なら修正(UUID)
}
export default class InfoList extends React.Component<{}, States> {
	state = {
		list: [],
		editingUuid: undefined,
	}

	componentWillMount() {
		this.fetch()
	}

	fetch = () => {
		post("etc/logininfo/fetch", {})
		.then((ret: any) => {
			this.setState({
				list: ret.logininfo_texts,
			})
		})
	}

	handleAdd = () => {
		this.setState({
			editingUuid: "",
		})
	}

	handleCloseAddInfo = (isCanceled:boolean) => {
		this.setState({
			editingUuid: undefined,
		})
		if (!isCanceled) this.fetch()
	}

	handleEdit = (row:any) => () => {
		this.setState({
			editingUuid: row.uuid,
		})
	}

	handleDelete = (row:any) => () => {
		if (window.confirm("削除してもよろしいですか？")) {
			const payload = {
				info_text_id: row.uuid,
			}
			post("etc/logininfo/delete", payload)
			.then(() => {
				G.store.dispatch(updateSnackbarAC("削除しました"))
				this.fetch()
			})
		}
	}

	render() {
		const {list, editingUuid} = this.state
		if (editingUuid !== undefined) {		// fetchの完了を待つ
			const infoText: any = (editingUuid.length) ? _.find(list, {uuid: editingUuid})
				: {uuid: "", order: 0, subject: "", subject_color: "primary", body: "", view_dantai: "", view_group1: "", view_group2: "", hide_dantai: "", hide_group1: "", hide_group2: ""}
			// const infoGroups: Array<InfoGroup> = (editingUuid.length) ? _.filter(groups, {info_text_id: editingUuid}) : []
			return (
				<EditLoginInfo
					infoText={infoText}
					onClose={this.handleCloseAddInfo}
				/>
			)
		}

		const vd = list.map((row: any, index: number) => (
			//$FlowFixMe
			<React.Fragment key={index}>
				<Grid item xs={2} style={{textAlign:'center'}}>
					{row.dantai_name}
				</Grid>
				<Grid item xs={1} style={{textAlign:'center'}}>
					{row.order}
				</Grid>
				<Grid item xs={8}>
					<div className={`box box-danger`}>
						<div style={{height:100,overflowY:'auto'}} className="box-body">
							<div dangerouslySetInnerHTML={{__html: row.body.replace(/\n/g,"<br />")}}>
							</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={1}>
					<button onClick={this.handleEdit(row)} type="button" className="btn btn-success">修正</button>
					<br/>
					<br/>
					<button onClick={this.handleDelete(row)} type="button" className="btn btn-danger">削除</button>
				</Grid>
			</React.Fragment>
		))
		if (vd.length === 0) {
			vd.push(
				<div key={1} style={{margin:40}}>現在、ログインページに表示する情報はありません</div>
			)
		}
		return (
			<div style={{margin:20}}>
				<Grid container direction="row">
					<Grid item xs={2} style={{textAlign:'center'}}>
						団体
					</Grid>
					<Grid item xs={1} style={{textAlign:'center'}}>
						順
					</Grid>
					<Grid item xs={8} style={{textAlign:'center'}}>
						内容
					</Grid>
					<Grid item xs={1}>
					</Grid>
					{vd}
				</Grid>
				<div>
					<button onClick={this.handleAdd} type="button" className="btn btn-block btn-primary">追加</button>
				</div>
			</div>
		)
	}
}


