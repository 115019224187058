// @flow
import React from 'react';
import type {WmService} from "../../shared/ServerInterface";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import CurrentService from "./CurrentService";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";


type Props = {
	history: any,
	currService:   WmService,
}
type States = {
		scheduleEndDateHukushuOn: string,
		scheduleEndDateHukushuOff: string,
}

class WmSctEtc extends React.Component<Props, States> {
	state = {
		scheduleEndDateHukushuOn: "2021-01-01",
		scheduleEndDateHukushuOff: "2021-01-01",
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		const {currService} = this.props
		if (currService) {
			post_wm("admin/fetch_sct_etc", {
				service_code: currService.service
			})
			.then((ret) => {
				const {sct_etc} = ret
				if (sct_etc) {
					if (sct_etc.service !== currService.service) {
						alert("エラーが発生しました #service_err")
						location.href="/"
					}
					this.setState({
						scheduleEndDateHukushuOn: sct_etc.schedule_end_date_a,
						scheduleEndDateHukushuOff: sct_etc.schedule_end_date_b,
					})
				}
			})
		}
	}

	handleUpdate = () => {
		const {currService} = this.props
		if (currService) {
			const {scheduleEndDateHukushuOn, scheduleEndDateHukushuOff} = this.state
			post_wm("admin/update_sct_etc", {
				service_code: currService.service,
				schedule_end_date_a: scheduleEndDateHukushuOn,
				schedule_end_date_b: scheduleEndDateHukushuOff,
			})
			.then((ret) => {
				if (ret.err) {
					alert(ret.err)
				} else {
					alert("更新しました")
				}
			})
			.catch(() => {
					alert("エラーが発生しました")
			})
		}
	}

	handleChangeEndDataOn = (ev) => {
		this.setState({
			scheduleEndDateHukushuOn: ev.target.value
		})
	}

	handleChangeEndDataOff = (ev) => {
		this.setState({
			scheduleEndDateHukushuOff: ev.target.value
		})
	}


	render() {
		const {currService} = this.props
		const {scheduleEndDateHukushuOn, scheduleEndDateHukushuOff} = this.state

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<div style={{margin:20}}>
							<h3>学習終了日の設定</h3>
						</div>
						<div style={{marginTop:50, fontSize:16}}>
							総復習期間 要 の学習最終日
						</div>
						<div style={{marginTop:20}}>
							<TextField
								id="date"
								label="scheduleEndData1"
								type="date"
								value={scheduleEndDateHukushuOn}
								// defaultValue="2017-05-24"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={this.handleChangeEndDataOn}
							/>
						</div>
						<div style={{marginTop:50, fontSize:16}}>
							総復習期間不要の学習最終日
						</div>
						<div style={{marginTop:20}}>
							<TextField
								id="date"
								label="scheduleEndData2"
								type="date"
								value={scheduleEndDateHukushuOff}
								// defaultValue="2017-05-24"
								InputLabelProps={{
									shrink: true,
								}}
								onChange={this.handleChangeEndDataOff}
							/>
						</div>
						<div style={{marginTop:40}}>
							<Button
								variant="contained"
								onClick={this.handleUpdate}
								color="primary"
								size="small"
								disabled={(currService.locked === 1)}
							>
								更新
							</Button>
						</div>
					</React.Fragment>
				)}
				<Paper elevation={0} style={{marginTop:50, padding:10}}>
					ロックがかかっている場合は、更新できません
				</Paper>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmSctEtc)