// @flow
import React from 'react';
import {connect} from "react-redux";
import CurrentService from "../CurrentService";
import type {WmService} from "../../../shared/ServerInterface";
import WmSaitenStats from './WmSaitenStats'
import WmSaitenDownload from './WmSaitenDownload'
import WmSaitenDelete from './WmSaitenDelete'
import WmSaitenByFile from './WmSaitenByFile'
import WmSaitenRandom from './WmSaitenRandom'
import WmReSaiten from './WmReSaiten'


type Props = {
	currService: WmService,
}
type State = {
}
class Index extends React.Component<Props, State> {
	state = {
	}

	render() {
		const {currService} = this.props
		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<h3>採点データの管理</h3>
						<WmSaitenStats/>
						<WmSaitenDownload/>
						<WmSaitenDelete/>
						<WmSaitenByFile/>
						<WmSaitenRandom/>
						<WmReSaiten/>
					</React.Fragment>
				)}
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(Index)