// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import {post} from '../../sonodalib/sl_utils/Http'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import {AdminContentSearchJireiBun} from '../../shared/ServerInterface'
import {JireiBun} from '../../shared/DbModel'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'
import _ from "lodash"

type States = {
	JireiID: TextInputState,
	KamokuID: TextInputState,
	ShikenID: TextInputState,
	JireiTitle: TextInputState,
	JireiBun: TextInputState,
	result: Array<JireiBun>,
	editingJireiId: string,
	editKamokuID: TextInputState,
	editShikenID: TextInputState,
	editJireiTitle: TextInputState,
	editJireiBun: TextInputState,
}

export default class EditJireiBun extends React.Component<{}, States> {
	state = {
		JireiID: new TextInputState({validations: "integer"}),
		KamokuID: new TextInputState({validations: "integer"}),
		ShikenID: new TextInputState({validations: "integer"}),
		JireiTitle: new TextInputState(),
		JireiBun: new TextInputState(),
		result: [],
		editingJireiId: "",
		editKamokuID: new TextInputState({validations: "required,integer"}),
		editShikenID: new TextInputState({validations: "required,integer"}),
		editJireiTitle: new TextInputState({validations: "required"}),
		editJireiBun: new TextInputState({validations: "required"}),
	}
	searchMembers = ["JireiID", "KamokuID", "ShikenID", "JireiTitle", "JireiBun"]
	editMembers = ["editKamokuID", "editShikenID", "editJireiTitle", "editJireiBun"]

	handleSearch = () => {
		const searchMembers = this.searchMembers
		this.setState(FormState.validateMembers(this.state, searchMembers), () => {
			if (FormState.isValidMembers(this.state, searchMembers)) {
				post("admin/content/search-jireibun", FormState.valueMembers(this.state, searchMembers))
				.then((ret: AdminContentSearchJireiBun) => {
					let show = true
					if (ret.result.length > 1000) {
						show = (window.confirm(ret.result.length + "件ありますがよろしいですか？"))
					}
					if (show) {
						this.setState({
							result: ret.result,
							editingJireiId: "",
						})
					}
				})
			}
		})
	}

	handleCancelEdit = () => {
		this.setState({
			editingJireiId: "",
		})
	}

	handleSaveRow = (row: JireiBun) => {
		this.setState(FormState.validateMembers(this.state, this.editMembers), () => {
			if (FormState.isValidMembers(this.state, this.editMembers)) {
				if (window.confirm("この内容で保存してもよろしいですか？")) {
					const payload = {
						JireiID: row.JireiID,
						KamokuID: this.state.editKamokuID.getValue(),
						ShikenID: this.state.editShikenID.getValue(),
						JireiTitle: this.state.editJireiTitle.getValue(),
						JireiBun: this.state.editJireiBun.getValue(),
					}
					post("admin/content/update-jireibun", payload)
					.then(() => {
						row.KamokuID = payload.KamokuID
						row.ShikenID = payload.ShikenID
						row.Jirei_Title = payload.JireiTitle
						row.Jirei_Bun = payload.JireiBun
						this.setState({
							editingJireiId: "",
							result: this.state.result
						})
					})
					.catch(() => {
						alert("ネットワークエラーが発生しました")
					})
				}
			}
		})
	}

	handleDeleteRow = (row: JireiBun) => {
		if (window.confirm(`事例ID ${row.JireiID} を削除します。この事例文に関連するすべての事例問題も自動的に削除されます。`)) {
			const payload = {
				JireiID: row.JireiID,
			}
			post("admin/content/delete-jireibun", payload)
			.then((ret: any) => {
				if (ret.status && ret.status === 'ok') {
					const result = _.reject(this.state.result, {JireiID: row.JireiID})
					this.setState({
						editingMondaiId: "",
						result: result
					})
					alert("削除しました")
				} else {
					alert("不明なエラー")
				}
			})
			.catch((ret: any) => {
				alert("ネットワークエラーが発生しました")
			})
		}
	}

	handleEditRow = (row: JireiBun) => {
		this.setState({
			editingJireiId: row.JireiID,
			editKamokuID: this.state.editKamokuID.setValue(row.KamokuID),
			editShikenID: this.state.editShikenID.setValue(row.ShikenID),
			editJireiTitle: this.state.editJireiTitle.setValue(row.Jirei_Title),
			editJireiBun: this.state.editJireiBun.setValue(row.Jirei_Bun),
		})
	}

	render() {
		const resultVd = this.state.result.map((row: JireiBun) => {
			if (this.state.editingJireiId === row.JireiID) {
				return (
					<tr key={"result_a_" + row.JireiID}>
						<td>{row.JireiID}</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editKamokuID}
								onChange={(newState: TextInputState) => {
									this.setState({editKamokuID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editShikenID}
								onChange={(newState: TextInputState) => {
									this.setState({editShikenID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editJireiTitle}
								onChange={(newState: TextInputState) => {
									this.setState({editJireiTitle: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editJireiBun}
								multiline
								rows={20}
								onChange={(newState: TextInputState) => {
									this.setState({editJireiBun: newState})
								}}
							/>
						</td>
						<td>
							<BsButton
								onClick={this.handleSaveRow.bind(this, row)}
								color="danger"
							>
								保存
							</BsButton>
							<BsButton
								onClick={this.handleCancelEdit}
							>
								中止
							</BsButton>
						</td>
					</tr>
				)
			} else {
				return (
					<tr key={"result_b_" + row.JireiID}>
						<td>{row.JireiID}</td>
						<td>{row.KamokuID}</td>
						<td>{row.ShikenID}</td>
						<td>{row.Jirei_Title}</td>
						<td>
							<div dangerouslySetInnerHTML={{__html: row.Jirei_Bun.replace(/\n/g, "<br />")}}></div>
						</td>
						<td>
							<BsButton
								onClick={this.handleEditRow.bind(this, row)}
								color="purple"
							>
								修正
							</BsButton>
							<BsButton
								style={{marginLeft:6}}
								onClick={this.handleDeleteRow.bind(this, row)}
								color="danger"
							>
								削除
							</BsButton>
						</td>
					</tr>
				)
			}
		})

		return (
			<div>
				<section className="content-header">
					<h1>
						事例文データの修正
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				<section className="content">
					<Grid container direction="row">
						<Grid item xs={6}>
							<SlTextField
								label="事例ID"
								state={this.state.JireiID}
								onChange={(newState: TextInputState) => {
									this.setState({JireiID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="科目ID"
								state={this.state.KamokuID}
								onChange={(newState: TextInputState) => {
									this.setState({KamokuID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="試験ID"
								state={this.state.ShikenID}
								onChange={(newState: TextInputState) => {
									this.setState({ShikenID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="事例タイトル"
								state={this.state.JireiTitle}
								onChange={(newState: TextInputState) => {
									this.setState({JireiTitle: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<SlTextField
								label="事例文"
								state={this.state.JireiBun}
								onChange={(newState: TextInputState) => {
									this.setState({JireiBun: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<BsButton
								onClick={this.handleSearch}
								color="primary"
								// variant="contained"
							>
								事例文を検索
							</BsButton>
						</Grid>
					</Grid>
					<div>
						<table className="table table-bordered">
							<tbody>
							<tr>
								<th style={{width: 80, whiteSpace: 'nowrap'}}>事例ID</th>
								<th style={{width: 80, whiteSpace: 'nowrap'}}>科目ID</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>試験ID</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>事例タイトル</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>事例文</th>
							</tr>
							{resultVd}
							</tbody>
						</table>
					</div>
				</section>
			</div>
		)
	}
}

