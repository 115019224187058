// @flow
import React from 'react';
import type {WmService} from "../../shared/ServerInterface";
import {post_wm} from "../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import CurrentService from "./CurrentService";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import G from "../../sonodalib/SlGlobal";
import {updateWmKohyoCategoriesAC, updateWmKohyoShikensAC, updateWmKohyoSubjectsAC} from "../MyPageAction";


type Props = {
	history: any,
	currService:   WmService,
}
type States = {
	// kohyo_texts_shikens:    Array<any>,
	// kohyo_texts_categories: Array<any>,
	// kohyo_texts_subjects:	  Array<any>,
}

class WmKohyo extends React.Component<Props, States> {
	state = {
		// kohyo_texts_shikens: [],
		// kohyo_texts_categories: [],
		// kohyo_texts_subjects: [],
	}

	handleUpdate = (shiken) => () => {
		const {currService} = this.props
		if (currService) {
			if (window.confirm("更新してよろしいですか？")) {
				post_wm("admin/upsert_kohyo", {
					service_code: currService.service,
					type: shiken.type,
					param: shiken.param,
					text: shiken.text.trim(),
				})
				.then((ret) => {
					alert("更新しました")
				})
			}
		}
	}

	render() {
		const {currService, kohyo_texts_shikens, kohyo_texts_categories, kohyo_texts_subjects} = this.props

		const updateButton = (row) => (
			(currService.locked) ? (
				<Paper elevation={0} style={{backgroundColor:'#ffaaaa', color:'black', padding:15}}>
					ロックされているため、変更できません
				</Paper>
			) : (
				<Button
					variant="contained"
					onClick={this.handleUpdate(row)}
					color="primary"
					size="small"
				>
					更新
				</Button>
			)
		)

		const kohyosVd = kohyo_texts_shikens.map((row) => {
			return (
				<div key={row.label}>
					<div style={{marginTop:10, fontWeight:700}}>
						{row.label}
					</div>
					<textarea
						id="info"
						className="form-control"
						onChange={(el)=>{
							G.store.dispatch(updateWmKohyoShikensAC(kohyo_texts_shikens.map((x) => (x.key !== row.key) ? x : {
								...row,
								text: el.target.value,
							})))
							// row.text = el.target.value
							// this.setState({
							// 	kohyo_texts_shikens,
							// })
						}}
						value={row.text}
						rows={6}
					/>
					<div style={{marginTop:8}}>
						{updateButton(row)}
					</div>
				</div>
			)
		})

		const categoriesVd = kohyo_texts_categories.map((row) => {
			return (
				<div key={row.label}>
					<div style={{marginTop:10, fontWeight:700}}>
						{row.label}
					</div>
					<textarea
						id="info"
						className="form-control"
						onChange={(el)=>{
							G.store.dispatch(updateWmKohyoCategoriesAC(kohyo_texts_categories.map((x) => (x.key !== row.key) ? x : {
								...row,
								text: el.target.value,
							})))
							// row.text = el.target.value
							// this.setState({
							// 	kohyo_texts_categories,
							// })
						}}
						value={row.text}
						rows={6}
					/>
					<div style={{marginTop:8}}>
						{updateButton(row)}
					</div>
				</div>
			)
		})

		const subjectsVd = kohyo_texts_subjects.map((row) => {
			return (
				<div key={row.label}>
					<div style={{marginTop:10, fontWeight:700}}>
						{row.label}
					</div>
					<textarea
						id="info"
						className="form-control"
						onChange={(el)=>{
							G.store.dispatch(updateWmKohyoSubjectsAC(kohyo_texts_subjects.map((x) => (x.key !== row.key) ? x : {
								...row,
								text: el.target.value,
							})))
							// row.text = el.target.value
							// this.setState({
							// 	kohyo_texts_subjects,
							// })
						}}
						value={row.text}
						rows={6}
					/>
					<div style={{marginTop:8}}>
						{updateButton(row)}
					</div>
				</div>
			)
		})

		return (
			<div style={{padding: 20}}>
				<CurrentService/>
				{(!currService) ? null : (
					<React.Fragment>
						<div style={{margin:20}}>
							<h3>講評の編集</h3>
						</div>
						<Grid container style={{width:'100%'}}>
							<Grid item style={{width:10, backgroundColor:'#aaaaee'}}> </Grid>
							<Grid item style={{paddingLeft:10, width:'calc(100% - 10px)'}}>
								<div style={{fontSize:18}}>
									各試験の講評
								</div>
								{kohyosVd}
							</Grid>
						</Grid>
						<Grid container style={{width:'100%', marginTop:20}}>
							<Grid item style={{width:10, backgroundColor:'#aaeeaa'}}> </Grid>
							<Grid item style={{paddingLeft:10, width:'calc(100% - 10px)'}}>
								<div style={{fontSize:18}}>
									各カテゴリの講評
								</div>
								{categoriesVd}
							</Grid>
						</Grid>
						<Grid container style={{width:'100%', marginTop:20}}>
							<Grid item style={{width:10, backgroundColor:'#eeaaaa'}}> </Grid>
							<Grid item style={{paddingLeft:10, width:'calc(100% - 10px)'}}>
								<div style={{fontSize:18}}>
									各科目の講評
								</div>
								{subjectsVd}
							</Grid>
						</Grid>
					</React.Fragment>
				)}
				<Paper elevation={0} style={{marginTop:50, padding:10}}>
					<div style={{margin:10}}>
						画像の挿入方法
					</div>
					ファイルアップローダーで画像ファイルをサーバにアップロードし<br />
					[[img:（ファイル名）]]<br />
					と入力する<br />
					【例】[[img:MONDAI_001.png]]<br />
					なお、ファイルアップローダーのファイル一覧にこのタグが表示されますので、コピーペーストする事ができます<br />
				</Paper>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
		kohyo_texts_shikens:   state.mypage.kohyo_texts_shikens,
		kohyo_texts_categories:   state.mypage.kohyo_texts_categories,
		kohyo_texts_subjects:   state.mypage.kohyo_texts_subjects,
	}),
)(WmKohyo)