// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {FormState, TextInputState} from "../../sonodalib/sl_utils/SlForms";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

type Props = {
	paySetting: any,
	onUpdate: ()=>void,
	onCancel: ()=>void,
}
type States = {
	editDantaiId: TextInputState,
	editServiceCode: TextInputState,
	editLabel: TextInputState,
	editDesc: TextInputState,
	editOrder: TextInputState,
	editPrice: TextInputState,
	editLicense: TextInputState,
}
export default class PaySettingEdit extends React.Component<Props, States> {
	state = {
		editDantaiId: new TextInputState(),
		editServiceCode: new TextInputState({validations:"required"}),
		editLabel: new TextInputState({validations:"required"}),
		editDesc: new TextInputState({validations:"required"}),
		editOrder: new TextInputState({validations:"required,integer"}),
		editPrice: new TextInputState({validations:"required,integer"}),
		editLicense: new TextInputState({validations:"required"}),
	}

	componentWillMount(): void {
		const {paySetting} = this.props
		const _paySetting: any = (paySetting) ? paySetting : {
			dantai_id: "",
			service: "",
			label: "",
			desc: "",
			order: 1,
			price: 0,
			license: "0",
		}
		this.setState({
			editDantaiId: this.state.editDantaiId.setValue(_paySetting.dantai_id),
			editServiceCode: this.state.editServiceCode.setValue(_paySetting.service),
			editLabel: this.state.editLabel.setValue(_paySetting.label),
			editDesc: this.state.editDesc.setValue(_paySetting.desc),
			editOrder: this.state.editOrder.setValue(_paySetting.order),
			editPrice: this.state.editPrice.setValue(_paySetting.price),
			editLicense: this.state.editLicense.setValue(_paySetting.license),
		})
	}

	handleSave = () => {
		const license_list = ["01","02","012","1","2","999"]
		this.setState(FormState.validate(this.state), () => {
			if (FormState.isValid(this.state)) {
				const {editDantaiId, editServiceCode, editLabel, editDesc, editOrder, editPrice, editLicense} = this.state
				const license = editLicense.getValue()
				if (license_list.indexOf(license) === -1) {
					alert("ライセンスコードが間違っています")
				} else {
					if (window.confirm("保存してよろしいですか？")) {
						const {paySetting} = this.props
						const uuid = (paySetting) ? paySetting.uuid : "NEW"
						const dantai_id = editDantaiId.getValue().trim()
						const user_type = (dantai_id.length === 0) ? "general" : "d"+dantai_id
						const pay_setting = {
							uuid,
							user_type,
							service: editServiceCode.getValue(),
							label: editLabel.getValue(),
							desc: editDesc.getValue(),
							order: editOrder.getValue(),
							price: editPrice.getValue(),
							license,
						}
						post("admin/update_pay_setting", {
							pay_setting,
						})
						.then(() => {
							this.props.onUpdate()
						})
						.catch(() => {
							alert("エラーが発生しました。１つの団体に対して同じサービスコードが複数ある可能性があります")
						})
					}
				}
			}
		})
	}

	handleCancel = () => {
		this.props.onCancel()
	}

	render() {
		const {editDantaiId, editServiceCode, editLabel, editDesc, editOrder, editPrice, editLicense} = this.state
		return (
			<div style={{padding: 20}}>
				<h3>編集：課金設定</h3>
				<Grid container direction="row">
					<Grid item xs={6}>
						<SlTextField
							label="団体ID"
							state={editDantaiId}
							onChange={(newState: TextInputState) => {
								this.setState({editDantaiId: newState})
							}}
							placeholder="例：1234"
						/>
					</Grid>
					<Grid item xs={6}>
						<SlTextField
							label="サービスコード"
							state={editServiceCode}
							onChange={(newState: TextInputState) => {
								this.setState({editServiceCode: newState})
							}}
							placeholder="例：seishin"
						/>
					</Grid>
					<Grid item xs={12}>
						<SlTextField
							label="サービス名"
							state={editLabel}
							onChange={(newState: TextInputState) => {
								this.setState({editLabel: newState})
							}}
							placeholder="例：社会福祉士合格サポート	"
						/>
					</Grid>
					<Grid item xs={12}>
						<SlTextField
							label="説明文"
							multiline={true}
							state={editDesc}
							onChange={(newState: TextInputState) => {
								this.setState({editDesc: newState})
							}}
							placeholder="例：過去問トレーニング2018年4月以降ご利用開始日から2019年合格発表までWeb模擬試験１回分受験料を含む"
						/>
					</Grid>
					<Grid item xs={4}>
						<SlTextField
							label="表示順"
							state={editOrder}
							onChange={(newState: TextInputState) => {
								this.setState({editOrder: newState})
							}}
							placeholder="値が小さい順に表示される"
						/>
					</Grid>
					<Grid item xs={4}>
						<SlTextField
							label="価格"
							state={editPrice}
							onChange={(newState: TextInputState) => {
								this.setState({editPrice: newState})
							}}
							placeholder="例：6000"
						/>
					</Grid>
					<Grid item xs={4}>
						<SlTextField
							label="付与されるライセンスコード（999 = 付与しない）"
							state={editLicense}
							onChange={(newState: TextInputState) => {
								this.setState({editLicense: newState})
							}}
							placeholder="01, 02, 012, 1, 2 のうちのいずれか"
						/>
					</Grid>
				</Grid>
				<div style={{marginTop:30}}>
					<Button
						variant="contained"
						onClick={this.handleSave}
						color="primary"
					>
						保存
					</Button>
					<Button
						variant="contained"
						onClick={this.handleCancel}
						style={{marginLeft:18}}
					>
						キャンセル
					</Button>
				</div>
				<div style={{marginTop:30, padding:10}} className="alert-info">
					デフォルトの設定は、団体IDを空欄、サービスコードに "general" と入力してください
				</div>
			</div>
		)
	}
}

