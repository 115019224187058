// @flow
import React from 'react'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import { grey } from '@material-ui/core/colors';
import Moment from 'moment'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import PaySettingEdit from './PaySettingEdit'
import Cookies from "js-cookie";

type Props = {}
type States = {
	editingPaySettingId: string,
	settings: Array<any>,
}
export default class PaySetting extends React.Component<Props, States> {
	state = {
		editingPaySettingId: "",
		settings: [],
	}

	componentWillMount(): void {
		this.fetch()
	}

	fetch(): void {
		post("admin/fetch_pay_settings", {})
		.then((ret:any) => {
			this.setState({
				settings: ret.settings,
			})
		})
	}

	handleEdit = (uuid:string) => () => {
		this.setState({
			editingPaySettingId: uuid,
		})
		window.scrollTo(0,0)
	}

	handleDelete = (uuid: string) => () => {
		if (window.confirm("削除してもよろしいですか？（元に戻せません）")) {
			post("admin/delete_pay_setting", {
				user_token: Cookies.get("sl_users"),
				pay_setting_id: uuid,
			})
			.then(() => {
				alert("削除しました")
				this.fetch()
			})
		}
	}

	render() {
		if (!this.state.editingPaySettingId.length) return this.renderList()
		else return this.renderEdit()
	}

	renderList() {
		const styles = {
			tableHeader: {
				fontSize: 12,
				backgroundColor: grey[200],
			},
		}
		const {settings} = this.state
		const _settings = _.orderBy(settings, ["user_type","order"],["asc","asc"])
		const list = _settings.map((row) => (
			<tr key={row.uuid}>
				<td>{row.dantai_id}</td>
				<td>{row.dantai_name}</td>
				<td>{row.service}</td>
				<td>{row.label}</td>
				<td>{Moment(row.updated).format("YYYY/MM/DD HH:mm:ss")}</td>
				<td style={{whiteSpace:'nowrap'}}>
					<BsButton
						color="info"
						onClick={this.handleEdit(row.uuid)}
					>
						編集
					</BsButton>
					<BsButton
						color="danger"
						onClick={this.handleDelete(row.uuid)}
						style={{marginLeft:2}}
					>
						削除
					</BsButton>
				</td>
			</tr>
		))
		return (
			<div>
				<table className="table table-hover table-striped table-bordered" style={{marginTop: 6,fontSize: 12,borderBottom: '1px #ccc solid'}}>
					<tbody>
						<tr style={styles.tableHeader}>
							<th style={{textAlign:'center'}}>団体ID</th>
							<th style={{textAlign:'center'}}>団体名</th>
							<th style={{textAlign:'center'}}>サービスコード</th>
							<th style={{textAlign:'center'}}>サービス名</th>
							<th style={{textAlign:'center'}}>編集日時</th>
							<th style={{textAlign:'center'}}></th>
						</tr>
						{list}
					</tbody>
				</table>
				<BsButton
					color="primary"
					onClick={this.handleEdit("NEW")}
					style={{margin:10}}
				>
					追加
				</BsButton>
			</div>
		)
	}

	handleCancelEditing = () => {
		this.setState({
			editingPaySettingId: "",
		})
	}

	handleUpdate = () => {
		this.setState({
			editingPaySettingId: "",
		}, () => {
			this.fetch()
		})
	}

	renderEdit() {
		const {settings, editingPaySettingId} = this.state
		const paySetting = _.find(settings, {uuid: editingPaySettingId})
		return (
			<PaySettingEdit
				paySetting={paySetting}
				onUpdate={this.handleUpdate}
				onCancel={this.handleCancelEditing}
			/>
		)
	}
}
