import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {post} from "../sonodalib/sl_utils/Http";
// import {altName} from '../alt/constants'


class SideBar extends React.Component {
  handleSuperUserTest = () => {
		alert("START")
		post("admin/super_user_test", {})
		.then(()=>{
      alert("DONE")
		})
  }

  render() {
    const {currService} = this.props
    const styles = {
      treeViewMenu: {
        marginLeft: 18,
      }
    }
    // const menuOfGroups = (altName === "kaigo-admin") ? null : (
    const menuOfGroups = (
      <li className="treeview pinned">
        <span>
          <i className="fa fa-group"></i> <span>グループ</span>
        </span>
        <ul className="treeview-menu" style={styles.treeViewMenu}>
          <li><Link to="/my/group/list"> リスト</Link></li>
          <li><Link to="/my/group/add"> 一括登録</Link></li>
          <li><Link to="/my/group/dantai"> 団体設定</Link></li>
        </ul>
      </li>
    )

    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu">
              <li className="header">管理メニュー</li>
              <li className="treeview">
                <Link to="/my">
                  <i className="fa fa-dashboard"></i> <span>HOME</span>
                </Link>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-user"></i> <span>ユーザ</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/user/search"> 検索</Link></li>
                  <li><Link to="/my/user/add"> 一括登録</Link></li>
                </ul>
              </li>
              {menuOfGroups}
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-comments"></i> <span>掲示板管理</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/bbs/board1-list"> 学習掲示板１</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/bbs/board2-list"> 学習掲示板２</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/bbs/board3-list"> 交流掲示板</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/bbs/board4-list"> よくある質問</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-fax"></i> <span>お問い合わせ</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/bbs/thread-list/9"> スレッド管理</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-edit"></i> <span>模試設定</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/moshi/dantai-on-off"> Web模試設定</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/moshi/webjidousaiten-setting"> Web自動採点設定</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/moshi/link-setting"> オリジナル模試設定</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/moshi/sct-setting"> セルフチェックテスト設定</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-edit"></i> <span>模試統合・セルフチェックテスト</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/wm/service-list"> サービスリスト</Link></li>
                </ul>
                {(!currService) ? null : (
                  <React.Fragment>
                    <ul className="treeview-menu" style={styles.treeViewMenu}>
                      <li><Link to="/my/wm/subjects"> 科目の管理</Link></li>
                    </ul>
                    <ul className="treeview-menu" style={styles.treeViewMenu}>
                      <li><Link to="/my/wm/mondai-add"> 問題の一括追加</Link></li>
                    </ul>
                    <ul className="treeview-menu" style={styles.treeViewMenu}>
                      <li><Link to="/my/wm/mondai"> 問題の編集</Link></li>
                    </ul>
                    <ul className="treeview-menu" style={styles.treeViewMenu}>
                      <li><Link to="/my/wm/saiten"> 採点データの管理</Link></li>
                    </ul>
                    {(currService.stype !== "selfchecktest") ? (
                      <ul className="treeview-menu" style={styles.treeViewMenu}>
                        <li><Link to="/my/wm/shukei"> 集計処理の管理</Link></li>
                      </ul>
                    ) : null}
                    <ul className="treeview-menu" style={styles.treeViewMenu}>
                      <li><Link to="/my/wm/uploader"> アップローダー</Link></li>
                    </ul>
                    {(currService.stype !== "selfchecktest") ? (
                      <>
                        <ul className="treeview-menu" style={styles.treeViewMenu}>
                          <li><Link to="/my/wm/info"> インフォメーション</Link></li>
                        </ul>
                        <ul className="treeview-menu" style={styles.treeViewMenu}>
                          <li><Link to="/my/wm/kohyo"> 講評</Link></li>
                        </ul>
                        <ul className="treeview-menu" style={styles.treeViewMenu}>
                          <li><Link to="/my/wm/hantei_th"> 判定のしきい値</Link></li>
                        </ul>
                      </>
                    ) : (
                      <ul className="treeview-menu" style={styles.treeViewMenu}>
                        <li><Link to="/my/wm/sct_etc"> 学習最終日の設定</Link></li>
                      </ul>
                    )}
                  </React.Fragment>
                )}
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/wm/tasks"> 採点・集計処理</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-folder-open"></i> <span>コンテンツ</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/edit-mondai"> 選択問題データ修正</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/edit-sentakushi"> 選択肢データ修正</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/edit-marubatsu"> ◯✕問題データ修正</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/edit-jireibun"> 事例文データ修正</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/edit-jireimondai"> 事例問題データ修正</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/content/how-to-use-image"> 画像を貼る方法</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-envelope"></i> <span>自動送信メール設定</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/email-template/mail-address-confirmation"> 確認コード送信</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/email-template/change-license"> 申込手続き完了</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/email-template/notify-bbs-reply"> 掲示板返信通知</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/email-template/notify-contact"> 管理者への問い合わせ通知</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-television"></i> <span>表示設定</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/view-settings/schedule"> 試験日時</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/view-settings/information"> インフォメーション</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/view-settings/logininfo"> ログインページの情報欄</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <i className="fa fa-yen"></i> <span>課金管理</span>
                </span>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/pay/pay-setting"> 課金設定</Link></li>
                </ul>
                <ul className="treeview-menu" style={styles.treeViewMenu}>
                  <li><Link to="/my/pay/order-manage"> 注文検索</Link></li>
                </ul>
              </li>
              <li className="treeview pinned">
                <span>
                  <Link to="/my/etc-settings">
                    <i className="fa fa-gear"></i> <span>その他の設定</span>
                  </Link>
                </span>
              </li>
              <li className="treeview pinned" style={{marginTop:200}}>
                <span>
                  <a onClick={this.handleSuperUserTest}>
                    super user test
                  </a>
                </span>
              </li>
            </ul>
          </section>
        </aside>
      </div>
    )
  }
}

export default connect(
  state => ({
    currService:   state.mypage.currService,
  }),
  dispatch => ({
  })
)(withRouter(SideBar))
