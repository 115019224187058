import React from 'react'
import PropTypes from 'prop-types'

export default class ContentHeader extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		subTitle: PropTypes.string.isRequired,
	}

	render() {
		const { title, subTitle } = this.props
		return (
			<section className="content-header">
				<h1>
					{title}
					<small>{subTitle}</small>
				</h1>
				<ol className="breadcrumb">
					<li><a><i className="fa fa-dashboard" /> Home</a></li>
					<li className="active">{title}</li>
				</ol>
			</section>
		)
	}
}

