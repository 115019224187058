import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {post} from "../../sonodalib/sl_utils/Http";
import VerticalCalendar from "./VerticalCalendar";


export default class MyPagePreview extends React.Component {
	state = {
		infoTexts: [],
		moshi_link_list: null,
	}

	componentWillMount() {
		const props = this.props
		if (!(props && props.user_id))
			return
		post("admin/fetch_mypage_preview", {
			user_id: props.user_id,
		})
			.then(ret => {
				this.setState({
					infoTexts: ret.info_texts,
					moshi_link_list: ret.moshi_link_list,
				})
			})
	}

	// ===============================================================
	// 模試リンク
	// ===============================================================
	buildMoshiLink = () => {
		const {moshi_link_list} = this.state
		return moshi_link_list.map((moshiLink, index) => {
			const onClick = () => {
				if (moshiLink.err_desc) {
					alert(moshiLink.err_desc)
				} else {
					alert(moshiLink.service + "が実行されます")
				}
			}
			switch (moshiLink.type) {
				case "webmoshi":
					return (
						<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-purple" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
							<div className="inner" style={{textAlign: 'left',height:79}}>
								<span style={{fontSize: 20}}>Web模擬試験</span>
								<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
							</div>
							<div className="icon">
								<i className="far fa-edit"></i>
							</div>
							<span className="small-box-footer"
										style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
						</div>
					)
				case "webjidousaiten":
					return (
						<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-red" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
							<div className="inner" style={{textAlign: 'left',height:79}}>
								<span style={{fontSize: 20}}>Web自動採点</span>
								<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
							</div>
							<div className="icon">
								<i className="far fa-edit"></i>
							</div>
							<span className="small-box-footer"
										style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
						</div>
					)
				case "originalmoshi":
					return (
						<div key={"moshi-link"+index} onClick={onClick} className="small-box bg-aqua" style={{marginBottom: 8, cursor: 'pointer',height:105}}>
							<div className="inner" style={{textAlign: 'left',height:79}}>
								<span style={{fontSize: 20}}>Web模擬試験</span>
								<p><span style={{fontSize: 14}}>{moshiLink.desc}</span></p>
							</div>
							<div className="icon">
								<i className="far fa-edit"></i>
							</div>
							<span className="small-box-footer"
										style={{cursor: 'pointer'}}>サイトに移動 <i className="fas fa-arrow-circle-right"></i></span>
						</div>
					)
				default:
					return null
			}
		})
	}

	render() {
		const {infoTexts, moshi_link_list} = this.state
		const vd = infoTexts.map((row: any, index: number) => {
			return (
				<div key={index}>
					<Grid container direction="row">
						<Grid item xs={12}>
							<div className={`box box-${row.subject_color} box-solid`}>
								<div className="box-header with-border">
									<h3 className="box-title">{row.subject}</h3>
								</div>
								<div className="box-body" dangerouslySetInnerHTML={{__html: row.body.replace(/\n/g, "<br />")}}></div>
							</div>
						</Grid>
					</Grid>
				</div>
			)
		})
		return (
			<div style={{marginTop: 18}}>
				<Paper style={{padding:10}}>
					<div style={{fontWeight:600,padding:10}}>インフォメーション</div>
					{vd}
				</Paper>
				<Paper style={{padding:10, marginTop:20}}>
					<div style={{fontWeight:600,padding:10}}>カレンダー</div>
					<div style={{width:400,border:'2px solid #CCC',padding:10}}>
						<VerticalCalendar
							user_id={this.props.user_id}
							year={this.props.calendarYear}
							month={this.props.calendarMonth}
							onChange={this.props.onChange}
						/>
					</div>
				</Paper>
				<Paper style={{padding:10, marginTop:20}}>
					<div style={{fontWeight:600,padding:10}}>Web模試リンク</div>
					<div style={{width:400,border:'2px solid #CCC',padding:10}}>
						{(moshi_link_list) ? this.buildMoshiLink() : null}
					</div>
				</Paper>
			</div>
		)
	}
}