import React from 'react';
import { connect } from 'react-redux'
import ContentHeader from './ContentHeader'


class DashBoard extends React.Component {
  render() {
    return (
			<div>
				<ContentHeader
					title="管理ページ"
					subTitle="Admin home"
				/>
				<section className="content">
					左のメニューをクリックしてください
				</section>
			</div>
    );
  }
}

export default connect(
  state => ({
  }),
  dispatch => ({
  })
)(DashBoard);
