// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import ThemeDefault from './theme-default';
import {red, deepOrange, blueGrey} from '@material-ui/core/colors'
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import Routes from './routes'
import LoadingOverlay from './sonodalib/sl_utils/components/LoadingOverlay';
import GenericModal from './sonodalib/sl_utils/components/GenericModal';
import SlSnackbar from './sonodalib/sl_utils/components/Snackbar';
import G from './sonodalib/SlGlobal'
import {dbName} from './alt/constants'
// import injectTapEventPlugin from 'react-tap-event-plugin';
// import Global from './global'

const store = createStore(
  reducers,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
// Global.create(store);
G.store = store
G.apiPath = "/f/fkapi/"
G.apiDbName = dbName


const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
		accent: blueGrey,
    error: red,
  },
  typography: {
    htmlFontSize: 10,
  },
  // spacing: 4,
  // props: {
	// 	MuiAvatar: {
	// 		margin: 16,
	// 	}
  // }
});

// injectTapEventPlugin();

const styles = {
  viewRect: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    backgroundColor: '#FFF'
  },
  background: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FBE9E7'
  }
}

const root:?Element = document.getElementById('root')
if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div style={styles.background}>
          <div style={styles.viewRect}>
            <LoadingOverlay/>
            <SlSnackbar/>
            <GenericModal/>
            <Routes/>
          </div>
        </div>
      </ThemeProvider>
    </Provider>
    , root
  );
}
