// @flow

import * as Action from './MyPageAction';
import type {UserSearchResultState} from "./MyPageAction";
import {defaultUserSearchResult} from './MyPageAction'
import type {WmService, WmServiceType} from '../shared/ServerInterface'
import {SelectorInputState} from "../sonodalib/sl_utils/SlForms";


type MyPageReducer = {
  groupList:      any,
  allGroups:      any,
  userSearchResultState: UserSearchResultState,
  wmServiceList:  Array<WmService>,
  currService?:   WmService,
  wmServiceTypes: Array<WmServiceType>,
  subjectMenu?:   SelectorInputState,
  mondaiTsv:      string,
  hanteiThList: Array<any>,
  kohyo_texts_shikens:    Array<any>,
  kohyo_texts_categories: Array<any>,
  kohyo_texts_subjects:	  Array<any>,
  info:           string,
  subjectMenuDlSaiten?:     SelectorInputState,
  subjectMenuDeleteSaiten?: SelectorInputState,
  shukei_etc: any,
  categoriesToIgnoreCheckBoxes: Array<{label:string,value:boolean}>,
}
const initialState: MyPageReducer = {
  groupList:      new Action.GroupList(),
  allGroups:      new Action.AllGroups(),
  userSearchResultState: defaultUserSearchResult,
  wmServiceList:  undefined,
  currService:    undefined,
  wmServiceTypes: [],
  subjectMenu:    undefined,
  subjectMenuDlSaiten:    undefined,
  subjectMenuDeleteSaiten:undefined,
  mondaiTsv:      "",
  hanteiThList:   [],
  kohyo_texts_shikens: [],
  kohyo_texts_categories: [],
  kohyo_texts_subjects: [],
  info:           "",
  shukei_etc:     undefined,
  categoriesToIgnoreCheckBoxes: [],
};

export default function myPageReducer(state:MyPageReducer = initialState, action: any) {
	const p = action.payload
  switch (action.type) {
    case Action.UPDATE_GROUP_LIST:
      return {...state, groupList: state.groupList.merge(p)}
    // case Action.UPDATE_EMAIL_TEMPLATE_LIST:
    //   return {...state, emailTemplateList: state.emailTemplateList.merge(p)}
    case Action.UPDATE_ALL_GROUPS:
      return {...state, allGroups: state.allGroups.merge(p)}
    case Action.UPDATE_USERSEARCHRESULT:
      return {...state, userSearchResultState: p}
    case Action.UPDATE_WM_SERVICES:
      return {...state, wmServiceList: p}
    case Action.UPDATE_WM_SERVICE_TYPES:
      return {...state, wmServiceTypes: p}
    case Action.UPDATE_WM_CURR_SERVICE:
      return {...state, currService: p}
    case Action.UPDATE_WM_SUBJECT_MENU:
      return {...state, subjectMenu: p}
    case Action.UPDATE_WM_SUBJECT_MENU_DL_SAITEN:
      return {...state, subjectMenuDlSaiten: p}
    case Action.UPDATE_WM_SUBJECT_MENU_DEL_SAITEN:
      return {...state, subjectMenuDeleteSaiten: p}
    case Action.UPDATE_WM_MONDAI_TSV:
      return {...state, mondaiTsv: p}
    case Action.UPDATE_WM_HANTEITH:
      return {...state, hanteiThList: p}
    case Action.UPDATE_WM_KOHYO_SHIKENS:
      return {...state, kohyo_texts_shikens: p}
    case Action.UPDATE_WM_KOHYO_CATEGORIES:
      return {...state, kohyo_texts_categories: p}
    case Action.UPDATE_WM_KOHYO_SUBJECTS:
      return {...state, kohyo_texts_subjects: p}
    case Action.UPDATE_WM_INFO:
      return {...state, info: p}
    case Action.UPDATE_WM_SHUKEI_ETC:
      return {...state, shukei_etc: p}
    case Action.UPDATE_WM_CATEGORIESTOIGNORECHECKBOXES:
      return {...state, categoriesToIgnoreCheckBoxes: p}
    default:
      return state
  }
}
