import React from 'react';
import {connect} from 'react-redux'
import {licenseList} from './constants'
import TextInput from '../sonodalib/sl_adminlte/TextInput'
import ContentHeader from '../mypage/ContentHeader'
import {TextInputState} from '../sonodalib/sl_utils/SlForms'
import SlTextField from '../sonodalib/sl_mui/SlTextField'
import Grid from '@material-ui/core/Grid'
import {blue} from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import BsButton from '../sonodalib/sl_adminlte/BsButton'
import {post} from '../sonodalib/sl_utils/Http'
import {normalizeRow} from '../sonodalib/sl_utils/Utils'
import Selector from '../sonodalib/sl_adminlte/Selector'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import {fetchGroupList} from '../common/Common'
import {saveAs} from 'file-saver'
import {getPrefectures} from '../sonodalib/sl_utils/Address'
import UserDetail from '../mypage/user/UserDetail'
import G from "../sonodalib/SlGlobal";
import {loadingVisibilityAC} from "../sonodalib/sl_utils/SlUtilsAction";
import Checkbox from '@material-ui/core/Checkbox';
import {updateUserSearchResult} from '../mypage/MyPageAction'
import Cookies from "js-cookie";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';


const sortIdList = [
	{id: "none", label: "無指定"},
	{id: "asc", label: "小さい順"},
	{id: "desc", label: "大きい順"},
]
const sortActivatedList = [
	{id: "none", label: "無指定"},
	{id: "asc", label: "古い順"},
	{id: "desc", label: "新しい順"},
]


class SelectDantaiDialog extends React.Component {
	state = {
		group_list: null,
		selected_group_uuid: null,
	}

	componentWillMount(): void {
		this.updateProps(this.props)
	}

	componentWillReceiveProps(nextProps, nextContext): void {
		this.updateProps(nextProps)
	}

	updateProps = (newProps) => {
		const {selectedDantaiToMove} = newProps
		this.update(selectedDantaiToMove)
	}

	update = (new_dantai_id) => {
		switch (new_dantai_id) {
			case "none":
				this.setState({
					dantaiId: new_dantai_id,
					group_list: [
						{uuid:'no_group', name:'所属なし',layer:10,id:"なし",parent:null}
					],
					selected_group_uuid: null,
				})
				break
			default:
				post("admin/fetch_group_list", {dantai_id: new_dantai_id})
				.then(ret => {
					ret = normalizeRow(ret)
					this.setState({
						group_list: ret.groupList,
						selected_group_uuid: null,
					})
				})
		}
	}

	handleChangeDantai = (value) => {
		this.props.onChange(value)
	}

	createGroupList(groupList) {
		const {dantaiList, selectedDantaiToMove} = this.props
		const a0 = _.find(dantaiList, {uuid:selectedDantaiToMove})
		const list = [{
			name: `${a0.name}(${a0.id})`,
			layer: 10,
			uuid: a0.uuid,
			parent: null,
		}]

		let a1 = _.filter(groupList, {layer: 20})
		a1 = _.sortBy(a1, data => (data.id))
		_.forEach(a1, group1 => {
			const row = {
				name: `${group1.name}(${group1.id})`,
				layer: group1.layer,
				uuid: group1.uuid,
				parent: group1.parent,
			}
			list.push(row)

			let a2 = _.filter(groupList, {layer: 30, parent: group1.uuid})
			a2 = _.sortBy(a2, data => (data.id))
			_.forEach(a2, group2 => {
				const row = {
					name: `${group2.name}(${group2.id})`,
					layer: group2.layer,
					uuid: group2.uuid,
					parent: group2.parent,
				}
				list.push(row)
			})
		})
		return list
	}

	render() {
		const {dantaiList, selectedDantaiToMove, onCancel, onAccept} = this.props
		const {group_list, selected_group_uuid} = this.state
		const group_list_vd = (group_list == null) ? null : (
			<form>
				<div className="form-group">
					{this.createGroupList(group_list).map((row) => (
						<div className="radio" key={row.uuid} style={{paddingLeft:(row.layer-10)*3}}>
							<label>
								<input
									type="radio"
									name="group_radio"
									value={row.uuid}
									checked={selected_group_uuid === row.uuid}
									onChange={() => {
										this.setState({
											selected_group_uuid: row.uuid,
										})
									}}
								/>
								{row.name}
							</label>
						</div>
					))}
				</div>
			</form>
		)
		return (
			<Dialog open={true}>
				<DialogTitle>
					団体を選択
				</DialogTitle>
				<DialogContent>
					<Selector
						className="form-control"
						value={selectedDantaiToMove}
						onChange={this.handleChangeDantai}
					>
						{dantaiList.map((row) => (
							<option key={row.uuid} value={row.uuid}>{row.name}</option>
						))}
					</Selector>
					{group_list_vd}
				</DialogContent>
				<DialogActions>
					<Button
						// variant="contained"
						// color="primary"
						onClick={onCancel}
					>
						キャンセル
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							if (selected_group_uuid === null) {
								alert("グループを選択してください")
							} else {
								if (window.confirm("所属を変更しても良いですか？（元に戻せません）")) {
									onAccept(selected_group_uuid)
								}
							}
						}}
					>
						変更
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


class UserSearch extends React.Component {
	state = {
		keyword: new TextInputState(),
		uniqueId: new TextInputState(),
		license: "all",
		// limited: "all",
		dantaiList: null,
		dantaiId: "all",
		groupList: null,
		group1: "all",
		group2: "all",
		sortId: "none",
		sortActivated: "none",

		// SearchResult
		// result: null,
		currentPage: 1,
		totalPage: 0,
		totalItems: 0,
		showDetail: false,
		showDetailUserId: "",
		csvDlAnchorEl: null,
		infoString: "",
		showSelectDantaiDialog: false,
		selectedDantaiToMove: "none",
	}

	componentWillMount() {
		const self = this
		post("admin/fetch_dantai_list", {})
		.then(ret => {
			ret = normalizeRow(ret)
			self.setState({
				dantaiList: ret.dantaiList,
			})
			// if (ret.dantaiList.length) {
			// 	let selectedDantaiId = G.store.getState().mypage.groupList.get("selectedDantaiId")
			// 	if (selectedDantaiId === null || !ret.dantaiList.selectedDantaiId) {
			// 		selectedDantaiId = ret.dantaiList[0].uuid
			// 	}
			// 	self.fetchGroupList(selectedDantaiId)
			// }
		})
	}

	// handleKeywordChange = (event, value) => {
	// 	this.setState({
	// 		keyword: value,
	// 	})
	// }

	handleChangeDantai = (value) => {
		switch (value) {
			case "all":
			case "none":
				this.setState({
					dantaiId: value,
				})
				break
			default:
				fetchGroupList(value)
				.then((ret) => {
					this.setState({
						dantaiId: value,
						group1: "all",
						group2: "all",
						groupList: ret.groupList,
					})
				})
		}
	}

	handleChangeGroup1 = (value) => {
		this.setState({
			group1: value,
			group2: "all",
		})
	}

	handleChangeGroup2 = (value) => {
		this.setState({
			group2: value,
		})
	}

	createSearchApiPayload = () => {
		// const limited = (this.state.limited === 0) ? "all" : (this.state.limited === 2) ? 1 : 0
		let layer = 0
		let groupId = null
		if (this.state.dantaiId === "all") {
			groupId = "__ALL#"
		} else if (this.state.dantaiId === "none") {
			groupId = "__NONE#"
		} else {
			if (this.state.group2 !== "all") {
				layer = 30
				groupId = this.state.group2
			} else if (this.state.group1 !== "all") {
				layer = 20
				groupId = this.state.group1
			} else if (this.state.dantaiId !== "all" && this.state.dantaiId !== "none") {
				layer = 10
				groupId = this.state.dantaiId
			}
		}
		return {
			keyword: this.state.keyword.getValue(),
			unique_id: this.state.uniqueId.getValue(),
			license: this.state.license,
			// limited,
			layer,
			group_id: groupId,
			sort: this.state.sortId + "," + this.state.sortActivated,
			page: this.state.currentPage,
		}
	}

	fetchFirst = () => {
		const dispatch = G.store.dispatch
		dispatch(loadingVisibilityAC(true))
		post("admin/fetch_users_first", this.createSearchApiPayload())
		.then(ret => {
			this.updateResult(ret.list)
			this.setState({
				// currentPage: 1,
				totalPage: ret.total_pages,
				totalItems: ret.total_items,
				showDetail: false,
			})
		})
		.catch(ret => {
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	fetchChangePage = () => {
		const dispatch = G.store.dispatch
		dispatch(loadingVisibilityAC(true))
		post("admin/fetch_users_change_page", this.createSearchApiPayload())
		.then(ret => {
			this.updateResult(ret.list)
			this.setState({
				// currentPage: 1,
				showDetail: false,
			})
		})
		.catch(ret => {
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	handleSearch = (event) => {
		if (event) event.preventDefault()
		this.setState({
			currentPage: 1,
			showDetail: false,
		}, () => {
			this.fetchFirst()
		})
	}

	handleOpenCsvDownloadMenu = (event) => {
		this.setState({csvDlAnchorEl: event.currentTarget});
	}

	handleCloseCsvDlMenu = (outputOxStatus) => {
		this.setState({csvDlAnchorEl: null});
		if (outputOxStatus === null) return
		this.downloadCsv(outputOxStatus)
	}

	downloadCsv = (outputOxStatus) => {
		const dispatch = G.store.dispatch
		dispatch(loadingVisibilityAC(true))
		post("admin/fetch_users_all", this.createSearchApiPayload())
		.then(ret => {
			const csvs = ret.list.map((row) => {
				const kyotsuJisshido = row.sentaku_kyotsu_jisshido || "ー"
				const kyotsuShujukudo = row.sentaku_kyotsu_shujukudo || "ー"
				const shakaiJisshido = row.sentaku_shakai_jisshido || "ー"
				const shakaiShujukudo = row.sentaku_shakai_shujukudo || "ー"
				const seishinJisshido = row.sentaku_seishin_jisshido || "ー"
				const seishinShujukudo = row.sentaku_seishin_shujukudo || "ー"
				const oxKyotsuJisshido = row.ox_kyotsu_jisshido || "ー"
				const oxKyotsuShujukudo = row.ox_kyotsu_shujukudo || "ー"
				const oxShakaiJisshido = row.ox_shakai_jisshido || "ー"
				const oxShakaiShujukudo = row.ox_shakai_shujukudo || "ー"
				const oxSeishinJisshido = row.ox_seishin_jisshido || "ー"
				const oxSeishinShujukudo = row.ox_seishin_shujukudo || "ー"
				const gakushuDays = row.gakushu_days || 0
				const gakushuLastDate = row.gakushu_last_date || "ー"
				const name = (row.name) ? row.name : ""
				const kana = (row.kana) ? row.kana : ""
				const email = (row.email) ? row.email : ""
				const inviteCode = (row.invite_code) ? row.invite_code : ""
				const studentId = (row.student_id) ? row.student_id : ""
				const zipCode = (row.zipcode) ? row.zipcode : ""
				const addressCity = (row.address_city) ? row.address_city : ""
				const telNo = (row.telno) ? row.telno : ""
				return (outputOxStatus) ? (
					`${row.unique_id},\
${name},\
${kana},\
${email},\
${kyotsuJisshido},\
${kyotsuShujukudo},\
${shakaiJisshido},\
${shakaiShujukudo},\
${seishinJisshido},\
${seishinShujukudo},\
${oxKyotsuJisshido},\
${oxKyotsuShujukudo},\
${oxShakaiJisshido},\
${oxShakaiShujukudo},\
${oxSeishinJisshido},\
${oxSeishinShujukudo},\
${gakushuDays},\
${gakushuLastDate},\
${_.find(licenseList, {id: row.license}).label},\
${(row.activated) ? new Date(row.activated).toLocaleDateString() : "未登録"},\
${inviteCode},\
${row.dantai_text},\
${row.group1_text},\
${row.group2_text},\
${studentId},\
${zipCode},\
${(row.address_pref) ? getPrefectures()[row.address_pref].label : ""},\
${addressCity},\
${telNo},\
${row.remark}`
				) : (
					`${row.unique_id},\
${row.name},\
${kana},\
${email},\
${kyotsuJisshido},\
${kyotsuShujukudo},\
${shakaiJisshido},\
${shakaiShujukudo},\
${seishinJisshido},\
${seishinShujukudo},\
${gakushuDays},\
${gakushuLastDate},\
${_.find(licenseList, {id: row.license}).label},\
${(row.activated) ? new Date(row.activated).toLocaleDateString() : "未登録"},\
${inviteCode},\
${row.dantai_text},\
${row.group1_text},\
${row.group2_text},\
${studentId},\
${zipCode},\
${(row.address_pref) ? getPrefectures()[row.address_pref].label : ""},\
${addressCity},\
${telNo},\
${row.remark}`
				)
			})
			if (outputOxStatus) {
				csvs.unshift("利用者ID,氏名,かな,メールアドレス,選択・共通・実施度,選択・共通・習熟度,選択・社会専門・実施度,選択・社会専門・習熟度,選択・精神専門・実施度,選択・精神専門・習熟度,ＯＸ・共通・実施度,ＯＸ・共通・習熟度,ＯＸ・社会専門・実施度,ＯＸ・社会専門・習熟度,ＯＸ・精神専門・実施度,ＯＸ・精神専門・習熟度,学習日数,最終学習日,利用資格,登録日,招待コード,団体,グループ1,グループ2,学籍番号,郵便番号,都道府県,住所,電話番号,備考")
			} else {
				csvs.unshift("利用者ID,氏名,かな,メールアドレス,選択・共通・実施度,選択・共通・習熟度,選択・社会専門・実施度,選択・社会専門・習熟度,選択・精神専門・実施度,選択・精神専門・習熟度,学習日数,最終学習日,利用資格,登録日,招待コード,団体,グループ1,グループ2,学籍番号,郵便番号,都道府県,住所,電話番号,備考")
			}
			const csv = csvs.join("\n")
			const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
			const blob = new Blob([bom, csv], {"type": "text/csv"});
			saveAs(blob, "ユーザ情報.csv")
		})
		.catch(ret => {
			alert("通信エラー")
		})
		.finally(() => {
			dispatch(loadingVisibilityAC(false))
		})
	}

	handleChangePage = (value) => {
		this.setState({
			currentPage: value,
			showDetail: false,
		}, () => {
			this.fetchChangePage()
		})
	}

	handleTableRow = (row) => {
		this.setState({
			showDetail: true,
			showDetailUserId: row.uuid,
		})
		// this.props.history.push("/my/user/detail/" + row.uuid)
	}

	handleReset = () => {
		this.setState({
			uniqueId: this.state.uniqueId.setValue(""),
			keyword: this.state.keyword.setValue(""),
			license: "all",
			// limited: "all",
			dantaiId: "all",
			group1: "all",
			group2: "all",
			sortId: "none",
			sortActivated: "none",
		})
	}

	updateResult = (result: Array<any>) => {
		const checkboxes = result.map((row) => (
			{
				checked: false,
				user_id: row.uuid,
				name: row.name,
			}
		))
		updateUserSearchResult({
			result,
			checkboxes,
		})
		// this.props.updateUserSearchResult(this.props.userSearchResult.setResult(result))
	}

	handleCloseDetail = () => {
		this.setState({
			showDetail: false,
		}, () => {
			this.handleSearch(null)
		})
	}

	handleToggleCheckbox = (checkbox) => () => {
		checkbox.checked = !checkbox.checked
		const checkboxes = this.props.userSearchResult.checkboxes
		updateUserSearchResult({
			checkboxes
		})
	}

	_checkAll = (checked) => {
		const checkboxes = this.props.userSearchResult.checkboxes.map((checkbox) => {
			checkbox.checked = checked
			return checkbox
		})
		updateUserSearchResult({
			checkboxes
		})
	}

	handleCheckAll = () => {
		this._checkAll(true)
	}

	handleUncheckAll = () => {
		this._checkAll(false)
	}

	getSelectedUsers = () => {
		return this.props.userSearchResult.checkboxes.filter((checkbox) => (checkbox.checked))
	}

	handleDelete = () => {
		const usersToDelete = this.getSelectedUsers()
		if (!usersToDelete.length) {
			alert("削除するユーザを選択して下さい")
			return
		}
		if (window.confirm(usersToDelete.length + "件のユーザは完全に削除され、元に戻すことはできません。よろしいですか？")) {
			let p = Promise.resolve()
			usersToDelete.forEach((checkbox) => {
				p = p.then((ret) =>
					new Promise((resolve, reject) => {
						this.setState({
							infoString: "削除中... " + checkbox.name,
						})
						post("user/delete_perm", {
							user_token: Cookies.get("sl_users"),
							user_id: checkbox.user_id,
						})
						.then((ret) => {
							resolve(ret)
						})
						.catch((ret) => {
							reject(ret)
						})
					})
				)
			})
			p.then((ret) => {
				alert("削除しました")
				this.handleSearch(null)
			})
			.catch((ret) => {
				alert("不明なエラーが発生しました")
			})
			.finally(() => {
				this.setState({
					infoString: "",
				})
			})
		}
	}

	handleChangeGroup = () => {
		const usersToDelete = this.getSelectedUsers()
		if (!usersToDelete.length) {
			alert("所属を変更するユーザを選択して下さい")
			return
		}
		this.setState({
			showSelectDantaiDialog: true,
		})
	}

	handleChangeDantaiToMove = (new_dantai_id) => {
		this.setState({
			selectedDantaiToMove: new_dantai_id,
		})
	}

	handleCancelDantaiToMove = () => {
		this.setState({
			showSelectDantaiDialog: false,
		})
	}

	handleAcceptChangeGroup = (group_uuid) => {
		const selectedUsers = this.getSelectedUsers()
		let p = Promise.resolve()
		selectedUsers.forEach((checkbox) => {
			p = p.then((ret) =>
				new Promise((resolve, reject) => {
					this.setState({
						infoString: "所属変更中... " + checkbox.name,
					})
					post("user/change_group", {
						user_token: Cookies.get("sl_users"),
						user_id: checkbox.user_id,
						group_uuid,
					})
					.then((ret) => {
						resolve(ret)
					})
					.catch((ret) => {
						reject(ret)
					})
					.finally(() => {
						this.setState({
							showSelectDantaiDialog: false,
						})
					})
				})
			)
		})
		p.then((ret) => {
			alert("変更が完了しました")
			this.handleSearch(null)
		})
		.catch((ret) => {
			alert("不明なエラーが発生しました")
		})
		.finally(() => {
			this.setState({
				infoString: "",
			})
		})
	}

	render() {
		const styles = {
			pagenateButton: {
				marginLeft: 1,
			},
			pageSelector: {
				width: 60,
				height: 30,
				marginRight: 6,
			},
			colTitle: {
				width: 120,
			},
			selector: {
				width: 200,
			},
			center: {
				textAlign: 'center',
				verticalAlign: 'middle',
			},
			right: {
				textAlign: 'right',
			},
		}

		if (!this.state.dantaiList) return null
		let resultVd = null
		const {totalPage, totalItems, currentPage, csvDlAnchorEl, infoString} = this.state
		const {result, checkboxes} = this.props.userSearchResult
		// const {result, totalPage, currentPage} = this.props.storeUserSearch
		if (!this.state.showDetail && result) {
			// const list = _.map(result, _row => {
			const list = result.map(_row => {
				const row = normalizeRow(_row)
				const license = _.find(licenseList, {id: row.license})
				const activated = (row.activated) ? new Date(row.activated).toLocaleDateString() : "未登録"
				const kyotsuJisshido = row.sentakuKyotsuJisshido || "ー"
				const kyotsuShujukudo = row.sentakuKyotsuShujukudo || "ー"
				const shakaiJisshido = row.sentakuShakaiJisshido || "ー"
				const shakaiShujukudo = row.sentakuShakaiShujukudo || "ー"
				const seishinJisshido = row.sentakuSeishinJisshido || "ー"
				const seishinShujukudo = row.sentakuSeishinShujukudo || "ー"
				const gakushuDays = row.gakushuDays || 0
				const gakushuLastDate = row.gakushuLastDate || "ー"
				const checkbox = checkboxes.find((checkbox) => (checkbox.user_id === row.uuid))
				return (
					<tr key={row.uuid} data-key={row.uuid}>
						<td>
							<Checkbox
								checked={checkbox.checked}
								onChange={this.handleToggleCheckbox(checkbox)}
								value="1"
								style={{width: 24, height: 24}}
							/>
						</td>
						<td><a style={{cursor: 'pointer'}} onClick={this.handleTableRow.bind(this, row)}>{row.uniqueId}</a></td>
						<td>{row.name}</td>
						<td style={styles.right}>{kyotsuJisshido}</td>
						<td style={styles.right}>{kyotsuShujukudo}</td>
						<td style={styles.right}>{shakaiJisshido}</td>
						<td style={styles.right}>{shakaiShujukudo}</td>
						<td style={styles.right}>{seishinJisshido}</td>
						<td style={styles.right}>{seishinShujukudo}</td>
						<td style={styles.right}>{gakushuDays}</td>
						<td style={styles.right}>{gakushuLastDate}</td>
						<td style={styles.center}>{license.label}</td>
						<td style={styles.center}>{row.dantaiText + " " + row.group1Text + " " + row.group2Text}</td>
						<td style={styles.right}>{activated}</td>
						<td>{row.inviteCode}</td>
					</tr>
				)
			})
			resultVd = (
				<Grid container direction="column" spacing={4}>
					<Grid item>
						<Grid container direction="row" spacing={4}>
							<Grid item>
								検索結果 {totalItems}件 見つかりました
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									aria-owns={csvDlAnchorEl ? 'simple-menu' : null}
									aria-haspopup="true"
									onClick={this.handleOpenCsvDownloadMenu}
									style={{color: 'white', backgroundColor: blue[500]}}
								>
									CSVダウンロード
								</Button>
								<Menu
									id="csv-dl-menu"
									anchorEl={csvDlAnchorEl}
									open={Boolean(csvDlAnchorEl)}
									onClose={this.handleCloseCsvDlMenu.bind(this, null)}
								>
									<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, true)}>OX実施度・習熟度を含む</MenuItem>
									<MenuItem onClick={this.handleCloseCsvDlMenu.bind(this, false)}>OX実施度・習熟度を含まない</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<div className="box box-primary">
							<table className="table table-striped table-hover">
								<tbody>
								<tr>
									<th rowSpan="2"></th>
									<th style={styles.center} rowSpan="2">利用者ID</th>
									<th style={styles.center} rowSpan="2">氏名</th>
									<th style={styles.center} rowSpan="1" colSpan="2">共通</th>
									<th style={styles.center} rowSpan="1" colSpan="2">社会専門</th>
									<th style={styles.center} rowSpan="1" colSpan="2">精神専門</th>
									<th style={styles.center} rowSpan="2">学習日数</th>
									<th style={styles.center} rowSpan="2">最終学習日</th>
									<th style={styles.center} rowSpan="2">利用資格</th>
									<th style={styles.center} rowSpan="2">所属</th>
									<th style={styles.center} rowSpan="2">登録日</th>
									<th style={styles.center} rowSpan="2">招待コード</th>
								</tr>
								<tr>
									<th style={styles.center}>実施度</th>
									<th style={styles.center}>習熟度</th>
									<th style={styles.center}>実施度</th>
									<th style={styles.center}>習熟度</th>
									<th style={styles.center}>実施度</th>
									<th style={styles.center}>習熟度</th>
								</tr>
								{list}
								</tbody>
							</table>
							<div className="box-footer clearfix">
								<ul className="no-margin pull-left">
									<Button
										variant="contained"
										style={{color: 'white', backgroundColor: blue[400]}}
										onClick={this.handleCheckAll}
									>
										すべて選択
									</Button>
									<Button
										variant="contained"
										style={{marginLeft: 8, color: 'white', backgroundColor: blue[400]}}
										onClick={this.handleUncheckAll}
									>
										選択解除
									</Button>
									<Button
										variant="contained"
										color="secondary"
										style={{marginLeft: 14}}
										onClick={this.handleDelete}
									>
										完全に削除
									</Button>
									<Button
										variant="contained"
										color="primary"
										style={{marginLeft: 14}}
										onClick={this.handleChangeGroup}
									>
										所属変更
									</Button>
								</ul>
								<ul className="no-margin pull-right">
									<div className="form-control">
										全 {totalPage} ページ
									</div>
								</ul>
								<ul className="no-margin pull-right">
									<Selector
										style={styles.pageSelector}
										onChange={this.handleChangePage}
										value={currentPage}
										minNumber={1}
										maxNumber={totalPage}
									>
									</Selector>
								</ul>
							</div>
						</div>
					</Grid>
				</Grid>
			)
		}

		const searchLicenseList = [{id: "all", label: "すべて"}].concat(licenseList)
		// const searchLimitedList = ["すべて"].concat(limitedList)
		const searchDantaiList = [{uuid: "all", id: "all", name: "すべて"}, {
			uuid: "none",
			id: "none",
			name: "なし"
		}].concat(this.state.dantaiList)
		let searchGroup1List = [{uuid: _.uniqueId(), id: "all", name: "すべて"}]
		if (this.state.groupList && this.state.dantaiId !== "all" && this.state.dantaiId !== "none") {
			searchGroup1List = searchGroup1List.concat(
				_.filter(this.state.groupList,
					row => (row.layer === 20 && row.parent === this.state.dantaiId)
				)
			)
		}
		let searchGroup2List = [{uuid: _.uniqueId(), id: "all", name: "すべて"}]
		if (this.state.groupList && this.state.group1 !== "all") {
			searchGroup2List = searchGroup2List.concat(
				_.filter(this.state.groupList,
					row => (row.layer === 30 && row.parent === this.state.group1)
				)
			)
		}

		const userDetail = (this.state.showDetail) ? (
			<UserDetail
				userId={this.state.showDetailUserId}
				onClose={this.handleCloseDetail}
			/>
		) : null

		const formVd = (
			<div style={{margin:16}}>
				<form onSubmit={this.handleSearch}>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<SlTextField
								label="キーワード"
								placeholder="空欄ならすべてが対象"
								state={this.state.keyword}
								onChange={(newState) => {
									this.setState({keyword: newState})
								}}
							/>
						</Grid>
						<Grid item>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									利用者ID
								</Grid>
								<Grid item>
									<TextInput
										// label="利用者ID"
										type="number"
										state={this.state.uniqueId}
										onChange={(state) => {
											this.setState({uniqueId: state})
										}}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									利用資格
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.license}
										onChange={value => {
											this.setState({license: value})
										}}
										style={styles.selector}
									>
										{searchLicenseList.map(row => (
											<option key={row.id} value={row.id}>{row.label}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									団体
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.dantaiId}
										onChange={this.handleChangeDantai}
										style={styles.selector}
									>
										{searchDantaiList.map((row) => (
											<option key={row.uuid} value={row.uuid}>{row.name}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									グループ１
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.group1}
										onChange={this.handleChangeGroup1}
										style={styles.selector}
									>
										{searchGroup1List.map((row) => (
											<option key={row.uuid} value={row.uuid}>{row.name}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									グループ２
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.group2}
										onChange={this.handleChangeGroup2}
										style={styles.selector}
									>
										{searchGroup2List.map((row) => (
											<option key={row.uuid} value={row.uuid}>{row.name}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									IDソート
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.sortId}
										onChange={value => {
											this.setState({sortId: value, sortActivated: "none"})
										}}
										style={styles.selector}
									>
										{sortIdList.map(row => (
											<option key={row.id} value={row.id}>{row.label}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="center">
								<Grid item style={styles.colTitle}>
									登録日ソート
								</Grid>
								<Grid item>
									<Selector
										className="form-control"
										value={this.state.sortActivated}
										onChange={value => {
											this.setState({sortActivated: value, sortId: "none"})
										}}
										style={styles.selector}
									>
										{sortActivatedList.map(row => (
											<option key={row.id} value={row.id}>{row.label}</option>
										))}
									</Selector>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<BsButton
								color="primary"
								type="submit"
							>
								検索
							</BsButton>
							<BsButton
								onClick={this.handleReset}
								style={{marginLeft: 10}}
							>
								リセット
							</BsButton>
						</Grid>
					</Grid>
				</form>
			</div>
		)

		const selectDantaiListToMove = [{
			uuid: "none",
			id: "none",
			name: "所属なし"
		}].concat(this.state.dantaiList)
		const selectDantaiDialogVd = (!this.state.showSelectDantaiDialog) ? null : (
			<SelectDantaiDialog
				dantaiList={selectDantaiListToMove}
				selectedDantaiToMove={this.state.selectedDantaiToMove}
				onChange={this.handleChangeDantaiToMove}
				onCancel={this.handleCancelDantaiToMove}
				onAccept={this.handleAcceptChangeGroup}
			/>
		)

		return (
			<div>
				<ContentHeader
					title="ユーザ検索"
					subTitle="Search users"
				/>
				<section className="content">
					{formVd}
					{resultVd}
					{userDetail}
				</section>
				<Dialog open={(infoString.length !== 0)}>
					<DialogContent>
						<div style={{textAlign: 'center'}}>
							{infoString}
						</div>
					</DialogContent>
				</Dialog>
				{selectDantaiDialogVd}
			</div>
		)
	}
}

export default connect(
	state => ({
		// storeUserSearch: state.mypage.userSearch,
		userSearchResult: state.mypage.userSearchResultState,
	}),
)(withRouter(UserSearch))