import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthRoute from './sonodalib/sl_users/AuthRouteComponent'
import UnauthRoute from './sonodalib/sl_users/UnauthRouteComponent'
import LoginContainer from './top/LoginContainer';
import MyPage from './mypage/MyPage';


const Routes = (props) => (
  <Router {...props}>
    <React.Fragment>
      <Switch>
        <Route path="/my" component={AuthRoute}/>
        <Route path="/" component={UnauthRoute}/>
      </Switch>
      <Route path="/my" component={MyPage}/>
      <Switch>
        <Route exact path="/" component={LoginContainer}/>
        {/*<Route component={NotFoundPage}/>*/}
      </Switch>
    </React.Fragment>
  </Router>
);

export default Routes;
