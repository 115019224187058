
export const altName = "ss-admin"
export const dbName = "fukushi"
export const urlPref = "/ss"
export const hostPrefix = "admin"
export const contentLabel = "社会精神"
export const nendoList = [
	{"value": 2023, "label": "2023年", "checked": true, "disabled": false},
	{"value": 2022, "label": "2022年", "checked": true, "disabled": false},
	{"value": 2021, "label": "2021年", "checked": true, "disabled": false},
	{"value": 2020, "label": "2020年", "checked": true, "disabled": false},
	{"value": 2019, "label": "2019年", "checked": true, "disabled": false},
	{"value": 2018, "label": "2018年", "checked": true, "disabled": false},
	{"value": 2017, "label": "2017年", "checked": true, "disabled": false},
	// {"value": 2016, "label": "2016年", "checked": true, "disabled": false},
	// {"value": 2015, "label": "2015年", "checked": true, "disabled": false},
	// {"value": 2014, "label": "2014年", "checked": true, "disabled": false},
]
export const licenseList = [
	{id: "0", label: "お試し"},
	{id: "01", label: "社会"},
	{id: "02", label: "精神"},
	{id: "012", label: "社会+精神"},
	{id: "1", label: "社会専門"},
	{id: "2", label: "精神専門"},
	{id: "8", label: "自動採点"},
	{id: "9", label: "無効"},
]