// @flow
import React from 'react'
import type {WmService} from "../../../shared/ServerInterface";
import Button from "@material-ui/core/Button";
import {post_wm} from "../../../sonodalib/sl_utils/Http";


type Props = {
	currService: WmService,
}
export default function WmReserveShukei(props: Props) {

	const {currService} = props

	function handleReserve() {
		post_wm("admin/reserve_shukei", {
			service_code: currService.service,
		})
		.then((ret) => {
			alert(ret.status)
		})
	}

	return (
		<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
			<h4>集計処理・手動予約</h4>
			<div style={{marginTop:20}}>
				<Button variant="contained" onClick={handleReserve} color="primary">
					集計処理を即時予約
				</Button>
			</div>
		</div>
	)
}
