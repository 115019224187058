// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {loading, genericModal} from '../../../sonodalib/sl_utils/Utils'
import {connect} from "react-redux";
import type {WmService} from "../../../shared/ServerInterface";
import Cookies from "js-cookie";

const id = 1


type Props = {
	currService: WmService,
}
type State = {
}
class WmSaitenByFile extends React.Component<Props, State> {
	state = {
	}

	onSelectFile = (event:any) => {
		const {currService} = this.props
		const file = event.target.files[0]
		if (!file) return
		if (window.confirm("ファイルをアップロードして採点を開始します")) {
			const fd = new FormData(document.forms.namedItem("upload_form"+id))
			fd.append("params",JSON.stringify({
				user_token: Cookies.get("user_token"),
				form_name: "name-input-el"+id,
				service: currService.service,
			}))
			loading(true)
			post_wm("admin/saiten_by_data", fd)
			.then((ret) => {
				if (ret.status === "err") {
					genericModal(ret.error)
					// alert(ret.error)
				} else {
					genericModal(ret.count + "行の採点処理を予約しました")
					// alert(ret.count + "行の採点処理を予約しました")
				}
			})
			.catch(() => {
				genericModal("不明なエラーが発生しました")
				// alert("不明なエラーが発生しました")
			})
			.finally(()=>{
				loading(false)
			})
		}
	}

	render() {
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>一括採点</h4>
				<div style={{marginTop:24}}>
					<form id={"upload_form"+id} encType="multipart/form-data">
						<input id={"input-element-"+id}
									 onChange={this.onSelectFile}
									 name={"name-input-el"+id}
									 accept="*/*"
									 type="file"
						/>
					</form>
					<div>
						<div style={{margin:10}}>
							ファイルを選択すると、一括採点が開始します
						</div>
						<div style={{margin:10}}>
							【例】利用者ID（タブ）科目コード（タブ）問題番号（タブ）解答
						</div>
						<div style={{margin:10}}>
							<a href="https://docs.google.com/spreadsheets/d/1Bsgi_Jthdu30xb1DbFCdP3W7MQZoBP025LN4AckNLzg/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
								一括採点サンプル
							</a>
						</div>
					</div>

				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService: state.mypage.currService,
	}),
)(WmSaitenByFile)