// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import SlTextField from "../../sonodalib/sl_mui/SlTextField";
import {TextInputState, FormState} from "../../sonodalib/sl_utils/SlForms";
import {post} from '../../sonodalib/sl_utils/Http'
import BsButton from '../../sonodalib/sl_adminlte/BsButton'
import {AdminContentSearchSentakushi} from '../../shared/ServerInterface'
import {Sentakushi} from '../../shared/DbModel'
import TextInput from '../../sonodalib/sl_adminlte/TextInput'

type States = {
	SentakushiID: TextInputState,
	MondaiID: TextInputState,
	Sentakubun: TextInputState,
	Kaisetsubun: TextInputState,
	Seikai: TextInputState,
	result: Array<Sentakushi>,
	editingSentakushiId: string,
	editMondaiID: TextInputState,
	editSentakubun: TextInputState,
	editKaisetsubun: TextInputState,
	editSeikai: TextInputState,
}

export default class EditSentakushi extends React.Component<{}, States> {
	state = {
		SentakushiID: new TextInputState({validations: "integer"}),
		MondaiID: new TextInputState({validations: "integer"}),
		Sentakubun: new TextInputState(),
		Kaisetsubun: new TextInputState(),
		Seikai: new TextInputState({validations: "integer"}),
		result: [],
		editingSentakushiId: "",
		editMondaiID: new TextInputState({validations: "required,integer"}),
		editSentakubun: new TextInputState({validations: "required"}),
		editKaisetsubun: new TextInputState({validations: "required"}),
		editSeikai: new TextInputState({validations: "required,integer"}),
	}
	searchMembers = ["SentakushiID", "MondaiID", "Sentakubun", "Seikai", "Kaisetsubun"]
	editMembers = ["editMondaiID", "editSentakubun", "editSeikai", "editKaisetsubun"]

	handleSearch = () => {
		const searchMembers = this.searchMembers
		this.setState(FormState.validateMembers(this.state, searchMembers), () => {
			if (FormState.isValidMembers(this.state, searchMembers)) {
				post("admin/content/search-sentakushi", FormState.valueMembers(this.state, searchMembers))
				.then((ret: AdminContentSearchSentakushi) => {
					let show = true
					if (ret.result.length > 1000) {
						show = (window.confirm(ret.result.length + "件ありますがよろしいですか？"))
					}
					if (show) {
						this.setState({
							result: ret.result,
							editingSentakushiId: "",
						})
					}
				})
			}
		})
	}

	handleCancelEdit = () => {
		this.setState({
			editingSentakushiId: "",
		})
	}

	handleSaveRow = (row: Sentakushi) => {
		this.setState(FormState.validateMembers(this.state, this.editMembers), () => {
			if (FormState.isValidMembers(this.state, this.editMembers)) {
				if (window.confirm("この内容で保存してもよろしいですか？")) {
					const payload = {
						SentakushiID: row.SentakushiID,
						MondaiID: this.state.editMondaiID.getValue(),
						Sentakubun: this.state.editSentakubun.getValue(),
						Kaisetsubun: this.state.editKaisetsubun.getValue(),
						Seikai: this.state.editSeikai.getValue(),
					}
					post("admin/content/update-sentakushi", payload)
					.then(() => {
						row.MondaiID = payload.MondaiID
						row.Sentakubun = payload.Sentakubun
						row.Seikai = payload.Seikai
						row.Kaisetsubun = payload.Kaisetsubun
						this.setState({
							editingSentakushiId: "",
							result: this.state.result
						})
					})
					.catch(() => {
						alert("ネットワークエラーが発生しました")
					})
				}
			}
		})
	}

	handleEditRow = (row: Sentakushi) => {
		this.setState({
			editingSentakushiId: row.SentakushiID,
			editMondaiID: this.state.editMondaiID.setValue(row.MondaiID),
			editSentakubun: this.state.editSentakubun.setValue(row.Sentakubun),
			editKaisetsubun: this.state.editKaisetsubun.setValue(row.Kaisetsubun),
			editSeikai: this.state.editSeikai.setValue(row.Seikai),
		})
	}

	render() {
		const resultVd = this.state.result.map((row: Sentakushi) => {
			if (this.state.editingSentakushiId === row.SentakushiID) {
				return (
					<tr key={"result_a_" + row.SentakushiID}>
						<td>{row.SentakushiID}</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editMondaiID}
								onChange={(newState: TextInputState) => {
									this.setState({editMondaiID: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editSentakubun}
								onChange={(newState: TextInputState) => {
									this.setState({editSentakubun: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editKaisetsubun}
								multiline
								onChange={(newState: TextInputState) => {
									this.setState({editKaisetsubun: newState})
								}}
							/>
						</td>
						<td>
							<TextInput
								type="text"
								state={this.state.editSeikai}
								onChange={(newState: TextInputState) => {
									this.setState({editSeikai: newState})
								}}
							/>
						</td>
						<td>
							<BsButton
								onClick={this.handleSaveRow.bind(this, row)}
								color="danger"
							>
								保存
							</BsButton>
							<BsButton
								onClick={this.handleCancelEdit}
							>
								中止
							</BsButton>
						</td>
					</tr>
				)
			} else {
				return (
					<tr key={"result_b_" + row.SentakushiID}>
						<td>{row.SentakushiID}</td>
						<td>{row.MondaiID}</td>
						<td>
							<div dangerouslySetInnerHTML={{__html: row.Sentakubun.replace(/\n/g, "<br />")}}></div>
						</td>
						<td>
							<div dangerouslySetInnerHTML={{__html: row.Kaisetsubun.replace(/\n/g, "<br />")}}></div>
						</td>
						<td>{row.Seikai}</td>
						<td>
							<BsButton
								onClick={this.handleEditRow.bind(this, row)}
								color="purple"
							>
								修正
							</BsButton>
						</td>
					</tr>
				)
			}
		})

		return (
			<div>
				<section className="content-header">
					<h1>
						選択肢データの修正
						<small>Control panel</small>
					</h1>
					<ol className="breadcrumb">
						<li><a><i className="fa fa-dashboard"/> Home</a></li>
						<li className="active">Dashboard</li>
					</ol>
				</section>
				<section className="content">
					<Grid container direction="row">
						<Grid item xs={6}>
							<SlTextField
								label="選択肢ID"
								state={this.state.SentakushiID}
								onChange={(newState: TextInputState) => {
									this.setState({SentakushiID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="問題ID"
								state={this.state.MondaiID}
								onChange={(newState: TextInputState) => {
									this.setState({MondaiID: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="選択文"
								state={this.state.Sentakubun}
								onChange={(newState: TextInputState) => {
									this.setState({Sentakubun: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="解説文"
								state={this.state.Kaisetsubun}
								onChange={(newState: TextInputState) => {
									this.setState({Kaisetsubun: newState})
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<SlTextField
								label="正解"
								state={this.state.Seikai}
								onChange={(newState: TextInputState) => {
									this.setState({Seikai: newState})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<BsButton
								onClick={this.handleSearch}
								color="primary"
								// variant="contained"
							>
								選択肢を検索
							</BsButton>
						</Grid>
					</Grid>
					<div>
						<table className="table table-bordered">
							<tbody>
							<tr>
								<th style={{width: 80, whiteSpace: 'nowrap'}}>選択肢ID</th>
								<th style={{width: 100, whiteSpace: 'nowrap'}}>問題ID</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>選択文</th>
								<th style={{width: 300, whiteSpace: 'nowrap'}}>解説文</th>
								<th style={{width: 80, whiteSpace: 'nowrap'}}>正解</th>
							</tr>
							{resultVd}
							</tbody>
						</table>
					</div>
				</section>
			</div>
		)
	}
}

