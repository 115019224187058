// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import {FormState} from '../../../sonodalib/sl_utils/SlForms'
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";


type Props = {
	currService: WmService,
}
type State = {
	checkBoxes: Array<boolean>,
}
class WmReSaiten extends React.Component<Props, State> {
	state = {
		checkBoxes: [false, false, false],
	}

	handleToggleCheck = (index:number) => () => {
		const {checkBoxes} = this.state
		checkBoxes[index] = !checkBoxes[index]
		this.setState({
			checkBoxes,
		})
	}

	handleReSaiten = () => {
		const {currService} = this.props
		const {checkBoxes} = this.state
		if (!(checkBoxes[0] && checkBoxes[1] && checkBoxes[2])) {
			alert("チェックボックスをすべてONにしてください")
		} else {
			const newState = FormState.validate(this.state)
			this.setState(newState, () => {
				if (FormState.isValid(this.state)) {
					if (window.confirm("再採点を実行します")) {
						post_wm("admin/reserve_resaiten", {
							service_code: currService.service,
						})
						.then((ret) => {
							alert("再採点処理を予約しました。進捗は、採点・集計処理のページをご覧ください")
							this.setState({
								checkBoxes: [false, false, false]
							})
						})
						.catch(() => {
						})
					}
				}
			})
		}
	}

	render() {
		const {checkBoxes} = this.state
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>再採点</h4>
				<Grid container alignItems="center" style={{marginTop:20}}>
					<Grid item>
						<Checkbox
							checked={checkBoxes[0]}
							onChange={this.handleToggleCheck(0)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[1]}
							onChange={this.handleToggleCheck(1)}
							value="1"
							style={{width: 24, height: 24}}
						/>
						<Checkbox
							checked={checkBoxes[2]}
							onChange={this.handleToggleCheck(2)}
							value="1"
							style={{width: 24, height: 24}}
						/>
					</Grid>
					<Grid item>
						３つすべてをONにして、再採点ボタンを押してください
					</Grid>
				</Grid>
				<div style={{marginTop:20}}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleReSaiten}
					>
						再採点
					</Button>
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmReSaiten)