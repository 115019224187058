// @flow
import React from 'react';
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import {connect} from "react-redux";
import SlTextField from "../../../sonodalib/sl_mui/SlTextField";
import {TextInputState} from '../../../sonodalib/sl_utils/SlForms'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import type {WmService} from "../../../shared/ServerInterface";


type Props = {
	currService: WmService,
}
type State = {
	optionRows: Array<any>,
}
class WmShukeiViewOption extends React.Component<Props, State> {
	state = {
		optionRows: [],
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = () => {
		const {currService} = this.props
		post_wm("admin/fetch_shukei_view_option", {
			service_code: currService.service
		})
		.then((ret) => {
			const optionRows = ret.shukei_view_option.map((row) => {
				return {
					id: 		 			row.id,
					thValue: 			new TextInputState({value:""+row.th_value, validations: "integer"}),
					label: 	 			row.label,
					curr_count: 	row.curr_count,
					sum_category: row.sum_category,
				}
			})
			this.setState({
				optionRows,
			})
		})
	}

	handleUpdate = (optionRow) => () => {
		const {currService} = this.props
		const {optionRows} = this.state
		optionRow.thValue = optionRow.thValue.validate()
		this.setState({optionRows: [...optionRows]}, () => {
			if (optionRow.thValue.isValid()) {
				post_wm("admin/update_shukei_view_option", {
					id: 					optionRow.id,
					service:			currService.service,
					sum_category: optionRow.sum_category,
					th_value: 		optionRow.thValue.getValue(),
				})
				.then((ret) => {
					alert("更新しました")
				})
				.catch(() => {
					alert("エラーが発生しました")
				})
			}
		})
	}

	render() {
		const {optionRows} = this.state
		const listVd = optionRows.map((row, index) => {
			return (
				<Grid container alignItems="center" key={"svo"+index}>
					<Grid item style={{fontSize:16, width:200}}>
						{row.label}
					</Grid>
					<Grid item style={{paddingLeft:10}}>
						受験者数が
						<SlTextField
							name={"tf"+row.id}
							placeholder="人数"
							style={{width:75, marginLeft:10, marginRight:10}}
							state={row.thValue}
							onChange={(newState: TextInputState) => {
								row.thValue = newState
								this.setState({optionRows: [...optionRows]})
							}}
						/>
						人以上なら公開
						{/*（現在{row.curr_count}人）*/}
					</Grid>
					<Grid item style={{paddingLeft:10}}>
						<Button variant="contained" color="primary" onClick={this.handleUpdate(row)} size="small">
							更新
						</Button>
					</Grid>
				</Grid>
			)
		})
		return (
			<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
				<h4>集計を公開する条件</h4>
				<div>
					{listVd}
				</div>
			</div>
		)
	}
}
export default connect(
	state => ({
		currService:   state.mypage.currService,
	}),
)(WmShukeiViewOption)