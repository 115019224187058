import React from 'react'
import BoardThread from "./BoardThread";

export default class Board2Thread extends React.Component {
	render() {
		return (
			<BoardThread
				boardId={2}
			/>
		)
	}
}


