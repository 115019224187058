import React from 'react'
// import { withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {post} from '../../sonodalib/sl_utils/Http'
import _ from 'lodash'
import Moment from 'moment'
import SlTextField from '../../sonodalib/sl_mui/SlTextField'
import {SelectorInputState, TextInputState} from '../../sonodalib/sl_utils/SlForms'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SlTextFieldSelector from "../../sonodalib/sl_mui/SlTextFieldSelector";
import ContactPost from "./ContactPost";
import Cookies from "js-cookie";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {blue} from '@material-ui/core/colors'

const onlyUnreadList = [
	{id: 0, label:"未読のみ"},
	{id: 1, label:"すべて"},
]

export default class ContactThread extends React.Component {
	state = {
		searchUniqueId: new TextInputState(),
		searchOnlyUnread: new SelectorInputState({menuList:onlyUnreadList}),
		threads: null,
		infoString: "",
		showPost: false,
		threadId: null,
	}

	handleClickThread = thread => {
		this.setState({
			threadId: thread.uuid,
			showPost: true,
		})
		// this.props.history.push("/my/bbs/posts/"+thread.uuid)
	}

	handleClosePost = () => {
		this.setState({
			showPost: false,
		})
		this.handleSearch()
	}

	handleSearch = () => {
		const self = this
		post("admin/fetch_threads", {
			user_token: Cookies.get("sl_users"),
			board_id: 9,
			ident: null,
			unique_id: this.state.searchUniqueId.getValue(),
			only_unread: this.state.searchOnlyUnread.getValue(),
		})
			.then(ret => {
				const list = _(ret.list).sortBy("thread_created").reverse().value()
				list.forEach((row) => {row.checked = false})
				self.setState({
					threads: list,
				})
			})
	}

	handleToggleCheckbox = (thread:any) => () => {
		thread.checked = !thread.checked
		this.setState({
			threads: this.state.threads
		})
	}

	_checkAll = (checked) => {
		const {threads} = this.state
		if (threads) {
			threads.forEach((row:any) => {
				row.checked = checked
			})
			this.setState({
				threads: this.state.threads
			})
		}
	}

	handleCheckAll = () => {
		this._checkAll(true)
	}

	handleUncheckAll = () => {
		this._checkAll(false)
	}

	handleDelete = () => {
		if (window.confirm("削除してよろしいですか？（元に戻せません）")) {
			const {threads} = this.state
			if (threads) {
				let p = Promise.resolve()
				threads.filter((thread:any) => (thread.checked)).forEach((thread: any) => {
					p = p.then(() =>
						new Promise((resolve, reject) => {
							this.setState({
								infoString: "削除中... " + thread.title,
							})
							post("admin/del_thread_perm", {
								user_token: Cookies.get("sl_users"),
								thread_id: thread.uuid,
							})
							.then((ret) => {
								resolve(ret)
							})
							.catch((ret) => {
								reject(ret)
							})
						})
					)
				})
				p.then(() => {
					alert("削除しました")
					this.handleSearch()
				})
				.catch(() => {
					alert("不明なエラーが発生しました")
				})
				// $FlowFixMe
				.finally(() => {
					this.setState({
						infoString: "",
					})
				})
			}
		}
	}

	render() {
		const { threads } = this.state
		const threadList = (threads === null) ? (
			<TableRow>
				<TableCell colSpan={4}>
					検索結果
				</TableCell>
			</TableRow>
		) : (threads.length === 0) ? (
			<TableRow>
				<TableCell colSpan={4}>
					みつかりませんでした
				</TableCell>
			</TableRow>
		) : _.map(
			threads, thread => {
				const hiddenVd = (thread.hidden) ? (
					<span className="badge bg-red" style={{marginRight:8}}>非表示中</span>
				) : null
				return (
					<TableRow hover key={thread.uuid}>
						<TableCell padding="default">
							<Checkbox
								checked={thread.checked}
								onChange={this.handleToggleCheckbox(thread)}
								value="1"
								style={{width: 24, height: 24}}
							/>
						</TableCell>
						<TableCell padding="default">
							{hiddenVd}
							<a style={{cursor: 'pointer'}}
								 onClick={this.handleClickThread.bind(this, thread)}>{thread.title}</a> {(thread.newest_user_post > thread.newest_admin_read) ?
							<span style={{fontWeight: 800, color: 'red'}}>未読</span> : null}</TableCell>
						<TableCell padding="default">{thread.unique_id}</TableCell>
						<TableCell padding="dense" numeric>{thread.comment_count}</TableCell>
						<TableCell padding="default">{Moment(thread.thread_created).format("YYYY/MM/DD HH:mm")}</TableCell>
					</TableRow>
				)
			}
		)
		if (this.state.showPost) {
			return <ContactPost
				threadId={this.state.threadId}
				onClose={this.handleClosePost}
			/>
		} else {
			const command_box = (!threads || threads.length === 0) ? null : (
				<div className="clearfix">
					<ul className="no-margin pull-left">
						<Button
							variant="contained"
							style={{color: 'white', backgroundColor: blue[400]}}
							onClick={this.handleCheckAll}
						>
							すべて選択
						</Button>
						<Button
							variant="contained"
							style={{marginLeft: 8, color: 'white', backgroundColor: blue[400]}}
							onClick={this.handleUncheckAll}
						>
							選択解除
						</Button>
						<Button
							variant="contained"
							color="secondary"
							style={{marginLeft: 14}}
							onClick={this.handleDelete}
						>
							完全に削除
						</Button>
					</ul>
				</div>
			)
			return (
				<div>
					<section className="content-header">
						<h1>
							お問い合わせ検索
							<small>Control panel</small>
						</h1>
						<ol className="breadcrumb">
							<li><a><i className="fa fa-dashboard"/> Home</a></li>
							<li className="active">Dashboard</li>
						</ol>
					</section>
					<section className="content">
						<Grid container direction="row">
							<Grid item xs={6}>
								<SlTextField
									label="利用者ID（省略可）"
									state={this.state.searchUniqueId}
									onChange={(newState) => {
										this.setState({searchUniqueId: newState})
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<SlTextFieldSelector
									label="未読・すべて"
									state={this.state.searchOnlyUnread}
									onChange={(newState) => {
										this.setState({searchOnlyUnread: newState})
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									onClick={this.handleSearch}
								>
									検索
								</Button>
							</Grid>
						</Grid>
						<div style={{marginTop: 100}}>
							お問い合わせ 検索結果
						</div>
						{command_box}
						<Table>
							<TableHead>
								<TableRow>
									<TableCell padding="default">タイトル</TableCell>
									<TableCell padding="default">投稿者ID</TableCell>
									<TableCell padding="dense" numeric>返信数</TableCell>
									<TableCell padding="default">スレッド作成日</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{threadList}
							</TableBody>
						</Table>
						{command_box}
					</section>
					<Dialog open={(this.state.infoString.length !== 0)}>
						<DialogContent>
							<div style={{textAlign: 'center'}}>
								{this.state.infoString}
							</div>
						</DialogContent>
					</Dialog>
				</div>
			)
		}
	}
}

// export default withRouter(ContactThread)
