// @flow
import React from 'react'
import type {WmService} from "../../../shared/ServerInterface";
import Button from "@material-ui/core/Button";
import {post_wm} from "../../../sonodalib/sl_utils/Http";
import G from "../../../sonodalib/SlGlobal";
import {updateWmShukeiEtc} from "../../MyPageAction";


type Props = {
	currService: WmService,
	shukei_etc: any,
}
export default function WmShukeiShowBunpu(props: Props) {

	const {currService, shukei_etc} = props

	function change(flag:number) {
		post_wm("admin/update_shukei_show_bunpu", {
			service_code: currService.service,
			flag,
		})
		.then((ret) => {
			G.store.dispatch(updateWmShukeiEtc(ret.shukei_etc))
		})
	}

	function handleStart() {
		change(1)
	}

	function handleStop() {
		change(0)
	}


	if (!shukei_etc) return null
	return (
		<div style={{marginTop:30, padding:20, backgroundColor:'white'}}>
			<h4>得点分布の表示・非表示</h4>
			現在 {(shukei_etc.show_bunpu) ? "表示中" : "非表示中"}
			<div style={{marginTop:20}}>
				{(shukei_etc.show_bunpu) ? (
					<div>
						<Button variant="contained" disabled={true}>
							表示する
						</Button>
						<Button variant="contained" color="primary" style={{marginLeft:20}} onClick={handleStop}>
							非表示にする
						</Button>
					</div>
				) : (
					<div>
						<Button variant="contained" color="primary" onClick={handleStart}>
							表示する
						</Button>
						<Button variant="contained" disabled={true} style={{marginLeft:20}}>
							非表示にする
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
